import { ACTIVITY_URL, ActionTypes, USER_URL } from "../contants";
import { customeGetService, } from "../services/service";


const getActivitySearchUsers = () => {
    return (Dispatch) => {
        Dispatch({ type: ActionTypes.ACTIVE_SEARCH_USER_REQUEST, })
        customeGetService(USER_URL + 'User/SearchUsers').then(
            (response) => {
                if (response?.data) {
                    return Dispatch({ type: ActionTypes.ACTIVE_SEARCH_USER_SUCESS, data: response.data })
                }
                else { return Dispatch({ type: ActionTypes.ACTIVE_SEARCH_USER_FAIL, data: response.response }) }
            },
            (error) => { return Dispatch({ type: ActionTypes.ACTIVE_SEARCH_USER_FAIL, data: error }) }
        )
    }
};

const GetActivityExcelAction = (params) => {
    return (Dispatch) => {
        Dispatch({ type: ActionTypes.GET_ACTIVE_EXEL_REQUEST, })
        customeGetService(ACTIVITY_URL + 'Activity/GetActivityExcel?userId=' + params.userId + '&startTime=' + params.startDate + '&endTime=' + params.endDate).then(
            (response) => {
                if (response?.data) {
                    downloadExcelXLSX(response.data, 'ActivityExcel')
                    return Dispatch({ type: ActionTypes.GET_ACTIVE_EXEL_SUCESS, data: response.data })
                }
                else { return Dispatch({ type: ActionTypes.GET_ACTIVE_EXEL_FAIL, data: response.response }) }
            },
            (error) => { return Dispatch({ type: ActionTypes.GET_ACTIVE_EXEL_FAIL, data: error }) }
        )
    }
};

function downloadExcelXLSX(base64Txt, fileName) {

    let day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
    let month = new Date().getMonth() < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
    let year = new Date().getFullYear();
    let today = day + month + year

    let linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Txt}`;
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', `${fileName}${today}.xlsx`);
    downloadLink.href = linkSource;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

const transferUserActivityAction = (params) => {
    return (Dispatch) => {
        Dispatch({ type: ActionTypes.TRANSFER_USER_ACTIVITY_REQUEST, })
        customeGetService(ACTIVITY_URL + 'Activity/ActivityMovedAnotherUser?oldUserId=' + params.oldUserId + '&newUserId=' + params.newUserId).then(
            (response) => {
                if (response) {
                    return Dispatch({ type: ActionTypes.TRANSFER_USER_ACTIVITY_SUCESS, data: response })
                }
                else { return Dispatch({ type: ActionTypes.TRANSFER_USER_ACTIVITY_FAIL, data: response.response }) }
            },
            (error) => { return Dispatch({ type: ActionTypes.TRANSFER_USER_ACTIVITY_FAIL, data: error }) }
        )
    }
};

const transferUserFollowerAction = (params) => {
    return (Dispatch) => {
        Dispatch({ type: ActionTypes.TRANSFER_USER_FOLLOWER_REQUEST, })
        customeGetService(USER_URL + 'User/FollowerMovedAnotherUser?oldUserId=' + params.oldUserId + '&newUserId=' + params.newUserId).then(
            (response) => {
                if (response) {
                    return Dispatch({ type: ActionTypes.TRANSFER_USER_FOLLOWER_SUCESS, data: response })
                }
                else { return Dispatch({ type: ActionTypes.TRANSFER_USER_FOLLOWER_FAIL, data: response.response }) }
            },
            (error) => { return Dispatch({ type: ActionTypes.TRANSFER_USER_FOLLOWER_FAIL, data: error }) }
        )
    }
};

export {
    getActivitySearchUsers,
    GetActivityExcelAction,
    transferUserActivityAction,
    transferUserFollowerAction
}