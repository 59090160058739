import { Grid } from "@mui/material";
import flatpickr from "assets/theme/components/flatpickr";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { connect } from "formik";
import Notification from "layouts/Notification";
import react, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const IsUserHasPermetionComponent = ({ userDetails, chieldComponent, }) => {

    const navigate = useNavigate();
    // const userDetails = JSON.parse(localStorage.getItem("UserDetails"))

    let isGlobalAdmin = userDetails && userDetails?.userRoles?.filter((item) => item.roleType == "GlobalCommunityAdmin").length ? true : false;

    if (userDetails && !isGlobalAdmin) {
        {
            return navigate('/401')
        }
    }

    return chieldComponent

}

export default IsUserHasPermetionComponent;