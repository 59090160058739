import { ConstructionOutlined } from "@mui/icons-material";
import { ActionTypes } from "../contants";

const intialState = {
    loading: false,
    activateReward: null,
};

// Set Activity Types Reducer
const activateRewardReducer = (state = intialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_ACTIVE_USER_MARKETING_REQUEST:
            return {
                loading: true,
                activateReward: null,
            };
        case ActionTypes.CREATE_ACTIVE_USER_MARKETING_SUCESS:
            return {
                activateReward: action.data,
                loading: false,
            };
        case ActionTypes.CREATE_ACTIVE_USER_MARKETING_FAIL:
            return {
                activateReward: action.data,
                loading: false,
            };
        default:
            return state;
    }
};

// Set Reward Challenges Options Reducer
const rewardChallengesReducer = (state = intialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_REWAED_CHALLENGES_REQUEST:
            return {
                loading: true,
                rewardChallengeList: null,
            };
        case ActionTypes.GET_REWAED_CHALLENGES_SUCESS:
            return {
                rewardChallengeList: action.data,
                loading: false,
            };
        case ActionTypes.GET_REWAED_CHALLENGES_FAIL:
            return {
                rewardChallengeList: null,
                loading: false,
            };
        default:
            return state;
    }
};

// Set Reward Challenges Options Reducer
const rewardChallengeParticipantsReducer = (state = intialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_REWAED_CHALLENGES_PARTICIPANTS_REQUEST:
            return {
                loading: true,
                rewardChallengeParticipantsList: null,
            };
        case ActionTypes.GET_REWAED_CHALLENGES_PARTICIPANTS_SUCESS:
            return {
                rewardChallengeParticipantsList: action.data,
                loading: false,
            };
        case ActionTypes.GET_REWAED_CHALLENGES_PARTICIPANTS_FAIL:
            return {
                rewardChallengeParticipantsList: null,
                loading: false,
            };
        default:
            return state;
    }
};




export {
    activateRewardReducer,
    rewardChallengesReducer,
    rewardChallengeParticipantsReducer,
}