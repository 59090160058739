import { ConstructionOutlined } from "@mui/icons-material";
import { ActionTypes } from "../contants";

const intialState = {
  activityTypeList: null,
  activityOptionList: null,
};

// Set Activity Types Reducer
const activityTypeReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ACTIVE_TYPE_REQUEST:
      return {
        loading: true,
        activityTypeList: null,
      };
    case ActionTypes.GET_ACTIVE_TYPE_SUCESS:
      return {
        activityTypeList: action.data,
        loading: false,
      };
    case ActionTypes.GET_ACTIVE_TYPE_FAIL:
      return {
        activityTypeList: null,
        loading: false,
      };
    default:
      return state;
  }
};

// Set Activity Options Reducer
const activityOptionsReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ACTIVE_OPTIONS_REQUEST:
      return {
        loading: true,
        activityOptionList: null,
      };
    case ActionTypes.GET_ACTIVE_OPTIONS_SUCESS:
      return {
        activityOptionList: action.data,
        loading: false,
      };
    case ActionTypes.GET_ACTIVE_OPTIONS_FAIL:
      return {
        activityOptionList: null,
        loading: false,
      };
    default:
      return state;
  }
};

export {
  activityTypeReducer,
  activityOptionsReducer,
}








