
import { ActionTypes, msalConfig } from "../contants";
import { PublicClientApplication, EventType } from "@azure/msal-browser";


const getMsalInstance = (msalConfig) => {
  const msalInstance = new PublicClientApplication(msalConfig);
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
  msalInstance.addEventCallback((event) => {
    if ((event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload.account
    ) {
      msalInstance.setActiveAccount(event.payload.account);
    }
  });
  return msalInstance;
}

const intialState = {
  msalInstance: getMsalInstance(msalConfig),
};

const msalInstanceReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_LOGIN_AUTHORITY:
      let newMsal = {
        ...msalConfig,
        auth: {
          ...msalConfig.auth,
          authority: action.data,
        },
      };
      return {
        msalInstance: getMsalInstance(newMsal),
      };
    default:
      return state;
  }
};

const logedinUserReducer = (state = {}, action) => {

  switch (action.type) {

    // User/ValidateUser
    case ActionTypes.USER_LOGIN_REQUEST:
      return {
        userDetails: null,
        loading: true,
      };
    case ActionTypes.USER_LOGIN_SUCESS:
      return {
        userDetails: action.data,
        loading: false,
      };
    case ActionTypes.USER_LOGIN_FAILED:
      return {
        userDetails: null,
        loading: false,
        error: action.data
      };

    // if Session has User Data
    case ActionTypes.USER_ALLRADY_LOGIN:
      return {
        userDetails: null,
      };

    // Log Out
    case ActionTypes.LOG_OUT:
      return {
        userDetails: null,
        loading: false,
      };

    // Default
    default:
      return {
        userDetails: JSON.parse(localStorage.getItem("UserDetails")),
        loading: true,
      };
  }


};

export {
  msalInstanceReducer,
  logedinUserReducer
}










