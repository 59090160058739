export const ActionTypes = {

    SET_LOGIN_AUTHORITY: 'SET_LOGIN_AUTHORITY',
    SET_USER_DETAILS: 'SET_USER_DETAILS',
    USER_ALLRADY_LOGIN: 'USER_ALLRADY_LOGIN',
    LOG_OUT: 'LOG_OUT',

    USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCESS: 'USER_LOGIN_SUCESS',
    USER_LOGIN_FAILED: 'USER_LOGIN_FAILED',

    // CHALLENGES
    GET_ALL_ACTIVE_CHALLENGES_LIST_REQUEST: 'GET_ALL_ACTIVE_CHALLENGES_LIST_REQUEST',
    GET_ALL_ACTIVE_CHALLENGES_LIST_SUCESS: 'GET_ALL_ACTIVE_CHALLENGES_LIST_SUCESS',
    GET_ALL_ACTIVE_CHALLENGES_LIST_FAIL: 'GET_ALL_ACTIVE_CHALLENGES_LIST_FAIL',

    GET_ACTIVE_CHALLENGES_OPTIONS_REQUEST: 'GET_ACTIVE_CHALLENGES_OPTIONS_REQUEST',
    GET_ACTIVE_CHALLENGES_OPTIONS_SUCESS: 'GET_ACTIVE_CHALLENGES_OPTIONS_SUCESS',
    GET_ACTIVE_CHALLENGES_OPTIONS_FAIL: 'GET_ACTIVE_CHALLENGES_OPTIONS_FAIL',

    GET_PARTICIPANTS_BY_ORGANIZER_REQUEST: 'GET_PARTICIPANTS_BY_ORGANIZER_REQUEST',
    GET_PARTICIPANTS_BY_ORGANIZER_SUCESS: 'GET_PARTICIPANTS_BY_ORGANIZER_SUCESS',
    GET_PARTICIPANTS_BY_ORGANIZER_FAIL: 'GET_PARTICIPANTS_BY_ORGANIZER_FAIL',

    CREATE_CHALANGE_REQUEST: 'CREATE_CHALANGE_REQUEST',
    CREATE_CHALANGE_SUCESS: 'CREATE_CHALANGE_SUCESS',
    CREATE_CHALANGE_FAIL: 'CREATE_CHALANGE_FAIL',

    CLEAR_CHALLENGES_RESPONCE: "CLEAR_CHALLENGES_RESPONCE",

    GET_CHALLENGES_DETAILS_BY_ID_REQUEST: 'GET_CHALLENGES_DETAILS_BY_ID_REQUEST',
    GET_CHALLENGES_DETAILS_BY_ID_SUCESS: 'GET_CHALLENGES_DETAILS_BY_ID_SUCESS',
    GET_CHALLENGES_DETAILS_BY_ID_FAIL: 'GET_CHALLENGES_DETAILS_BY_ID_FAIL',
    RESET_CHALLENGES_DETAILS_BY_ID: 'RESET_CHALLENGES_DETAILS_BY_ID',

    UPDATE_CHALANGE_REQUEST: 'UPDATE_CHALANGE_REQUEST',
    UPDATE_CHALANGE_SUCESS: 'UPDATE_CHALANGE_SUCESS',
    UPDATE_CHALANGE_FAIL: 'UPDATE_CHALANGE_FAIL',

    REMOVE_CHALANGE_REQUEST: 'REMOVE_CHALANGE_REQUEST',
    REMOVE_CHALANGE_SUCESS: 'REMOVE_CHALANGE_SUCESS',
    REMOVE_CHALANGE_FAIL: 'REMOVE_CHALANGE_FAIL',

    GET_CHALLENGE_RELATED_ACTIVITIES_REQUEST: 'GET_CHALLENGE_RELATED_ACTIVITIES_REQUEST',
    GET_CHALLENGE_RELATED_ACTIVITIES_SUCESS: 'GET_CHALLENGE_RELATED_ACTIVITIES_SUCESS',
    GET_CHALLENGE_RELATED_ACTIVITIES_FAIL: 'GET_CHALLENGE_RELATED_ACTIVITIES_FAIL',
    
    
    GET_USER_CHALLENGES_REQUEST: 'GET_USER_CHALLENGES_REQUEST',
    GET_USER_CHALLENGES_SUCESS: 'GET_USER_CHALLENGES_SUCESS',
    GET_USER_CHALLENGES_FAIL: 'GET_USER_CHALLENGES_FAIL',

    // EVENTS
    GET_ALL_ACTIVE_EVENT_LIST_REQUEST: 'GET_ALL_ACTIVE_EVENT_LIST_REQUEST',
    GET_ALL_ACTIVE_EVENT_LIST_SUCESS: 'GET_ALL_ACTIVE_EVENT_LIST_SUCESS',
    GET_ALL_ACTIVE_EVENT_LIST_FAIL: 'GET_ALL_ACTIVE_EVENT_LIST_FAIL',

    GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_REQUEST: 'GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_REQUEST',
    GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_SUCESS: 'GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_SUCESS',
    GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_FAIL: 'GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_FAIL',

    CREATE_EVENT_REQUEST: 'CREATE_EVENT_REQUEST',
    CREATE_EVENT_SUCESS: 'CREATE_EVENT_SUCESS',
    CREATE_EVENT_FAIL: 'CREATE_EVENT_FAIL',

    GET_EVENT_PARTICIPANTS_BY_ORGANIZER_REQUEST: 'GET_EVENT_PARTICIPANTS_BY_ORGANIZER_REQUEST',
    GET_EVENT_PARTICIPANTS_BY_ORGANIZER_SUCESS: 'GET_EVENT_PARTICIPANTS_BY_ORGANIZER_SUCESS',
    GET_EVENT_PARTICIPANTS_BY_ORGANIZER_FAIL: 'GET_EVENT_PARTICIPANTS_BY_ORGANIZER_FAIL',

    CLEAR_EVENT_RESPONCE: "CLEAR_EVENT_RESPONCE",

    GET_EVENT_DETAILS_BY_ID_REQUEST: 'GET_EVENT_DETAILS_BY_ID_REQUEST',
    GET_EVENT_DETAILS_BY_ID_SUCESS: 'GET_EVENT_DETAILS_BY_ID_SUCESS',
    GET_EVENT_DETAILS_BY_ID_FAIL: 'GET_EVENT_DETAILS_BY_ID_FAIL',
    RESET_EVENT_DETAILS_BY_ID_FAIL: 'RESET_EVENT_DETAILS_BY_ID_FAIL',

    UPDATE_EVENT_REQUEST: 'UPDATE_EVENT_REQUEST',
    UPDATE_EVENT_SUCESS: 'UPDATE_EVENT_SUCESS',
    UPDATE_EVENT_FAIL: 'UPDATE_EVENT_FAIL',

    REMOVE_EVENT_REQUEST: 'REMOVE_EVENT_REQUEST',
    REMOVE_EVENT_SUCESS: 'REMOVE_EVENT_SUCESS',
    REMOVE_EVENT_FAIL: 'REMOVE_EVENT_FAIL',

    //  GOALS
    GET_ALL_GOLES_LIST_REQUEST: 'GET_ALL_GOLES_LIST_REQUEST',
    GET_ALL_GOLES_LIST_SUCESS: 'GET_ALL_GOLES_LIST_SUCESS',
    GET_ALL_GOLES_LIST_FAIL: 'GET_ALL_GOLES_LIST_FAIL',

    GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_REQUEST: 'GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_REQUEST',
    GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_SUCESS: 'GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_SUCESS',
    GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_FAIL: 'GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_FAIL',

    CREATE_GOAL_REQUEST: 'CREATE_GOAL_REQUEST',
    CREATE_GOAL_SUCESS: 'CREATE_GOAL_SUCESS',
    CREATE_GOAL_FAIL: 'CREATE_GOAL_FAIL',

    CLEAR_GOAL_RESPONCE: "CLEAR_GOAL_RESPONCE",

    // 
    GET_REWARD_OPTIONS_OPTIONS_REQUEST: 'GET_REWARD_OPTIONS_OPTIONS_REQUEST',
    GET_REWARD_OPTIONS_OPTIONS_SUCESS: 'GET_REWARD_OPTIONS_OPTIONS_SUCESS',
    GET_REWARD_OPTIONS_OPTIONS_FAIL: 'GET_REWARD_OPTIONS_OPTIONS_FAIL',


    GET_ACTIVE_TYPE_REQUEST: 'GET_ACTIVE_TYPE_REQUEST',
    GET_ACTIVE_TYPE_SUCESS: 'GET_ACTIVE_TYPE_SUCESS',
    GET_ACTIVE_TYPE_FAIL: 'GET_ACTIVE_TYPE_FAIL',

    GET_COMPETITION_COMMUNITY_REQUEST: 'GET_COMPETITION_COMMUNITY_REQUEST',
    GET_COMPETITION_COMMUNITY_SUCESS: 'GET_COMPETITION_COMMUNITY_SUCESS',
    GET_COMPETITION_COMMUNITY_FAIL: 'GET_COMPETITION_COMMUNITY_FAIL',

    GET_ACTIVE_OPTIONS_REQUEST: 'GET_ACTIVE_OPTIONS_REQUEST',
    GET_ACTIVE_OPTIONS_SUCESS: 'GET_ACTIVE_OPTIONS_SUCESS',
    GET_ACTIVE_OPTIONS_FAIL: 'GET_ACTIVE_OPTIONS_FAIL',

    GET_ALL_CHALLENGE_OPTIONS_LIST_SUCESS: 'GET_ALL_CHALLENGE_OPTIONS_LIST_SUCESS',
    GET_ALL_CHALLENGE_OPTIONS_LIST_FAIL: 'GET_ALL_CHALLENGE_OPTIONS_LIST_FAIL',

    GET_ALL_ACTIVE_USERS_LIST_SUCESS: 'GET_ALL_ACTIVE_USERS_LIST_SUCESS',
    GET_ALL_ACTIVE_USERS_LIST_FAIL: 'GET_ALL_ACTIVE_USERS_LIST_FAIL',

    GET_ACTIVITY_TYPES_CONTENT_PREFRENCES_LIST_SUCESS: 'GET_ACTIVITY_TYPES_CONTENT_PREFRENCES_LIST_SUCESS',
    GET_ACTIVITY_TYPES_CONTENT_PREFRENCES_LIST_FAIL: 'GET_ACTIVITY_TYPES_CONTENT_PREFRENCES_LIST_FAIL',

    GET_APPLICATION_OPTIONS_LIST_SUCESS: 'GET_APPLICATION_OPTIONS_LIST_SUCESS',
    GET_APPLICATION_OPTIONS_LIST_FAIL: 'GET_APPLICATION_OPTIONS_LIST_FAIL',

    CREATE_ACTIVE_USER_MARKETING_REQUEST: 'CREATE_ACTIVE_USER_MARKETING_REQUEST',
    CREATE_ACTIVE_USER_MARKETING_SUCESS: 'CREATE_ACTIVE_USER_MARKETING_SUCESS',
    CREATE_ACTIVE_USER_MARKETING_FAIL: 'CREATE_ACTIVE_USER_MARKETING_FAIL',

    REMOVE_USER_REQUEST: 'REMOVE_USER_REQUEST',
    REMOVE_USER_SUCESS: 'REMOVE_USER_SUCESS',
    REMOVE_USER_FAIL: 'REMOVE_USER_FAIL',

    CLEAR_USER_RESPONCE: "CLEAR_USER_RESPONCE",

    GET_USER_MARKETING_DATA_REQUEST: 'GET_USER_MARKETING_DATA_REQUEST',
    GET_USER_MARKETING_DATA_SUCESS: 'GET_USER_MARKETING_DATA_SUCESS',
    GET_USER_MARKETING_DATA_FAIL: 'GET_USER_MARKETING_DATA_FAIL',

    GET_REWAED_CHALLENGES_REQUEST: 'GET_REWAED_CHALLENGES_REQUEST',
    GET_REWAED_CHALLENGES_SUCESS: 'GET_REWAED_CHALLENGES_SUCESS',
    GET_REWAED_CHALLENGES_FAIL: 'GET_REWAED_CHALLENGES_FAIL',

    RECACULATE_lEADERBOARD_REQUEST: 'GET_REWAED_CHALLENGES_REQUEST',
    RECACULATE_lEADERBOARD_SUCESS: 'GET_REWAED_CHALLENGES_SUCESS',
    RECACULATE_lEADERBOARD_FAIL: 'GET_REWAED_CHALLENGES_FAIL',

    GET_REWAED_CHALLENGES_PARTICIPANTS_REQUEST: 'GET_REWAED_CHALLENGES_PARTICIPANTS_REQUEST',
    GET_REWAED_CHALLENGES_PARTICIPANTS_SUCESS: 'GET_REWAED_CHALLENGES_PARTICIPANTS_SUCESS',
    GET_REWAED_CHALLENGES_PARTICIPANTS_FAIL: 'GET_REWAED_CHALLENGES_PARTICIPANTS_FAIL',

    ACTIVE_SEARCH_USER_REQUEST: 'ACTIVE_SEARCH_USER_REQUEST',
    ACTIVE_SEARCH_USER_SUCESS: 'ACTIVE_SEARCH_USER_SUCESS',
    ACTIVE_SEARCH_USER_FAIL: 'ACTIVE_SEARCH_USER_FAIL',

    GET_ACTIVE_EXEL_REQUEST: 'GET_ACTIVE_EXEL_REQUEST',
    GET_ACTIVE_EXEL_SUCESS: 'GET_ACTIVE_EXEL_SUCESS',
    GET_ACTIVE_EXEL_FAIL: 'GET_ACTIVE_EXEL_FAIL',

    TRANSFER_USER_ACTIVITY_REQUEST: 'TRANSFER_USER_ACTIVITY_REQUEST',
    TRANSFER_USER_ACTIVITY_SUCESS: 'TRANSFER_USER_ACTIVITY_SUCESS',
    TRANSFER_USER_ACTIVITY_FAIL: 'TRANSFER_USER_ACTIVITY_FAIL',

    TRANSFER_USER_FOLLOWER_REQUEST: 'TRANSFER_USER_FOLLOWER_REQUEST',
    TRANSFER_USER_FOLLOWER_SUCESS: 'TRANSFER_USER_FOLLOWER_SUCESS',
    TRANSFER_USER_FOLLOWER_FAIL: 'TRANSFER_USER_FOLLOWER_FAIL',
};
// BASE URLS
export const CHALLENGE_URL = "https://framichallengeapiprod.azurewebsites.net/api/";
export const LOOKUP_URL = "https://framilookupapiprod.azurewebsites.net/api/";
export const USER_URL = "https://framiuserapiprod.azurewebsites.net/api/";
export const EVENT_URL = "https://framieventapiprod.azurewebsites.net/api/";
export const GOAL_URL = "https://framigoalapiprod.azurewebsites.net/api/";
export const REWARD_URL = "https://framirewardapiprod.azurewebsites.net/api/";
export const ACTIVITY_URL = "https://framiactivityapiprod.azurewebsites.net/api/";

// Auth Config
export const LOGIN_BASE_URL = 'https://framib2c.b2clogin.com/framib2c.onmicrosoft.com/';
export const authorityDomain = 'framib2c.b2clogin.com';

export const b2cPolicies = {
    signUpSignInGoogle: 'B2C_1_FramiSignUpSignInFlowGoogle',
    signUpSignInEmail: 'B2C_1_FramiSignUpSignInFlowEmail',
    signUpSignInFaceBook: 'B2C_1_FramiSignUpSignInFlow',
    signUpSignInApple: 'B2C_1_FramiSignUpSignInFlowApple',
};

export const msalConfig = {
    auth: {
        clientId: 'd444dbd3-217d-4d4f-b1d6-2e3136d5de0d',
        authority: LOGIN_BASE_URL + b2cPolicies.signUpSignInEmail,
        knownAuthorities: [authorityDomain],
        redirectUri: 'https://web.frami.eco',
        //redirectUri: 'https://framiwebtest.azurewebsites.net',
        //redirectUri: '/',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
        scopes: ["https://framib2c.onmicrosoft.com/0153fc9c-a153-43ea-8fe1-d3c0c7c3ee03/framib2c.auth"]
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    // system: {
    //     loggerOptions: {
    //         loggerCallback: (level, message, containsPii) => {
    //             if (containsPii) {
    //                 return;
    //             }
    //             switch (level) {
    //                 case LogLevel.Error:
    //                     console.error(message);
    //                     return;
    //                 case LogLevel.Info:
    //                     console.info(message);
    //                     return;
    //                 case LogLevel.Verbose:
    //                     console.debug(message);
    //                     return;
    //                 case LogLevel.Warning:
    //                     console.warn(message);
    //                     return;
    //                 default:
    //                     return;
    //             }
    //         },
    //     },
    // },
};