import { ActionTypes, LOOKUP_URL, EVENT_URL, GOAL_URL } from "../contants";
import { customeGetService, customePostService } from "../services/service";

const userDetails = JSON.parse(localStorage.getItem('UserDetails'))

const getGoalsList = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.GET_ALL_GOLES_LIST_REQUEST, })
    customeGetService(EVENT_URL + 'Goal/Own/' + userDetails?.userId + '?type=ACTIVE').then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_ALL_GOLES_LIST_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_ALL_GOLES_LIST_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.GET_ALL_GOLES_LIST_FAIL, data: error }) }
    )
  }
};

// Get Goal Options List
const getGoalsOptionsList = () => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_REQUEST, })
    customeGetService(LOOKUP_URL + 'Goal/Options').then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_FAIL, data: error }) }
    )
  }
};

// Create New Goal
const createGoal = (params = {}) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.CREATE_GOAL_REQUEST, })
    customePostService(GOAL_URL + 'Goal/Create', params).then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.CREATE_GOAL_SUCESS, data: response })
        }
        else { return Dispatch({ type: ActionTypes.CREATE_GOAL_FAIL, data: response.response }) }
      },
      (error) => {
        return Dispatch({ type: ActionTypes.CREATE_GOAL_FAIL, data: error })
      }
    )
  }
};

// Clear Goal Success Responce
const clearGoalResponce = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.CLEAR_GOAL_RESPONCE, })
  }
}

export {
  getGoalsList,
  getGoalsOptionsList,
  createGoal,
  clearGoalResponce,
}