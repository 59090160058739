
import { ActionTypes } from "../contants";

const intialState = {
    loading: false,
    sucess: false,
    userList: null,
};

const getActivitySearchUsersReducer = (state = intialState, action) => {
    switch (action.type) {
        case ActionTypes.ACTIVE_SEARCH_USER_REQUEST:
            return {
                loading: true,
                userList: null,
            };
        case ActionTypes.ACTIVE_SEARCH_USER_SUCESS:
            return {
                userList: action.data,
                loading: false,
            };
        case ActionTypes.ACTIVE_SEARCH_USER_FAIL:
            return {
                userList: null,
                loading: false,
            };
        default:
            return state;
    }
};

const GetActivityExcelReducer = (state = intialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_ACTIVE_EXEL_REQUEST:
            return {
                sucess: false,
                loading: true,
            };
        case ActionTypes.GET_ACTIVE_EXEL_SUCESS:
            return {
                sucess: action.data,
                loading: false,
            };
        case ActionTypes.GET_ACTIVE_EXEL_FAIL:
            return {
                sucess: null,
                loading: false,
            };
        default:
            return state;
    }
};

const transferUserActivityReducer = (state = intialState, action) => {
    switch (action.type) {
        case ActionTypes.TRANSFER_USER_ACTIVITY_REQUEST:
            return {
                sucess: false,
                loading: true,
            };
        case ActionTypes.TRANSFER_USER_ACTIVITY_SUCESS:
            return {
                sucess: action.data,
                loading: false,
            };
        case ActionTypes.TRANSFER_USER_ACTIVITY_FAIL:
            return {
                sucess: null,
                loading: false,
            };
        default:
            return state;
    }
};

const transferUserFollowersReducer = (state = intialState, action) => {
    switch (action.type) {
        case ActionTypes.TRANSFER_USER_FOLLOWER_REQUEST:
            return {
                sucess: false,
                loading: true,
            };
        case ActionTypes.TRANSFER_USER_FOLLOWER_SUCESS:
        return {
                sucess: action.data,
                loading: false,
            };
        case ActionTypes.TRANSFER_USER_FOLLOWER_FAIL:
            return {
                sucess: null,
                loading: false,
            };
        default:
            return state;
    }
};


export {
    getActivitySearchUsersReducer,
    GetActivityExcelReducer,
    transferUserActivityReducer,
    transferUserFollowersReducer
}