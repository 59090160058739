import { ActionTypes, REWARD_URL, CHALLENGE_URL, USER_URL } from "../contants";
import { customeGetService, customePostService, getService, getByIdService, createService, deleteService } from "../services/service";

// Get Active Challenges List

const creativeActivateRewardAction = (params = null) => {
    return (Dispatch) => {
        Dispatch({ type: ActionTypes.CREATE_ACTIVE_USER_MARKETING_REQUEST, })
        customeGetService(REWARD_URL + '/Reward/ActivateRewardUser' + "?challengeId=" + params.challengeId + "&userId=" + params.userId).then(
            (response) => {
                if (response.status === 200) {
                    return Dispatch({ type: ActionTypes.CREATE_ACTIVE_USER_MARKETING_SUCESS, data: response })
                }
                else { return Dispatch({ type: ActionTypes.CREATE_ACTIVE_USER_MARKETING_FAIL, data: response }) }
            },
            (error) => {
                return Dispatch({ type: ActionTypes.CREATE_ACTIVE_USER_MARKETING_FAIL, data: error })
            }
        )
    }
};

// Get Application Options List
const getRewardChallenges = (params = null) => {
    return (Dispatch) => {
        Dispatch({ type: ActionTypes.GET_REWAED_CHALLENGES_REQUEST, })
        customeGetService(REWARD_URL + 'Reward/GetChallengeRelatedToReward?isReward=true').then(
            (response) => {
                if (response.status === 200) {
                    return Dispatch({ type: ActionTypes.GET_REWAED_CHALLENGES_SUCESS, data: response.data })
                }
                else { return Dispatch({ type: ActionTypes.GET_REWAED_CHALLENGES_FAIL, data: response }) }
            },
            (error) => { return Dispatch({ type: ActionTypes.GET_REWAED_CHALLENGES_FAIL, data: error }) }
        )
    }
};

// Get Application Options List
const getRewardChallengeParticipants = (challengeId = null) => {
    return (Dispatch) => {
        Dispatch({ type: ActionTypes.GET_REWAED_CHALLENGES_PARTICIPANTS_REQUEST, })
        customeGetService(CHALLENGE_URL + 'Challenge/Participants/Accepted/' + challengeId).then(
            (response) => {
                if (response.status === 302) {
                    return Dispatch({ type: ActionTypes.GET_REWAED_CHALLENGES_PARTICIPANTS_SUCESS, data: response.data })
                }
                else { return Dispatch({ type: ActionTypes.GET_REWAED_CHALLENGES_PARTICIPANTS_FAIL, data: response }) }
            },
            (error) => { return Dispatch({ type: ActionTypes.GET_REWAED_CHALLENGES_PARTICIPANTS_FAIL, data: error }) }
        )
    }
};



export {
    creativeActivateRewardAction,
    getRewardChallenges,
    getRewardChallengeParticipants,
}