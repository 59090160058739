import { ConstructionOutlined } from "@mui/icons-material";
import { ActionTypes } from "../contants";

const intialState = {
  challengesList: null,
  challengeOptionsList: null,
  participantsList: null
};

const challengeReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_ACTIVE_CHALLENGES_LIST_REQUEST:
      return {
        loading: true,
        challengesList: null,
      };
    case ActionTypes.GET_ALL_ACTIVE_CHALLENGES_LIST_SUCESS:
      return {
        challengesList: action.data,
        loading: false,
      };
    case ActionTypes.GET_ALL_ACTIVE_CHALLENGES_LIST_FAIL:
      return {
        challengesList: null,
        loading: false,
      };
    default:
      return state;
  }
};

const challengeOptionsReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ACTIVE_CHALLENGES_OPTIONS_REQUEST:
      return {
        loading: true,
        challengeOptionsList: null,
      };
    case ActionTypes.GET_ACTIVE_CHALLENGES_OPTIONS_SUCESS:
      return {
        challengeOptionsList: action.data,
        loading: false,
      };
    case ActionTypes.GET_ACTIVE_CHALLENGES_OPTIONS_FAIL:
      return {
        challengeOptionsList: null,
        loading: false,
      };
    default:
      return state;
  }
};

const competitionCommunityReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_COMPETITION_COMMUNITY_REQUEST:
      return {
        loading: true,
        competitionCommunityList: null,
      };
    case ActionTypes.GET_COMPETITION_COMMUNITY_SUCESS:
      return {
        competitionCommunityList: action.data,
        loading: false,
      };
    case ActionTypes.GET_COMPETITION_COMMUNITY_FAIL:
      return {
        competitionCommunityList: null,
        loading: false,
      };
    default:
      return state;
  }
};

const participantsByOrganizerReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PARTICIPANTS_BY_ORGANIZER_REQUEST:
      return {
        loading: true,
        participantsList: null,
      };
    case ActionTypes.GET_PARTICIPANTS_BY_ORGANIZER_SUCESS:
      return {
        participantsList: action.data,
        loading: false,
      };
    case ActionTypes.GET_PARTICIPANTS_BY_ORGANIZER_FAIL:
      return {
        participantsList: null,
        loading: false,
      };
    default:
      return state;
  }
};

const rewardOptionsReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_REWARD_OPTIONS_OPTIONS_REQUEST:
      return {
        loading: true,
        rewardOptionsList: null,
      };
    case ActionTypes.GET_REWARD_OPTIONS_OPTIONS_SUCESS:
      return {
        rewardOptionsList: action.data,
        loading: false,
      };
    case ActionTypes.GET_REWARD_OPTIONS_OPTIONS_FAIL:
      return {
        rewardOptionsList: null,
        loading: false,
      };
    default:
      return state;
  }
};

const createChalangeReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_CHALANGE_REQUEST:
      return {
        loading: true,

      };
    case ActionTypes.CREATE_CHALANGE_SUCESS:
      return {
        data: action.data,
        sucess: true,
        loading: false,
      };
    case ActionTypes.CREATE_CHALANGE_FAIL:
      return {
        sucesss: false,
        loading: false,
      };
    case ActionTypes.CLEAR_CHALLENGES_RESPONCE:
      return {
      };
    default:
      return state;
  }
};

const challengesDatailsByIdReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CHALLENGES_DETAILS_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case ActionTypes.GET_CHALLENGES_DETAILS_BY_ID_SUCESS:
      if (action.data.status == 404) {
        return {
          challengesDatails: { message: "Challenge not found." },
          sucess: false,
          loading: false,
        };
      } else {
        return {
          challengesDatails: action.data.data,
          sucess: true,
          loading: false,
        };
      }
    case ActionTypes.GET_CHALLENGES_DETAILS_BY_ID_FAIL:
      return {
        sucesss: false,
        loading: false,
      };
    case ActionTypes.RESET_CHALLENGES_DETAILS_BY_ID:
      return {
        sucesss: false,
        loading: false,
      };
    default:
      return state;
  }
};

const updateChallengesDatailsByIdReducer = (state = intialState, action) => {
  switch (action.type) {

    case ActionTypes.UPDATE_CHALANGE_REQUEST:
      return {
        loading: true,
      };
    case ActionTypes.UPDATE_CHALANGE_SUCESS:
      return {
        challengesDatails: action.data,
        sucess: true,
        loading: false,
      };
    case ActionTypes.UPDATE_CHALANGE_FAIL:
      return {
        sucesss: false,
        loading: false,
      };
    case ActionTypes.CLEAR_CHALLENGES_RESPONCE:
      return {

      };
    default:
      return state;
  }
};

const removeChallengeReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.REMOVE_CHALANGE_REQUEST:
      return {
        loading: true,
      };
    case ActionTypes.REMOVE_CHALANGE_SUCESS:
      return {
        data: action.data,
        sucess: true,
        loading: false,
      };
    case ActionTypes.REMOVE_CHALANGE_FAIL:
      return {
        sucesss: false,
        loading: false,
      };
    default:
      return state;
  }
};

const GetChallengeRelatedActivitiesReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CHALLENGE_RELATED_ACTIVITIES_REQUEST:
      return {
        loading: true,
      };
    case ActionTypes.GET_CHALLENGE_RELATED_ACTIVITIES_SUCESS:
      return {
        data: action.data,
        sucess: true,
        loading: false,
      };
    case ActionTypes.GET_CHALLENGE_RELATED_ACTIVITIES_FAIL:
      return {
        sucesss: false,
        loading: false,
      };
    default:
      return {
        data: false,
        sucess: false,
        loading: false,
      };
  }
};

const recalculateLeaderboardReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.RECACULATE_lEADERBOARD_REQUEST:
      return {
        loading: true,
      };
    case ActionTypes.RECACULATE_lEADERBOARD_SUCESS:
      return {
        data: action.data,
        sucess: true,
        loading: false,
      };
    case ActionTypes.RECACULATE_lEADERBOARD_FAIL:
      return {
        sucesss: false,
        loading: false,
      };
    default:
      return {
        data: false,
        sucess: false,
        loading: false,
      };
  }
};

export {
  challengeReducer,
  challengeOptionsReducer,
  competitionCommunityReducer,
  participantsByOrganizerReducer,
  rewardOptionsReducer,
  createChalangeReducer,
  challengesDatailsByIdReducer,
  updateChallengesDatailsByIdReducer,
  removeChallengeReducer,
  GetChallengeRelatedActivitiesReducer,
  recalculateLeaderboardReducer
}








