import React from 'react';
import '../assets/css/custome.css'; // Import your spinner's CSS here

const CustomSpinner = () => {
  return (
    <div className="custom-spinner">
      {/* Your custom spinner content goes here */}
      <div className="spinner-loader"></div>
    </div>
  );
};

export default CustomSpinner;
