import react from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select } from "@mui/material";
import { ErrorMessage, Field } from 'formik';
import { Icon } from "@mui/material";


const ImageDropdownComponent = ({ titel, fieldName, onChangeEvent, options = [], isMultiple = false, selectedData = [] }) => {

    return (
        options.length &&
        <FormControl fullWidth>
            <InputLabel>{titel}</InputLabel>
            <Field
                as={Select}
                value={selectedData}
                name={fieldName}
                onChange={onChangeEvent}
                label={titel}
                multiple={isMultiple}
            >
                {options &&
                    options.map(
                        (option) => {
                            return (
                                <MenuItem className="menuItemOption" key={option.id} value={option}>
                                    {(option?.imageUrl && option?.imageUrl != "") || option?.profilePhotoUrl && option?.profilePhotoUrl != ""
                                        ? <img
                                            src={option.imageUrl || option.profilePhotoUrl}
                                            alt={option.name}
                                            className="communityImg"
                                        />
                                        : <Icon fontSize={"large"} className="communityImg" sx={{ fontWeight: "bold" }}>account_circle</Icon>
                                    }
                                    &nbsp;&nbsp;
                                    <span>{option.name ? option.name : option.userName}</span>
                                </MenuItem>
                            );
                        }
                    )}
            </Field>
            <ErrorMessage
                name={fieldName}
                component="div"
                className="error-message"
            />
        </FormControl >
    );
}

export default ImageDropdownComponent;