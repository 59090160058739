import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate, redirect } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import theme from "assets/theme";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav } from "context";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { MsalProvider } from "@azure/msal-react";
import { connect } from "react-redux";
import Dashboard from "./layouts/dashboard";
import Notification from "./layouts/Notification";
import SignIn from "./layouts/authentication/sign-in";
import { isAllradyLoginAction, logOutActopn } from "./redux/actions/loginActions"
import logo from "./assets/images/frami/frami.png"
import Challenges from "./layouts/challenges";
import Challengeform from "./layouts/challenges/form";
import ChallengeUpdate from "./layouts/challenges/update";
import Events from "layouts/events";
import Eventform from "layouts/events/form";
import EventUpdate from "layouts/events/update";

import Goals from "layouts/goals";
import Goalform from "layouts/goals/form";
import IsUserHasPermetionComponent from "layouts/commonComponents/IsUserHasPermetionComponent";
import UnauthorizedComponent from "layouts/commonComponents/UnauthorizedComponent";

import RewardForm from "layouts/reward/form.js";
import UserIndex from "layouts/user/index.js";
import ChallengeRelatedActivitiesForm from "layouts/challenges/ChallengeRelatedActivitiesForm.js";
import ActivityForm from "layouts/activity/form.js";
import TransferUserActivity from "layouts/activity/TransferUserActivity.js";
import TransferUserFollowers from "layouts/activity/TransferUserFollowers";
import RemoveUser from "layouts/removeUser/index.js";

const App = ({ errorDetails, successReducer, msalInstanceData, userDetails, isAllradyLoginAction, logOutActopn }) => {
  const [controller, dispatch] = useMaterialUIController();

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const { miniSidenav, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode, } = controller;

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false)


  useEffect(() => {

    if (userDetails && pathname === "/") {
      navigate('/dashboard')
    }
    else if (!userDetails && localStorage.getItem('UserDetails') && localStorage.getItem('UserDetails') != '') {
      isAllradyLoginAction();
    }
    else if (pathname !== "/" && (!localStorage.getItem('UserDetails') || !localStorage.getItem('token'))) {
      logOutActopn();
      navigate('/')

    }


    setIsGlobalAdmin(userDetails && userDetails?.userRoles?.filter((item) => item.roleType == "GlobalCommunityAdmin").length ? true : false)
  }, [userDetails])

  useEffect(() => {
    if (errorDetails?.error?.response?.status == 401) {
      logOutActopn();
      navigate('/')
    }
  }, [errorDetails])

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <MsalProvider instance={msalInstanceData}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthenticatedTemplate>
          {/* {userDetails?.userRoles && !isGlobalAdmin
            &&
            <UnauthorizedComponent />
          } */}
          <Routes>
            <Route path="*" element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<Navigate to="/dashboard" />} />} />
            <Route path="/dashboard" element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<Dashboard />} />} />
            <Route path='/challenges' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<Challenges />} />} />
            <Route path='/challenge' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<Challengeform />} />} />
            <Route path='/challenge/:challengeId' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<ChallengeUpdate />} />} />
            <Route path='/events' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<Events />} />} />
            <Route path='/event' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<Eventform />} />} />
            <Route path='/event/:eventId?' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<EventUpdate />} />} />
            <Route path='/goals' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<Goals />} />} />
            <Route path='/goal/:goalId?' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<Goalform />} />} />

            <Route path='/support_tool/activate-user-reward' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<RewardForm />} />} />
            <Route path='/support_tool/export-user-marketing-details' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<UserIndex />} />} />
            <Route path='/support_tool/export-challenge-activities' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<ChallengeRelatedActivitiesForm />} />} />

            <Route path='/support_tool/export-user-activity' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<ActivityForm />} />} />
            <Route path='/support_tool/transfer-user-activity' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<TransferUserActivity />} />} />
            <Route path='/support_tool/remove-user' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<RemoveUser />} />} />
            <Route path='/support_tool/transfer-user-followers' element={<IsUserHasPermetionComponent userDetails={userDetails} chieldComponent={<TransferUserFollowers />} />} />

            <Route path='/' element={<SignIn />} />
            <Route path='/401' element={<UnauthorizedComponent />} />
          </Routes>
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={logo}
                brandName=""
                userDetails={userDetails}
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="/" element={<SignIn />} />
          </Routes>
          {/* <SignIn /> */}
        </UnauthenticatedTemplate>
        {errorDetails.message &&
          <Notification
            color="error"
            icon="warning"
            title={errorDetails.message}
            content={errorDetails.description}
            dateTime=""
          />}
        {successReducer.message &&
          <Notification
            color="success"
            icon="check"
            title={successReducer.message}
            content={successReducer.Description}
            dateTime=""
          />}
      </ThemeProvider >
    </MsalProvider >
  );
}

const mapStateToProps = (state) => {
  return {
    msalInstanceData: state.msalInstanceReducer.msalInstance,
    userDetails: state.logedinUserReducer.userDetails,
    errorDetails: state.errorReducer,
    successReducer: state.successReducer
  };
};

export default connect(mapStateToProps, { isAllradyLoginAction, logOutActopn })(App);
