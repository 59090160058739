import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MDInput from "components/MDInput";

import { Select, Icon, Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { Formik, ErrorMessage, Field, Form, } from 'formik';
import * as Yup from 'yup';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import { Link, useNavigate } from "react-router-dom";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import { confirmAlert } from "react-confirm-alert";

import { creativeActivateRewardAction, getRewardChallenges, getRewardChallengeParticipants } from "redux/actions/rewardAction";


function Event({ activateRewardReducer, rewardChallengesReducer, rewardChallengeParticipantsReducer,
  getRewardChallenges, getRewardChallengeParticipants, creativeActivateRewardAction, }) {

  const navigate = useNavigate();

  const initialValues = {
    "userId": null,
    "challengeId": null,
  };

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(initialValues);

  const formValidationSchema = {
    userId: Yup.object().required("Please select user!"),
    challengeId: Yup.object().required("Please select challenge!"),
  };

  useEffect(() => {
    getRewardChallenges()
  }, []);

  useEffect(() => {
    if (activateRewardReducer?.activateReward) {
      setIsLoading(false);
      // setFormInitialValues(initialValues)
      // setTimeout(() => {
      //   navigate("/support_tool/activate-user-reward")
      // }, 2000);
    }
  }, [activateRewardReducer])

  useEffect(() => {
    if (rewardChallengesReducer?.rewardChallengeList && !rewardChallengesReducer.rewardChallengeList?.length > 0) {
      setIsError("There is no challenges available for this account.")
    }
    else {
      setIsError(null)
    }
  }, [rewardChallengesReducer])


  useEffect(() => {
    if (rewardChallengeParticipantsReducer?.rewardChallengeParticipantsList?.length) {
      setIsLoading(false);
    }
  }, [rewardChallengeParticipantsReducer])

  const getParticipants = (value) => {
    if (value) {
      getRewardChallengeParticipants(value.challengeId)
      setIsLoading(true);
      setFormInitialValues({
        "userId": null,
        "challengeId": value,
      })
    }
    else {
      setFormInitialValues(initialValues)
    }
  }

  const onFormSubmit = (values, actions) => {
    confirmAlert({
      title: "",
      message: "Are you sure to activate user reward?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setIsLoading(true)
            creativeActivateRewardAction({
              userId: values.userId.userId,
              challengeId: values.challengeId.challengeId
            })
          }
        },
        {
          label: "No"
          // onClick: () => alert("Click No")
        }
      ]
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mx={0} mb={2} mt={3} py={3} px={2} variant="gradient" bgColor="success" borderRadius="lg" coloredShadow="info">
          <MDTypography variant="h6" color="white">
            Activate User Reward
          </MDTypography>
        </MDBox>
        {isError && (
          <MDBox mx={0} mb={2} mt={3} py={3} px={2} variant="contained" bgColor="error" borderRadius="lg" coloredShadow="info">
            <MDTypography variant="h6" color="white">
              {isError}
            </MDTypography>
          </MDBox>

          // <MDSnackbar
          //   color="error" icon="warning" title={isError} content={isError} open={isError} bgWhite
          //   onClose={() => { setIsError(false) }}
          //   close={() => { setIsError(false) }}
          // />
        )}
        <MDBox mb={3}>
          {(rewardChallengesReducer?.loading || isLoading) && <CustomSpinner />
          }
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Formik
              enableReinitialize={true}
              initialValues={formInitialValues}
              validationSchema={Yup.object().shape(formValidationSchema)}
              onSubmit={onFormSubmit}
            >
              {formikProps => (
                <Form>
                  <Card>
                    {/* <MDBox pt={3} px={2}>
                            <MDTypography variant="h6" fontWeight="medium">Activate User Reward</MDTypography>
                          </MDBox> */}
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} py={3} px={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Field
                            as={Autocomplete}
                            name="challengeId"
                            options={rewardChallengesReducer?.rewardChallengeList ? rewardChallengesReducer?.rewardChallengeList : []}
                            defaultValue={formikProps.values?.challengeId}
                            value={formikProps.values?.challengeId}
                            onChange={(event, value) => {
                              formikProps.setFieldValue("challengeId", value);
                              getParticipants(value)
                            }}
                            getOptionLabel={(option) => option.challengeName}
                            renderOption={(props, option) => (
                              <Box component="li" {...props} key={option.challengeId}>
                                {option?.challengeImagesUrl.length && option?.challengeImagesUrl[0] != ""
                                  ? <img
                                    src={option?.challengeImagesUrl[0]}
                                    alt={option.challengeName}
                                    className="communityImg"
                                  />
                                  : <Icon fontSize={"large"} className="communityImg" sx={{ fontWeight: "bold" }}>account_circle</Icon>
                                }
                                &nbsp;&nbsp;
                                <span>{option.challengeName}</span>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Challenge"
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                          <ErrorMessage name="challengeId" component="div" className="error-message" />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          {/* <InputLabel>User</InputLabel> */}
                          <Field
                            as={Autocomplete}
                            name="userId"
                            options={rewardChallengeParticipantsReducer?.rewardChallengeParticipantsList ? rewardChallengeParticipantsReducer.rewardChallengeParticipantsList : []}
                            defaultValue={formikProps.values?.userId}
                            value={formikProps.values?.userId}
                            onChange={(event, value) => {
                              formikProps.setFieldValue("userId", value);
                              setFormInitialValues({
                                ...formInitialValues,
                                "userId": value,
                              })
                            }}
                            getOptionLabel={(option) => option.userName}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.userId}>
                                {option?.profilePhotoUrl && option?.profilePhotoUrl != ""
                                  ? <img
                                    srcSet={option.profilePhotoUrl}
                                    src={option.profilePhotoUrl}
                                    alt={option.userName}
                                    className="communityImg"
                                  />
                                  : <Icon fontSize={"large"} className="communityImg communityImgIcon" >account_circle</Icon>
                                }
                                &nbsp;&nbsp;
                                <span>{option.userName}</span>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="User"
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                          <ErrorMessage name="userId" component="div" className="error-message" />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  <MDBox display="flex" justifyContent="end" alignItems="center" mt={3} >
                    <MDButton type="submit" size="medium" color="success">Activate User Reward</MDButton>
                    &nbsp;
                    <Link to="/dashboard">
                      <MDButton type="button" size="medium" color="error">Back</MDButton>
                    </Link>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </LocalizationProvider>
        </MDBox>
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}

const mapStateToProps = (state) => {
  return {
    activateRewardReducer: state.activateRewardReducer,
    rewardChallengesReducer: state.rewardChallengesReducer,
    rewardChallengeParticipantsReducer: state.rewardChallengeParticipantsReducer
  };
};

export default connect(mapStateToProps, { getRewardChallenges, getRewardChallengeParticipants, creativeActivateRewardAction })(Event);