import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import MDInput from "components/MDInput";
import { Button, Icon } from "@mui/material";

import FormControl from "@mui/material/FormControl";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import * as Yup from "yup";

import {
  getChallengesDatailsById,
  resetChallengesDatailsById,
  updateChallengeById,
} from "../../redux/actions/challengeActions";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

function ChallengeUpdate({ challengesDatailsByIdReducer, updateChallengesDatailsByIdReducer, getChallengesDatailsById, updateChallengeById, resetChallengesDatailsById }) {

  let { challengeId } = useParams();

  const navigate = useNavigate();

  const initialValues = {
    ChallengeImages: null,
    ChallengeName: "",
    Objective: "",
    Description: "",
  };

  const [formData, setFormData] = useState(initialValues);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [challengeImages, setChallengeImages] = useState(null);

  useEffect(() => {
    if (challengeId) {
      getChallengesDatailsById(challengeId)
      setIsLoading(true)
    }
    return () => {
      resetChallengesDatailsById()
    }
  }, []);

  useEffect(() => {
    if (challengesDatailsByIdReducer.sucess) {
      setFormData({
        ChallengeImagesUrl: challengesDatailsByIdReducer.challengesDatails.challengeImagesUrl,
        ChallengeImages: challengesDatailsByIdReducer.challengesDatails.challengeImagesUrl[challengesDatailsByIdReducer.challengesDatails.challengeImagesUrl.length - 1],
        ChallengeName: challengesDatailsByIdReducer.challengesDatails.challengeName,
        Objective: challengesDatailsByIdReducer.challengesDatails.objective,
        Description: challengesDatailsByIdReducer.challengesDatails.description,
      })
    }
    else if (!challengesDatailsByIdReducer.sucess && challengesDatailsByIdReducer.challengesDatails) {
      setIsError(challengesDatailsByIdReducer.challengesDatails.message)
      setTimeout(() => {
        navigate('/challenges')
      }, 2000);
    }
    setIsLoading(false);

  }, [challengesDatailsByIdReducer])

  useEffect(() => {
    if (updateChallengesDatailsByIdReducer?.sucess === true) {
      setIsLoading(false);

      setTimeout(() => {
        navigate("/challenges")
      }, 2000);

    } else if (updateChallengesDatailsByIdReducer?.sucesss === false) {
      setIsError("Somthig went to wrong, try again!!");
      setIsLoading(false);
    }
  }, [updateChallengesDatailsByIdReducer])
  const validationSchema = {
    ChallengeName: Yup.string().required("Challenge Name is required"),
    Objective: Yup.string().required("Challenge Objective is required"),
    Description: Yup.string().required("Challenge Description is required"),
  };

  const onFormSubmit = (values, actions) => {
    setIsLoading(true);
    const formSubmitData = new FormData();
    formSubmitData.append("ChallengeId", challengeId)
    formSubmitData.append("ChallengeName", values.ChallengeName)
    formSubmitData.append("Description", values.Description)
    formSubmitData.append("Objective", values.Objective)
    formSubmitData.append("ChallengeImages", challengeImages)
    if (!challengeImages) {
      formSubmitData.append("ChallengeImagesUrl", values.ChallengeImagesUrl)
    }

    updateChallengeById(formSubmitData);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox
          mx={0}
          mb={2}
          mt={3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            Challenge
          </MDTypography>
        </MDBox>
        {isError && (
          <MDSnackbar
            color="error"
            icon="warning"
            title={isError}
            content={isError}
            open={isError}
            onClose={() => { setIsError(false) }}
            close={() => { setIsError(false) }}
            bgWhite
          />
        )}
        <MDBox mb={3}>
          {(!challengesDatailsByIdReducer.sucess || isLoading) && <CustomSpinner />}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Formik
              enableReinitialize={true}
              initialValues={formData}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={(values, actions) => {
                onFormSubmit(values, actions);
              }}
            >
              {(formikProps) => {
                return (<Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <Card>
                        <MDBox pt={3} px={2}>
                          <MDTypography variant="h6" fontWeight="medium">
                            Challenge Image
                          </MDTypography>
                        </MDBox>
                        {formikProps?.values?.ChallengeImages && (
                          <img alt="preview image" src={formikProps.values.ChallengeImages} />
                        )}
                        <ErrorMessage
                          name="ChallengeImages"
                          component="div"
                          className="error-message"
                        />
                        <FormControl fullWidth>
                          <Button variant="contained" component="label" color="white">
                            Upload File
                            <input
                              type="file"
                              name="ChallengeImages"
                              accept="image/*"
                              onChange={(event) => {
                                setChallengeImages(event.target.files[0]);
                                formikProps.setFieldValue("ChallengeImages", URL.createObjectURL(event.target.files[0]));
                              }}
                              hidden
                              className="filetypeInput"
                            />
                          </Button>

                        </FormControl>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Card>
                        <MDBox pt={3} px={2}>
                          <MDTypography variant="h6" fontWeight="medium">
                            Details
                          </MDTypography>
                        </MDBox>
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          py={3}
                          px={2}
                        >

                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              {/* <InputLabel>Challenge Name</InputLabel> */}
                              <Field
                                as={MDInput}
                                type="text"
                                name="ChallengeName"
                                label="Challenge Name"
                              />
                              <ErrorMessage
                                name="ChallengeName"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <Field
                                as={MDInput}
                                type="text"
                                name="Objective"
                                label="Challenge Objective"
                              />
                              <ErrorMessage
                                name="Objective"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <Field
                                as={MDInput}
                                type="text"
                                multiline
                                name="Description"
                                label="Challenge Description"
                                rows={3}
                              />
                              <ErrorMessage
                                name="Description"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>

                  </Grid>
                  <MDBox display="flex" justifyContent="end" alignItems="center" mt={3} >
                    <MDButton type="submit" size="medium" color="success">Update</MDButton>
                    &nbsp;
                    <Link to="/challenges">
                      <MDButton type="button" size="medium" color="error">Back</MDButton>
                    </Link>
                  </MDBox>
                </Form>)
              }}
            </Formik>
          </LocalizationProvider>

        </MDBox>
      </MDBox >
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.logedinUserReducer.userDetails,
    challengesDatailsByIdReducer: state.challengesDatailsByIdReducer,
    updateChallengesDatailsByIdReducer: state.updateChallengesDatailsByIdReducer,
  };
};

export default connect(mapStateToProps, {
  getChallengesDatailsById,
  updateChallengeById,
  resetChallengesDatailsById,
})(ChallengeUpdate);