import { NestedSelect } from 'multi-nested-select'
import { useEffect, useState } from "react";

const ActivityDropdown = ({ data, onDropdownChange }) => {

  const [selectData, setSelectData] = useState();

  useEffect(() => {
    if (data) {

      setSelectData(data.map((item) => {
        const { value, title, key, ...rest } = item;
        return {
          ...rest,
          name: title,
          code: key,
          provinceKey: key,
          zones: value.map((zone) => {
            const { name, key, ...zoneRest } = zone;
            return {
              ...zoneRest, name,
              code: zone.key
            }
          }),
        }
      }))

    }
  }, [data])


  return (<NestedSelect
    enableButton={false}
    state={true}
    width={"100%"}
    height={350}
    leading={true}
    chip={true}
    chipCount={10}
    //   error={erTg}
    //   helperText="error occured"
    placeholder="Select Activity"
    trailing={true}
    trailingIcon={true}
    inputClass="myCustom_text"
    continent={false}
    omitSelected={false}
    expandChip={true}
    selectedValue={[]}


    // chip={true}
    // limit={5}
    placeholderCtx={true}
    selectAllOption={false}
    dropDownClass="multi_nested_select "


    showCustomList={selectData}
    // selectAllOption={true}
    selectAllTitle={"select All Option"}
    onViewmore={(event) => onDropdownChange(event)}
    onChipDelete={(event) => onDropdownChange(event)}
    onChange={(event) => onDropdownChange(event)}
    callback={(event) => onDropdownChange(event)}
  />

  );
}

export default ActivityDropdown;