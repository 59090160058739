import { ActionTypes, USER_URL } from "../contants";
import { customeDeleteService, customeGetService, } from "../services/service";


// Get Remove Event
const removeUser = (params = null) => {
    return (Dispatch) => {
        Dispatch({ type: ActionTypes.REMOVE_USER_REQUEST, })
        customeDeleteService(USER_URL + 'User/DeleteUserFromWeb/' + params).then(
            (response) => {
                if (response?.status === 200) {
                    return Dispatch({ type: ActionTypes.REMOVE_USER_SUCESS, data: response })
                }
                else { return Dispatch({ type: ActionTypes.REMOVE_USER_FAIL, data: response }) }
            },
            (error) => { return Dispatch({ type: ActionTypes.REMOVE_USER_FAIL, data: error }) }
        )
    }
};
const clearUserResponce = (params = null) => {
    return (Dispatch) => {
        Dispatch({ type: ActionTypes.CLEAR_USER_RESPONCE, })
    }
}

const GetUserMarketingData = (params = null) => {
    return (Dispatch) => {
        Dispatch({ type: ActionTypes.GET_USER_MARKETING_DATA_REQUEST, })
        customeGetService(USER_URL + "User/GetUserMarketingData").then(
            (response) => {
                if (response?.message) {
                    downloadExcelXLSX(response?.data, 'UserMarketingData')
                    return Dispatch({ type: ActionTypes.GET_USER_MARKETING_DATA_SUCESS, data: response })
                }
                else { return Dispatch({ type: ActionTypes.GET_USER_MARKETING_DATA_FAIL, data: response.response }) }
            },
            (error) => {
                return Dispatch({ type: ActionTypes.GET_USER_MARKETING_DATA_FAIL, data: error })
            }
        )
    }
};


function downloadExcelXLSX(base64Txt, fileName) {

    let day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
    let month = new Date().getMonth() < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
    let year = new Date().getFullYear();
    let today = day + month + year

    let linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Txt}`;
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', `${fileName}${today}.xlsx`);
    downloadLink.href = linkSource;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export {
    GetUserMarketingData,
    removeUser,
    clearUserResponce,
}