import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select } from "@mui/material";

import { Formik, ErrorMessage, Field, Form, } from 'formik';
import * as Yup from 'yup';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import MDInput from "components/MDInput";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import dayjs from "dayjs";

import { getGoalsOptionsList, createGoal } from "redux/actions/goalActions";
import { getActivityTypes, getApplicationOptions } from "redux/actions/commonAction";

import ImageDropdownComponent from "layouts/commonComponents/ImageDropdownComponent";
import ActivityDropdownComponent from "layouts/commonComponents/ActivityDropdownComponent";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function Event({ userDetails, goalOptionsReducer, activityTypeDetails, activityOptionsDetails, ceateGoalReducer,
  getGoalsOptionsList, createGoal, getActivityTypes, getApplicationOptions }) {

  const navigate = useNavigate();
  let isGlobalAdmin = userDetails.userRoles.filter((item) => item.roleType == "GlobalCommunityAdmin").length ? true : false;

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [recurringEndDate, setRecurringEndDate] = useState(dayjs());

  const formInitialValues = {
    "Organizer": null,
    "all_activity": true,
    "ActivityTypes": [],
    "GoalName": "",
    "Description": "",
    "GoalType": "",
    "Duration": "",
    "TargetValue": 0,
    "TargetUnit": "",
    "StartDate": startDate,
    "EndDate": endDate,
    "IsRecurringGoal": "No"
  };

  const formValidationSchema = {
    GoalName: Yup.string().required("Goal Name required"),
    Description: Yup.string().required("Description required"),
    GoalType: Yup.string().required("Goal Type required"),
    Duration: Yup.string().required("Duration required"),
    TargetValue: Yup.number().min(1, "Must be more than 0").required("Target Value requried"),
    TargetUnit: Yup.string().required("Target Unit required"),
  };

  const [formValues, setFormValues] = useState(formInitialValues);
  const [dinamicValidationSchema, setDinamicValidationSchema] = useState(formValidationSchema);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [targetUnitOptions, setTargetUnitOptions] = useState(null);

  useEffect(() => {
    // setIsLoading(true)
    getGoalsOptionsList();
    getActivityTypes();
    getApplicationOptions();
  }, []);

  useEffect(() => {
    if (goalOptionsReducer?.goalOptionsList) {
      let targetUnitValues = getTargetUnit(goalOptionsReducer?.goalOptionsList?.goalType[0].key)
      setFormValues({
        ...formValues, "GoalType": goalOptionsReducer?.goalOptionsList?.goalType[0].key, "Duration": goalOptionsReducer.goalOptionsList.duration[0].key,
        "TargetUnit": targetUnitValues
      });
    }
  }, [goalOptionsReducer])

  useEffect(() => {
    if (ceateGoalReducer.sucess) {
      setIsLoading(false);

      setTimeout(() => {
        navigate("/goals")
      }, 2000);

    } else if (ceateGoalReducer?.sucesss === false) {
      setIsError("Somthig went to wrong, try again!!");
      setIsLoading(false);
    }

  }, [ceateGoalReducer])

  const getTargetUnit = (unit) => {
    let unitValues = goalOptionsReducer?.goalOptionsList.goalTypeTargetUnits.find(item => item.CategoryType == unit).TargetUnits
    let menuItem = unitValues.map((item) => {
      return (<MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>);
    });
    setFormValues({ ...formValues, "TargetUnit": unitValues[0].key });
    setTargetUnitOptions(menuItem);
    return unitValues[0].key
  };

  const handleDateChange = (date, duration = formValues.Duration) => {
    let end = null;
    switch (duration) {
      case "Day":
        end = dayjs(date);
        break;
      case "Week":
        end = dayjs(date).add(1, 'week');
        break;
      case "Month":
        end = dayjs(date).add(1, 'month');
        break;
      case "Custom":
        end = date
        break;
      default:
        break;
    }
    setEndDate(end)
  }

  const onFormSubmit = (values, actions) => {

    setIsLoading(true)

    const formData = new FormData();
    if (values.Organizer) {
      formData.append("OnBehalfOf.Id", values.Organizer.id);
      formData.append("OnBehalfOf.Name", values.Organizer.name);
      formData.append("OnBehalfOf.ImageUrl", values.Organizer.imageUrl);
      formData.append("OnBehalfOf.OrganizerPrivacy", values.Organizer.organizerPrivacy);
      formData.append("OnBehalfOf.OrganizerType", values.Organizer.organizerType);
      formData.append("RelatedType", values.Organizer.organizerType);
      formData.append("RelatedId", values.Organizer.id);
    } else {
      setIsLoading(false)
      setIsError("Select organizer first!!");
      return false
    }
    formData.append("GoalName", values.GoalName);
    formData.append("Description", values.Description);

    if (values.all_activity) {
      formData.append("ActivityTypes[0].key", activityOptionsDetails.activityOptionList.allActivityType.key);
      formData.append("ActivityTypes[0].name", activityOptionsDetails.activityOptionList.allActivityType.name);
      formData.append("ActivityTypes[0].icon", activityOptionsDetails.activityOptionList.allActivityType.icon);
      formData.append("ActivityTypes[0].combinationNo", activityOptionsDetails.activityOptionList.allActivityType.combinationNo);
    } else {
      if (values.activities.length) {
        let count = 0
        values.activities.forEach((item, index) => {
          item.zones.forEach((activity) => {
            formData.append("ActivityTypes[" + count + "].key", activity.code)
            formData.append("ActivityTypes[" + count + "].name", activity.name)
            formData.append("ActivityTypes[" + count + "].icon", activity.icon)
            formData.append("ActivityTypes[" + count + "].combinationNo", activity.combinationNo)
            count++
          })
        })
      }
      else {
        setIsError("Select activitys first!!");
        setIsLoading(false)
        return true;
      }
    }
    formData.append("GoalType", values.GoalType);
    formData.append("Target.Unit", values.TargetUnit);
    formData.append("Target.Value", values.TargetValue);
    formData.append("Duration", values.Duration);
    formData.append("StartDate", new Date(startDate).toLocaleDateString("en-CA"));
    formData.append("EndDate", new Date(endDate).toLocaleDateString("en-CA"));


    createGoal(formData)
  }


  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mx={0} mb={2} mt={3} py={3} px={2} variant="gradient" bgColor="success" borderRadius="lg" coloredShadow="info">
          <MDTypography variant="h6" color="white">
            Goal
          </MDTypography>
        </MDBox>
        {isError && (
          <MDSnackbar
            color="error" icon="warning" title={isError} content={isError} open={isError} bgWhite
            onClose={() => { setIsError(false) }}
            close={() => { setIsError(false) }}
          />
        )}
        <MDBox mb={3}>
          {
            goalOptionsReducer.loading || activityTypeDetails.loading || activityOptionsDetails.loading || isLoading ?
              (<CustomSpinner />)
              : (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={Yup.object().shape(dinamicValidationSchema)}
                    onSubmit={onFormSubmit}
                  >
                    {formikProps => (
                      <Form>
                        <Card>
                          <MDBox pt={3} px={2}>
                            <MDTypography variant="h6" fontWeight="medium">Goal create</MDTypography>
                          </MDBox>
                          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} py={3} px={2}>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                {goalOptionsReducer?.goalOptionsList?.organizers &&
                                  <ImageDropdownComponent titel={"On behalf of"} fieldName={"Organizer"} onChangeEvent={
                                    (inputData) => {
                                      formikProps.setFieldValue("Organizer", inputData.target.value);
                                    }} options={goalOptionsReducer.goalOptionsList.organizers} selectedData={formikProps.values.Organizer} />
                                }
                                <ErrorMessage name="Organizer" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              {activityTypeDetails?.activityTypeList &&
                                <ActivityDropdownComponent data={activityTypeDetails.activityTypeList} onActivitySelct={(selectedData) => {
                                  if (selectedData) {
                                    formikProps.setFieldValue("all_activity", false);
                                    formikProps.setFieldValue("activities", selectedData)
                                  } else {
                                    formikProps.setFieldValue("all_activity", true);
                                    formikProps.setFieldValue("activities", [])
                                  }
                                }} />
                              }
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                {/* <InputLabel>Activity goal name </InputLabel> */}
                                <Field
                                  as={MDInput}
                                  type="text"
                                  name="GoalName"
                                  label="Activity goal name"
                                />
                                <ErrorMessage name="GoalName" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                {/* <InputLabel>Location Address</InputLabel> */}
                                <Field
                                  as={MDInput}
                                  type="text"
                                  name="Description"
                                  label="Description"
                                  multiline
                                  rows={2}
                                />
                                <ErrorMessage name="Description" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <InputLabel>Goal Type </InputLabel>
                                <Field
                                  as={Select}
                                  name="GoalType"
                                  defaultValue={goalOptionsReducer.goalOptionsList && goalOptionsReducer.goalOptionsList.goalType[0].key}
                                  label="Goal Type "
                                  onChange={(event) => {
                                    formikProps.setFieldValue("GoalType", event.target.value);
                                    getTargetUnit(event.target.value)
                                  }}
                                >
                                  {goalOptionsReducer?.goalOptionsList?.goalType &&
                                    goalOptionsReducer.goalOptionsList.goalType.map((type) => {
                                      return (<MenuItem key={type.key} value={type.key}>{type.value}</MenuItem>);
                                    })}
                                </Field>
                                <ErrorMessage name="GoalType" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                {/* <InputLabel>Target Value</InputLabel> */}
                                <Field
                                  as={MDInput}
                                  type="number"
                                  defaultValue={0}
                                  name="TargetValue"
                                  label="Target Value"
                                />
                                <ErrorMessage name="TargetValue" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              {targetUnitOptions &&
                                <FormControl fullWidth>
                                  <InputLabel>Target Unit</InputLabel>
                                  <Field
                                    as={Select}
                                    name="TargetUnit"
                                    label="Target Unit"
                                    onChange={(event) => {
                                      formikProps.setFieldValue("TargetUnit", event.target.value);
                                    }}
                                  >
                                    {targetUnitOptions}
                                  </Field>
                                  <ErrorMessage name="TargetUnit" component="div" className="error-message" />
                                </FormControl>
                              }
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel>Duration</InputLabel>
                                <Field
                                  as={Select}
                                  name="Duration"
                                  label="Duration"
                                  onChange={(event) => {
                                    formikProps.setFieldValue("Duration", event.target.value);
                                    handleDateChange(startDate, event.target.value);
                                  }}
                                >
                                  {goalOptionsReducer?.goalOptionsList?.duration &&
                                    goalOptionsReducer.goalOptionsList.duration.map((duration) => {
                                      return (<MenuItem key={duration.key} value={duration.key}>{duration.value}</MenuItem>);
                                    })}
                                </Field>
                                <ErrorMessage name="Duration" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                {/* <InputLabel>Start Date</InputLabel> */}
                                <Field
                                  as={DatePicker}
                                  type="date"
                                  min={startDate}
                                  disablePast
                                  value={startDate}
                                  name="startDate"
                                  defaultValue={startDate}
                                  label="Start Date"
                                  onChange={(event) => {
                                    formikProps.setFieldValue("startDate", event);
                                    setStartDate(event)
                                    handleDateChange(event);
                                  }}
                                />
                                <ErrorMessage name="startDate" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                {/* <InputLabel>End Date</InputLabel> */}
                                <Field
                                  as={DatePicker}
                                  type="date"
                                  value={endDate}
                                  minDate={endDate}
                                  disablePast
                                  disabled={formikProps?.values.Duration !== "Custom"}
                                  name="endDate"
                                  defaultValue={endDate}
                                  label="End Date"
                                  onChange={(event) => {
                                    setEndDate(event)
                                    formikProps.setFieldValue("endDate", event);
                                  }}
                                />
                                <ErrorMessage name="endDate" component="div" className="error-message" />
                              </FormControl>
                            </Grid>

                            {/* <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel>Recurring Goal</InputLabel>
                                <Field
                                  as={Select}
                                  label="Recurring Goal"
                                  defaultValue={"No"}
                                  onChange={(event) => {
                                    formikProps.setFieldValue("IsRecurringGoal", event.target.value);
                                  }}
                                  name="IsRecurringGoal"
                                >
                                  <MenuItem value={"Yes"}>Yes</MenuItem>
                                  <MenuItem value={"No"}>No</MenuItem>
                                </Field>
                              </FormControl>
                            </Grid> */}
                            {/* {formikProps.values.IsRecurringGoal === "Yes" &&
                              <>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>Repeat</InputLabel>
                                    <Field
                                      as={Select}
                                      name="eventRepeat"
                                      // value={}
                                      defaultValue={goalOptionsReducer?.goalOptionsList?.chooseFrequentRecurring[0].key}
                                      label="Repeat"
                                    >
                                      {goalOptionsReducer?.goalOptionsList?.chooseFrequentRecurring &&
                                        goalOptionsReducer.goalOptionsList.chooseFrequentRecurring.map((recurring) => {
                                          return (
                                            <MenuItem key={recurring.key} value={recurring.key}>
                                              {recurring.value}
                                            </MenuItem>
                                          );
                                        })}
                                    </Field>
                                    <ErrorMessage name="eventRepeat" component="div" className="error-message" />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>Stop repeating</InputLabel>
                                    <Field
                                      as={Select}
                                      name="ChooseStopRecurring"
                                      defaultValue={goalOptionsReducer?.goalOptionsList?.chooseStopRecurring[0].key}
                                      label="Stop repeating"
                                      onChange={(event) => {
                                        formikProps.setFieldValue("ChooseStopRecurring", event.target.value);

                                      }}
                                    >
                                      {goalOptionsReducer?.goalOptionsList?.chooseStopRecurring &&
                                        goalOptionsReducer.goalOptionsList.chooseStopRecurring.map((recurring) => {
                                          return (
                                            <MenuItem key={recurring.key} value={recurring.key}>
                                              {recurring.value}
                                            </MenuItem>
                                          );
                                        })}
                                    </Field>
                                    <ErrorMessage name="ChooseStopRecurring" component="div" className="error-message" />
                                  </FormControl>
                                </Grid>
                                {formikProps.values.ChooseStopRecurring === "SPECIFICDATE" &&
                                  <Grid item xs={6}>
                                    <FormControl fullWidth>
                                      <Field
                                        as={DatePicker}
                                        type="date"
                                        min={endDate}
                                        disablePast
                                        value={recurringEndDate}
                                        name="RecurringGoalEndDate"
                                        defaultValue={endDate}
                                        label="Repeating End Date"
                                        onChange={(event) => {
                                          formikProps.setFieldValue("RecurringGoalEndDate", event);
                                          setRecurringEndDate(event);
                                        }}
                                      />
                                      <ErrorMessage name="RecurringGoalEndDate" component="div" className="error-message" />
                                    </FormControl>
                                  </Grid>
                                }
                              </>
                            } */}

                          </Grid>
                        </Card>
                        <MDBox display="flex" justifyContent="end" alignItems="center" mt={3} >
                          <MDButton type="submit" size="medium" color="success">Save</MDButton>
                          &nbsp;
                          <Link to="/challenges">
                            <MDButton type="button" size="medium" color="error">Back</MDButton>
                          </Link>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                </LocalizationProvider>
              )}
        </MDBox>
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.logedinUserReducer.userDetails,
    goalOptionsReducer: state.goalOptionsReducer,
    activityTypeDetails: state.activityTypeReducer,
    activityOptionsDetails: state.activityOptionsReducer,
    ceateGoalReducer: state.ceateGoalReducer
  };
};

export default connect(mapStateToProps, {
  getActivityTypes, getApplicationOptions, getGoalsOptionsList, createGoal,
})(Event);