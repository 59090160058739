/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";


function Notification({ color, icon, title, content, dateTime }) {
  const [successSB, setSuccessSB] = useState(true);

  return (
    // <MDTypography variant="body2" color={color}>
    //   {title}
    //   {/* <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
    //     an example link
    //   </MDTypography>
    //   . Give it a click if you like. */}

    // </MDTypography>

    <MDSnackbar
      color={color}
      icon={icon}
      title={title}
      content={content}
      dateTime={dateTime}
      open={successSB}
      onClose={() => { setSuccessSB(false) }}
      close={() => { setSuccessSB(false) }}
      bgWhite
    />
  );
}

export default Notification;
