import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from '@mui/icons-material/Email';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/frami/FramiBg.jpg";
import { connect } from 'react-redux'

import { LOGIN_BASE_URL, b2cPolicies } from "../../../redux/contants";
import { setLoginAuthority, getUserDetails } from "../../../redux/actions/loginActions"
import CustomSpinner from "layouts/ProgressBar";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Dashboard from "layouts/dashboard";
import IsUserHasPermetionComponent from "layouts/commonComponents/IsUserHasPermetionComponent";


function Basic({ msalInstanceData, userDetails, setLoginAuthority, getUserDetails }) {

  const [policy, setPolicy] = useState(null);

  const handleLogin = (policy) => {
    setPolicy(LOGIN_BASE_URL + policy)
    setLoginAuthority(LOGIN_BASE_URL + policy)
  }
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (policy && msalInstanceData?.controller?.operatingContext?.config?.auth?.authority) {
      const timer = setTimeout(() => {
        msalInstanceData.loginPopup(policy).then(response => {
          const request = {
            scopes: ["https://framib2c.onmicrosoft.com/0153fc9c-a153-43ea-8fe1-d3c0c7c3ee03/framib2c.auth"],
            account: response.account
          };
          msalInstanceData.acquireTokenSilent(request).then(res => {
            setIsLoading(true)
            localStorage.setItem("token", res.accessToken);
            getUserDetails();
          }).catch((error) => console.log(error));
        }).catch((error) => console.log(error));
      }, 100);
    }
  }, [msalInstanceData])

  useEffect(() => {
    if (userDetails?.userDetails !== null) {
      setIsLoading(false)
    }
  }, [userDetails])

  return (
    <>
      <UnauthenticatedTemplate>
        <BasicLayout image={bgImage}>

          <Card>
            <MDBox
              variant="gradient"
              bgColor="success"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Sign in
              </MDTypography>
              <Grid container spacing={4} justifyContent="center" sx={{ mt: 0.5, mb: 2 }}>
                <Grid item xs={2.5}>
                  <MDTypography component={MuiLink} variant="body1" color="white">
                    <GoogleIcon color="inherit" onClick={() => handleLogin(b2cPolicies.signUpSignInGoogle)} fontSize="large" />
                  </MDTypography>
                </Grid>
                <Grid item xs={2.5}>
                  <MDTypography component={MuiLink} variant="body1" color="white">
                    <EmailIcon color="inherit" onClick={() => handleLogin(b2cPolicies.signUpSignInEmail)} fontSize="large" />
                  </MDTypography>
                </Grid>
                <Grid item xs={2.5}>
                  <MDTypography component={MuiLink} variant="body1" color="white">
                    <FacebookIcon color="inherit" onClick={() => handleLogin(b2cPolicies.signUpSignInFaceBook)} fontSize="large" />
                  </MDTypography>
                </Grid>
                <Grid item xs={2.5}>
                  <MDTypography component={MuiLink} variant="body1" color="white">
                    <AppleIcon color="inherit" onClick={() => handleLogin(b2cPolicies.signUpSignInApple)} fontSize="large" />
                  </MDTypography>
                </Grid>
              </Grid>

            </MDBox>

          </Card>
        </BasicLayout>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {userDetails?.userDetails === null ? <CustomSpinner /> :
          <Dashboard />

        }
      </AuthenticatedTemplate>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    msalInstanceData: state.msalInstanceReducer.msalInstance,
    userDetails: state.logedinUserReducer,
  };
};

export default connect(mapStateToProps, { setLoginAuthority, getUserDetails })(Basic);
