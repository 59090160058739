import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import MDInput from "components/MDInput";
import { Button, Icon } from "@mui/material";

import FormControl from "@mui/material/FormControl";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import * as Yup from "yup";

import {
  getEventsDatailsById,
  resetEventsDatailsById,
  updateEventeById,
  clearEventResponce
} from "../../redux/actions/eventActions";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

function EventUpdate({ eventDatailsByIdReducer, updateEventDatailsByIdReducer, getEventsDatailsById, updateEventeById, clearEventResponce, resetEventsDatailsById }) {

  let { eventId } = useParams();

  const navigate = useNavigate();

  const initialValues = {
    EventId: eventId,
    EventName: "",
    Objective: "",
    Description: "",
    EventImagesUrl: "",
    LinkToPurchaseTickets: "",
    EventImages: null,
  };

  const [formData, setFormData] = useState(initialValues);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [eventImages, setEventImages] = useState(null);

  useEffect(() => {
    if (eventId) {
      getEventsDatailsById(eventId)
      setIsLoading(true)
    }
    return () => {
      resetEventsDatailsById()
    }
  }, []);

  useEffect(() => {
    if (eventDatailsByIdReducer.sucess) {
      setFormData({
        EventId: eventId,
        EventName: eventDatailsByIdReducer.data.eventName,
        Objective: eventDatailsByIdReducer.data.objective,
        Description: eventDatailsByIdReducer.data.description,
        LinkToPurchaseTickets: eventDatailsByIdReducer.data.linkToPurchaseTickets,
        EventImages: eventDatailsByIdReducer.data.eventImagesUrl[eventDatailsByIdReducer.data.eventImagesUrl.length - 1],
        EventImagesUrl: eventDatailsByIdReducer.data.eventImagesUrl,
      })
    }
    else if (!eventDatailsByIdReducer.sucess && eventDatailsByIdReducer.data) {
      setIsError(eventDatailsByIdReducer.data.message)
      setTimeout(() => {
        navigate('/events')
      }, 2000);
    }
    setIsLoading(false);

  }, [eventDatailsByIdReducer])

  useEffect(() => {
    if (updateEventDatailsByIdReducer?.sucess === true) {
      setIsLoading(false);

      setTimeout(() => {
        clearEventResponce();
        navigate("/events")
      }, 2000);

    } else if (updateEventDatailsByIdReducer?.sucesss === false) {
      setIsError("Somthig went to wrong, try again!!");
      setIsLoading(false);
    }
  }, [updateEventDatailsByIdReducer])
  const validationSchema = {
    EventName: Yup.string().required("Event name is required"),
    Objective: Yup.string().required("Event objective is required"),
    Description: Yup.string().required("Event description is required"),
  };

  const onFormSubmit = (values, actions) => {
    setIsLoading(true);
    const formSubmitData = new FormData();
    formSubmitData.append("EventId", eventId);
    formSubmitData.append("EventName", values.EventName);
    formSubmitData.append("Objective", values.Objective);
    formSubmitData.append("Description", values.Description);
    formSubmitData.append("LinkToPurchaseTickets", values.LinkToPurchaseTickets);
    formSubmitData.append("EventImages", eventImages);
    if (!eventImages) {
      formSubmitData.append("EventImagesUrl", values.EventImagesUrl);
    }
    updateEventeById(formSubmitData);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox
          mx={0}
          mb={2}
          mt={3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            Event
          </MDTypography>
        </MDBox>
        {isError && (
          <MDSnackbar
            color="error"
            icon="warning"
            title={isError}
            content={isError}
            open={isError}
            onClose={() => { setIsError(false) }}
            close={() => { setIsError(false) }}
            bgWhite
          />
        )}
        <MDBox mb={3}>
          {(!eventDatailsByIdReducer.sucess || isLoading) && <CustomSpinner />}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Formik
              enableReinitialize={true}
              initialValues={formData}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={(values, actions) => {
                onFormSubmit(values, actions);
              }}
            >
              {(formikProps) => {
                return (<Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <Card>
                        <MDBox pt={3} px={2}>
                          <MDTypography variant="h6" fontWeight="medium">
                            Event Image
                          </MDTypography>
                        </MDBox>
                        {formikProps?.values?.EventImages && (
                          <img alt="preview image" src={formikProps.values.EventImages} />
                        )}
                        <ErrorMessage
                          name="EventImages"
                          component="div"
                          className="error-message"
                        />
                        <FormControl fullWidth>
                          <Button variant="contained" component="label" color="white">
                            Upload File
                            <input
                              type="file"
                              name="EventImages"
                              accept="image/*"
                              onChange={(event) => {
                                setEventImages(event.target.files[0]);
                                formikProps.setFieldValue("EventImages", URL.createObjectURL(event.target.files[0]));
                              }}
                              hidden
                              className="filetypeInput"
                            />
                          </Button>

                        </FormControl>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Card>
                        <MDBox pt={3} px={2}>
                          <MDTypography variant="h6" fontWeight="medium">
                            Details
                          </MDTypography>
                        </MDBox>
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          py={3}
                          px={2}
                        >
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <Field
                                as={MDInput}
                                type="text"
                                name="EventName"
                                label="Event Name"
                              />
                              <ErrorMessage
                                name="EventName"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <Field
                                as={MDInput}
                                type="text"
                                name="Objective"
                                label="Event Objective"
                              />
                              <ErrorMessage
                                name="Objective"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <Field
                                as={MDInput}
                                type="text"
                                multiline
                                name="Description"
                                label="Event Description"
                                rows={3}
                              />
                              <ErrorMessage
                                name="Description"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>

                  </Grid>
                  <MDBox display="flex" justifyContent="end" alignItems="center" mt={3} >
                    <MDButton type="submit" size="medium" color="success">Update</MDButton>
                    &nbsp;
                    <Link to="/events">
                      <MDButton type="button" size="medium" color="error">Back</MDButton>
                    </Link>
                  </MDBox>
                </Form>)
              }}
            </Formik>
          </LocalizationProvider>

        </MDBox>
      </MDBox >
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    eventDatailsByIdReducer: state.eventDatailsByIdReducer,
    updateEventDatailsByIdReducer: state.updateEventDatailsByIdReducer,
  };
};

export default connect(mapStateToProps, {
  getEventsDatailsById,
  updateEventeById,
  clearEventResponce,
  resetEventsDatailsById,
})(EventUpdate);