import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import CustomSpinner from "layouts/ProgressBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import MDInput from "components/MDInput";
import { Button, Icon } from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select } from "@mui/material";
import Notification from "../../layouts/Notification";

import { useTheme } from "@mui/material/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import * as Yup from "yup";

import {
  getChallengeOptions,
  getcompetitionCommunity,
  getParticipantsByOrganizer,
  getRewardOptions,
  createChalange,
  clearChallengeResponce,
} from "../../redux/actions/challengeActions";

import { getActivityTypes, getApplicationOptions } from "../../redux/actions/commonAction";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import dayjs from "dayjs";
import ActivityDropdown from "layouts/ActivityDropdown";
import ActivityDropdownComponent from "layouts/commonComponents/ActivityDropdownComponent";

function Challenges({
  userDetails,
  challengeOptionsDetails,
  activityTypeDetails,
  activityOptionsDetails,
  competitionCommunityDetails,
  participantsByOrganizerDetails,
  rewardOptionsDetails,
  createChalangeReducer,
  getChallengeOptions,
  getActivityTypes,
  getApplicationOptions,
  getcompetitionCommunity,
  getParticipantsByOrganizer,
  getRewardOptions,
  createChalange, clearChallengeResponce
}) {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  let { challengeId } = useParams();

  let isGlobalAdmin = userDetails.userRoles.filter(
    (item) => item.roleType == "GlobalCommunityAdmin"
  ).length
    ? true
    : false;

  const navigate = useNavigate();

  const initialValues = {
    all_activity: true,
    PrivacyType: "Private",
    ChallengeCommunity: "No",
    activities: null,
    Organizer: null,
    ChallengeImages: null,
    ChallengeName: null,
    Objective: null,
    ChallengeCompetitors: "subCommunity",
    Description: null,
    TargetValue: 0,
    TargetUnit: "Kilometre",
    Invite: "Network",
    Community: [],
    WinningCriteria: "",
    ChallangeType: "",
    Duration: "",
    startDate: "",
    endDate: "",
    AddReward: "No",
    Choose: "",
    Particiapnts: "",
    RewardImages: "",
    Title: "",
    rewardUnlockPoint: 0,
    LinkToWebsite: "",
    Unlimited: "",
    NumberOfAvailableRewards: 0,
    GenerateRewardCode: "",
    CouponCode: "",
    ExpiryDate: "",
  };

  const [formData, setFormData] = useState(initialValues);
  const [isDataOk, setIsDataOk] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [communitys, setCommunitys] = useState(null);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [targetUnitValue, setTargetUnitValue] = useState(null);
  const [activityOptions, setActivityOptions] = useState(null);
  const [expiryDate, setExpiryDate] = useState(dayjs());
  const [challengeImages, setChallengeImages] = useState(null);
  const [rewardImages, setRewardImages] = useState(null);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [targetUnit, setTargetUnit] = useState("Kilometre");
  const [privacyList, setPrivacyList] = useState(null);
  const [inviteList, setInviteList] = useState(null);
  const [selectedActivitys, setSelectedActivitys] = useState(null);

  const setDropdownSelectedActivitys = (data) => {
    setSelectedActivitys(data)
  }
  useEffect(() => {

    getChallengeOptions();
    getActivityTypes();
    getApplicationOptions();
  }, []);

  useEffect(() => {
    if (competitionCommunityDetails?.competitionCommunityList) {
      let temp = {};
      temp['subCommunity'] = competitionCommunityDetails.competitionCommunityList
        .filter((community) => community.organizerType === 'SubCommunity')
        .map((community) => ({
          label: community.name,
          value: community.id,
          imageUrl: community.imageUrl,
        }));
      temp['community'] = competitionCommunityDetails.competitionCommunityList
        .filter((community) => community.organizerType === 'Community')
        .map((community) => ({
          label: community.name,
          value: community.id,
          imageUrl: community.imageUrl,
        }));
      setCommunitys(temp);
      setIsLoading(false);
    }
  }, [competitionCommunityDetails]);

  useEffect(() => {
    if (createChalangeReducer?.sucess === true) {
      setIsLoading(false);

      // setTimeout(() => {
      //   clearChallengeResponce();
      //   navigate("/challenge")
      // }, 2000);

    } else if (createChalangeReducer?.sucess === false) {
      setIsError("Somthig went to wrong, try again!!");
      setIsLoading(false);
    }
  }, [createChalangeReducer]);

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    if (event.target.name == "Organizer") {
      getParticipantsByOrganizer(event.target.value);
      getcompetitionCommunity(event.target.value);
      setInviteList(
        event.target.value.organizerType === "User" ?
          challengeOptionsDetails.challengeOptionsList.inviteFrom
          : challengeOptionsDetails.challengeOptionsList.inviteFrom.filter(item => { return item.key !== "None"; })
      )
      setFormData({
        ...formData, "Organizer": event.target.value,

        "Community": []
      });
      setIsError(false);
    } else if (
      event.target.name == "ChallengeCommunity" ||
      event.target.name == "ChallengeCompetitors" ||
      event.target.name == "PrivacyType" ||
      event.target.name == "Invite"
    ) {
      if (!formData.Organizer) {
        setFormData({ ...formData, "Invite": "None", PrivacyType: "Private" });
        setIsError("Select organizer first!!");
        return false;
      }
    } else if (event.target.name == "ChallengeCompetitors") {
      setFormData({ ...formData, "Community": [] });
    } else if (event.target.name == "WinningCriteria") {
      getTargetUnit(formData.ChallangeType);
    } else if (event.target.name == "ChallangeType") {
      getTargetUnit(event.target.value);
    } else if (event.target.name == "Duration") {
      setFormData({ ...formData, [event.target.name]: event.target.value });
      handleDateChange(startDate, event.target.value)
    } else if (event.target.name == "AddReward" && event.target.value == "Yes") {
      getRewardOptions();
      setFormData({ ...formData, "ExpiryDate": expiryDate, [event.target.name]: event.target.value });
    } else if (event?.target?.files && event?.target?.files[0]) {
      setFormData({ ...formData, [event.target.name]: URL.createObjectURL(event.target.files[0]) });
    }
  };



  const getTargetUnit = (
    unit = challengeOptionsDetails?.challengeOptionsList?.challengeType[0]?.key
  ) => {
    let temp = challengeOptionsDetails?.challengeOptionsList?.challengeTypeTargetUnits
      .find((item) => item.CategoryType == unit)
      .TargetUnits.map((item) => {
        return (
          <MenuItem key={item.key} value={item.key}>
            {item.value}
          </MenuItem>
        );
      });
    setTargetUnitValue(temp);
    setTargetUnit(temp[0].props.value);
  };

  const handleDateChange = (date, duration = formData.Duration) => {

    let end = null;
    switch (duration) {
      case "Day":
        end = dayjs(date);
        break;
      case "Week":
        end = dayjs(date).add(1, 'week');
        break;
      case "Month":
        end = dayjs(date).add(1, 'month');
        break;
      case "Custom":
        end = date
        break;
      default:
        break;

    }
    setEndDate(end)
    setExpiryDate(end)
  }

  useEffect(() => {
    if (activityTypeDetails?.activityTypeList) {
      let temp = [];
      activityTypeDetails?.activityTypeList.forEach((item) => {
        item.value.forEach((subItem) => {
          temp.push({ ...subItem, category: item.title });
        });
      });
      setActivityOptions(temp);
    }
  }, [activityTypeDetails]);

  const theme = useTheme();

  function getStyles(name, personName, theme) {
    if (formData?.activities) {
      return {
        fontWeight:
          formData?.activities?.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    } else {
      return {
        fontWeight:
          [].indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    }
  }

  useEffect(() => {
    if (challengeOptionsDetails?.challengeOptionsList) {
      setFormData({
        ...formData,
        all_activity: true,
        challengeAnotherCommunity: false,
        startDate: startDate,
        endDate: endDate,
        Organizer: challengeOptionsDetails.challengeOptionsList?.organizers && challengeOptionsDetails.challengeOptionsList?.organizers[0].key,
        AddReward: challengeOptionsDetails.challengeOptionsList?.addReward && challengeOptionsDetails.challengeOptionsList?.addReward[1].key,
        Choose: challengeOptionsDetails.challengeOptionsList?.chooseFrom && challengeOptionsDetails.challengeOptionsList?.chooseFrom[0].key,
        // "PrivacyType": challengeOptionsDetails.challengeOptionsList?.privacy[0].key,
        Duration: challengeOptionsDetails.challengeOptionsList?.duration && challengeOptionsDetails.challengeOptionsList?.duration[0].key,
        // "Invite": challengeOptionsDetails.challengeOptionsList?.inviteFrom[0].key,
        WinningCriteria: challengeOptionsDetails.challengeOptionsList?.challengeTypeCategory && challengeOptionsDetails.challengeOptionsList?.challengeTypeCategory[0].key,
        ChallangeType: challengeOptionsDetails.challengeOptionsList?.challengeType && challengeOptionsDetails.challengeOptionsList?.challengeType[0].key,
      });
      setInviteList(challengeOptionsDetails.challengeOptionsList?.inviteFrom)
      if (isGlobalAdmin) {
        setPrivacyList(challengeOptionsDetails.challengeOptionsList?.privacy);
      } else {
        setPrivacyList(
          challengeOptionsDetails.challengeOptionsList?.privacy.filter(function (item) {
            return item.key !== "Global";
          })
        );
      }
    }
  }, [challengeOptionsDetails]);

  const validationSchema = {
    // all_activity: Yup.string().required('All Activity  Unit is required'),
    Organizer: Yup.object().required("Organizer is required"),
    ChallengeImages: Yup.string().required("Challenge Images is required"),
    ChallengeName: Yup.string().required("Challenge Name is required"),
    Objective: Yup.string().required("Challenge Objective is required"),
    Description: Yup.string().required("Challenge Description is required"),
    // PrivacyType: Yup.string().required('Privacy Type is required'),
    // ChallengeCommunity: Yup.string().required('Challenge Community  Unit is required'),
    // WinningCriteria: Yup.string().required('Winning Criteria is required'),
    // ChallangeType: Yup.string().required('Challange Type  Unit is required'),
    // Duration: Yup.string().required('Duration  Unit is required'),
    startDate: Yup.string().required('Start Date  Unit is required'),
    endDate: Yup.string().required('End Date  Unit is required'),
    // AddReward: Yup.string().required('Add Reward  Unit is required'),
    // Invite: Yup.string().required('Invite  Unit is required'),
    // Choose: Yup.string().required('Choose  Unit is required'),
  };


  const [dinamicValidationSchema, setDinamicValidationSchema] = useState(validationSchema);

  const onFormSubmit = (values, actions) => {
    setIsLoading(true);
    const formSubmitData = new FormData();
    formSubmitData.append("UserId", userDetails.userId);
    formSubmitData.append("UserName", userDetails.userName);
    formSubmitData.append("ProfilePhotoUrl", userDetails.profilePhotoUrl);
    formSubmitData.append("ChallengeImages", challengeImages);
    formSubmitData.append("ChallengeName", values.ChallengeName);
    formSubmitData.append("Objective", values.Objective);
    formSubmitData.append("Description", values.Description);
    formSubmitData.append("Organizer.Id", values.Organizer.id);
    formSubmitData.append("Organizer.Name", values.Organizer.name);
    formSubmitData.append("Organizer.ImageUrl", values.Organizer.imageUrl);
    formSubmitData.append("Organizer.OrganizerPrivacy", values.Organizer.organizerPrivacy);
    formSubmitData.append("Organizer.OrganizerType", values.Organizer.organizerType);

    if (values.all_activity) {
      formSubmitData.append(
        "ActivityTypes[0].key",
        activityOptionsDetails.activityOptionList.allActivityType.key
      );
      formSubmitData.append(
        "ActivityTypes[0].name",
        activityOptionsDetails.activityOptionList.allActivityType.name
      );
      formSubmitData.append(
        "ActivityTypes[0].icon",
        activityOptionsDetails.activityOptionList.allActivityType.icon
      );
      formSubmitData.append(
        "ActivityTypes[0].combinationNo",
        activityOptionsDetails.activityOptionList.allActivityType.combinationNo
      );
    } else {
      if (values.activities.length) {
        let count = 0
        values.activities.forEach((item, index) => {
          item.zones.forEach((activity) => {
            formSubmitData.append("ActivityTypes[" + count + "].key", activity.code)
            formSubmitData.append("ActivityTypes[" + count + "].name", activity.name)
            formSubmitData.append("ActivityTypes[" + count + "].icon", activity.icon)
            formSubmitData.append("ActivityTypes[" + count + "].combinationNo", activity.combinationNo)
            count++
          })
        })
      }
      else {
        setIsError("Select activitys first!!");
        setIsLoading(false)
        return true;
      }
    }

    formSubmitData.append("ChallangeType", values.ChallangeType);
    formSubmitData.append("WinningCriteria", values.WinningCriteria);

    if (values.WinningCriteria == "CHALLENGETARGETCOMPLETED") {
      formSubmitData.append("Target.Unit", targetUnit);
      formSubmitData.append("Target.Value", values.TargetValue);
    }

    formSubmitData.append("PrivacyType", values.PrivacyType);

    if (values.PrivacyType !== "Private" && values.ChallengeCommunity === "Yes") {
      if (values.ChallengeCompetitors == "subCommunity") {
        values.Community.forEach((value, index) => {
          let community = competitionCommunityDetails.competitionCommunityList.find(
            (item) => item.id == value
          );
          formSubmitData.append("ChallengeCompetitors[" + index + "].communityId", community.id);
          formSubmitData.append(
            "ChallengeCompetitors[" + index + "].communityName",
            community.name
          );
          formSubmitData.append(
            "ChallengeCompetitors[" + index + "].communityImage",
            community.imageUrl
          );
          formSubmitData.append(
            "ChallengeCompetitors[" + index + "].communityType",
            community.organizerType
          );
          formSubmitData.append(
            "ChallengeCompetitors[" + index + "].communityStatus",
            "NoResponse"
          );
        });
      } else {
        values.Community.forEach((value, index) => {
          let community = competitionCommunityDetails.competitionCommunityList.find(
            (item) => item.id == value
          );
          formSubmitData.append("ChallengeCompetitors[" + index + "].communityId", community.id);
          formSubmitData.append(
            "ChallengeCompetitors[" + index + "].communityName",
            community.name
          );
          formSubmitData.append(
            "ChallengeCompetitors[" + index + "].communityImage",
            community.imageUrl
          );
          formSubmitData.append(
            "ChallengeCompetitors[" + index + "].communityType",
            community.organizerType
          );
          formSubmitData.append(
            "ChallengeCompetitors[" + index + "].communityStatus",
            "NoResponse"
          );
        });
      }
    }

    formSubmitData.append("ChallengeCommunity", values.ChallengeCommunity);

    formSubmitData.append("Duration", values.Duration);
    formSubmitData.append("StartDate", new Date(startDate).toLocaleDateString("en-CA"));
    formSubmitData.append("EndDate", new Date(endDate).toLocaleDateString("en-CA"));

    formSubmitData.append("Invite", values.Invite);
    if (values.Invite == "Network" && values.Choose == "Specific") {
      values.Particiapnts.forEach((particiapnt, index) => {
        formSubmitData.append("Participants[" + index + "].userId", particiapnt.userId);
        formSubmitData.append("Participants[" + index + "].userName", particiapnt.userName);
        formSubmitData.append(
          "Participants[" + index + "].profilePhotoUrl",
          particiapnt.profilePhotoUrl
        );
        formSubmitData.append("Participants[" + index + "].participantStatus", "NoResponse");
      });

      formSubmitData.append("Select", values.Choose);
    } else if (values.PrivacyType !== "Global" && values.Choose === "All") {
      participantsByOrganizerDetails.participantsList.forEach((particiapnt, index) => {
        formSubmitData.append("Participants[" + index + "].userId", particiapnt.userId);
        formSubmitData.append("Participants[" + index + "].userName", particiapnt.userName);
        formSubmitData.append(
          "Participants[" + index + "].profilePhotoUrl",
          particiapnt.profilePhotoUrl
        );
        formSubmitData.append("Participants[" + index + "].participantStatus", "NoResponse");
      });

      formSubmitData.append("Select", values.Choose);
    }

    if (isGlobalAdmin) {
      formSubmitData.append("AddReward", values.AddReward);
    } else {
      formSubmitData.append("AddReward", "No");
    }

    if (values.AddReward == "Yes") {
      formSubmitData.append("ChallangeReward.UserId", userDetails.userId);
      formSubmitData.append("ChallangeReward.RewardImages", rewardImages);
      formSubmitData.append("ChallangeReward.Title", values.RewardTitle);
      formSubmitData.append("ChallangeReward.Description", values.RewardDescription);
      formSubmitData.append("ChallangeReward.Organizer.Id", values.Organizer.id);
      formSubmitData.append("ChallangeReward.Organizer.Name", values.Organizer.name);
      formSubmitData.append("ChallangeReward.Organizer.ImageUrl", values.Organizer.imageUrl);
      formSubmitData.append(
        "ChallangeReward.Organizer.OrganizerPrivacy",
        values.Organizer.organizerPrivacy
      );
      formSubmitData.append(
        "ChallangeReward.Organizer.OrganizerType",
        values.Organizer.organizerType
      );
      formSubmitData.append("ChallangeReward.ChallengeCompleted", true);
      formSubmitData.append("ChallangeReward.LotteryReward", true);
      formSubmitData.append("ChallangeReward.Points", values.rewardUnlockPoint);
      formSubmitData.append(
        "ChallangeReward.NumberOfAvailableRewards",
        values.NumberOfAvailableRewards
      );

      formSubmitData.append("ChallangeReward.GenerateRewardCode", values.GenerateRewardCode);

      if (values.Unlimited == "NO") {
        formSubmitData.append("ChallangeReward.Unlimited", false);
      } else {
        formSubmitData.append("ChallangeReward.Unlimited", true);
      }

      formSubmitData.append("ChallangeReward.OtherInfo", values.Other);
      formSubmitData.append("ChallangeReward.LinkToWebsite", values.LinkToWebsite);
      formSubmitData.append("ChallangeReward.ExpiryDate", new Date(expiryDate).toLocaleDateString("en-CA"));

      if (values.GenerateRewardCode === "GENERICCODE") {
        formSubmitData.append("ChallangeReward.CouponCode[0][CouponCode]", values.CouponCode);
        formSubmitData.append("ChallangeReward.CouponCode[0][isActivated]", false);
      }
    }

    createChalange(formSubmitData);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      {(challengeOptionsDetails.loading ||
        activityTypeDetails?.loading ||
        activityOptionsDetails?.loading ||
        isLoading) &&
        <CustomSpinner />}
      <MDBox mt={8}>
        <MDBox
          mx={0}
          mb={2}
          mt={3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            Challenge
          </MDTypography>
        </MDBox>
        {isError && (
          <MDSnackbar
            color="error"
            icon="warning"
            title={isError}
            content={isError}
            open={isError}
            onClose={() => { setIsError(false) }}
            close={() => { setIsError(false) }}
            bgWhite
          />
        )}
        <MDBox mb={3}>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Formik
              enableReinitialize={true}
              initialValues={formData}
              validationSchema={Yup.object().shape(dinamicValidationSchema)}
              onSubmit={(values, actions) => {
                onFormSubmit(values, actions);
              }}
            >
              {(formikProps) => (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <Card>
                        <MDBox pt={3} px={2}>
                          <MDTypography variant="h6" fontWeight="medium">
                            Challenge Image
                          </MDTypography>
                        </MDBox>
                        {formikProps?.values?.ChallengeImages && (
                          <img alt="preview image" src={formikProps.values.ChallengeImages} />
                        )}
                        <ErrorMessage
                          name="ChallengeImages"
                          component="div"
                          className="error-message"
                        />
                        <FormControl fullWidth>
                          <Button variant="contained" component="label" color="white">
                            Upload File
                            <input
                              type="file"
                              name="ChallengeImages"
                              accept="image/*"
                              onChange={(event) => {
                                setChallengeImages(event.target.files[0]);
                                formikProps.setFieldValue("ChallengeImages", event.target.files[0]);
                                handleInputChange(event);
                              }}
                              hidden
                              className="filetypeInput"
                            />
                          </Button>

                        </FormControl>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Card>
                        <MDBox pt={3} px={2}>
                          <MDTypography variant="h6" fontWeight="medium">
                            Details
                          </MDTypography>
                        </MDBox>
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          py={3}
                          px={2}
                        >
                          {activityTypeDetails?.activityTypeList &&
                            <Grid item xs={12}>
                              <ActivityDropdownComponent data={activityTypeDetails.activityTypeList} onActivitySelct={(selectedData) => {
                                if (selectedData) {
                                  formikProps.setFieldValue("all_activity", false);
                                  formikProps.setFieldValue("activities", selectedData)
                                } else {
                                  formikProps.setFieldValue("all_activity", true);
                                  formikProps.setFieldValue("activities", [])
                                }
                              }} />
                            </Grid>
                          }
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel>Select Organizer</InputLabel>
                              <Field
                                as={Select}
                                // onBlur={formikProps.handleBlur}
                                name="Organizer"
                                // defaultValue={challengeOptionsDetails?.challengeOptionsList?.organizers[0].key}
                                onChange={(event) => {
                                  formikProps.setFieldValue("Organizer", event.target.value);
                                  formikProps.setFieldValue("Community", []);
                                  setFormData({ ...formData, "Community": [] });
                                  handleInputChange(event);
                                }}
                                label="Select Organizer"
                              >
                                {challengeOptionsDetails?.challengeOptionsList?.organizers &&
                                  challengeOptionsDetails.challengeOptionsList.organizers.map(
                                    (organizer) => {
                                      return (
                                        <MenuItem className="menuItemOption" key={organizer.id} value={organizer}>

                                          {organizer?.imageUrl && organizer?.imageUrl != ""
                                            ? <img
                                              src={organizer.imageUrl}
                                              alt={organizer.name}
                                              className="communityImg"
                                            />
                                            : <Icon fontSize={"large"} className="communityImg" sx={{ fontWeight: "bold" }}>account_circle</Icon>
                                          }
                                          &nbsp;&nbsp;
                                          <span>{organizer.name}</span>
                                        </MenuItem>
                                      );
                                    }
                                  )}
                              </Field>
                              <ErrorMessage
                                name="Organizer"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              {/* <InputLabel>Challenge Name</InputLabel> */}
                              <Field
                                as={MDInput}
                                type="text"
                                onChange={(event) => {
                                  formikProps.setFieldValue("ChallengeName", event.target.value);
                                  handleInputChange(event);
                                }}
                                name="ChallengeName"
                                value={formData?.ChallengeName}
                                label="Challenge Name"
                              />
                              <ErrorMessage
                                name="ChallengeName"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              {/* <InputLabel>Challenge Objective</InputLabel> */}
                              <Field
                                as={MDInput}
                                type="text"
                                onChange={(event) => {
                                  formikProps.setFieldValue("Objective", event.target.value);
                                  handleInputChange(event);
                                }}
                                name="Objective"
                                label="Challenge Objective"
                              />
                              <ErrorMessage
                                name="Objective"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              {/* <InputLabel>Challenge Description</InputLabel> */}
                              <Field
                                as={MDInput}
                                type="text"
                                onChange={(event) => {
                                  formikProps.setFieldValue("Description", event.target.value);
                                  handleInputChange(event);
                                }}
                                name="Description"
                                label="Challenge Description"
                                multiline
                                rows={3}
                              />
                              <ErrorMessage
                                name="Description"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          {/* Challenge Privacy */}
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>Select Challenge Privacy</InputLabel>
                              <Field
                                as={Select}
                                onChange={(event) => {
                                  formikProps.setFieldValue("PrivacyType", event.target.value);
                                  handleInputChange(event);

                                  if (event.target.value != "Private") {
                                    setDinamicValidationSchema({
                                      ...dinamicValidationSchema,
                                      ChallengeCommunity: Yup.string().required(
                                        "Challenge to another is required"
                                      ),
                                    });
                                  } else {
                                    const { ChallengeCommunity, ...rest } = dinamicValidationSchema;
                                    setDinamicValidationSchema(rest);
                                  }
                                }}
                                name="PrivacyType"
                                value={formData.PrivacyType}
                                // defaultValue={formData.PrivacyType}
                                label="Select Challenge Privacy"
                              >
                                {privacyList &&
                                  privacyList.map((privacy) => {
                                    return (
                                      <MenuItem key={privacy.key} value={privacy.key}>
                                        {privacy.value}
                                      </MenuItem>
                                    );
                                  })}
                              </Field>
                              <ErrorMessage
                                name="PrivacyType"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          {formData.PrivacyType != "Private" &&
                            formData?.Organizer?.organizerType !== "User" && (
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <InputLabel>Challenge to another community? </InputLabel>
                                  <Field
                                    as={Select}
                                    onChange={(event) => {
                                      formikProps.setFieldValue(
                                        "ChallengeCommunity",
                                        event.target.value
                                      );
                                      handleInputChange(event);
                                      if (!event.target.value) {
                                        setDinamicValidationSchema({
                                          ...dinamicValidationSchema,
                                          Invite: Yup.string().required("Invite  is required"),
                                        });
                                      } else {
                                        const { Invite, ...rest } = dinamicValidationSchema;
                                        setDinamicValidationSchema(rest);
                                      }
                                    }}
                                    name="ChallengeCommunity"
                                    defaultValue={"No"}
                                    label="Challenge to another community"
                                  >
                                    {challengeOptionsDetails?.challengeOptionsList
                                      ?.challengeCompetitor &&
                                      challengeOptionsDetails.challengeOptionsList.challengeCompetitor.map(
                                        (challengeCompetitor) => {
                                          return (
                                            <MenuItem
                                              key={challengeCompetitor.key}
                                              value={challengeCompetitor.key}
                                            >
                                              {challengeCompetitor.value}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                  </Field>
                                  <ErrorMessage
                                    name="ChallengeCommunity"
                                    component="div"
                                    className="error-message"
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {formikProps.values?.PrivacyType !== "Private" &&
                            formikProps.values.ChallengeCommunity !== "No" && (
                              <>
                                <Grid item xs={12}>
                                  <FormControl fullWidth>
                                    <InputLabel>Select Challenge Competitors</InputLabel>
                                    <Field
                                      as={Select}
                                      name="ChallengeCompetitors"
                                      defaultValue={"subCommunity"}
                                      onChange={(event) => {
                                        formikProps.setFieldValue("ChallengeCompetitors", event.target.value);
                                        formikProps.setFieldValue("Community", []);
                                        handleInputChange(event);
                                      }}
                                      label="Select Challenge Competitors"
                                    >
                                      <MenuItem value="subCommunity">SubCommunity</MenuItem>
                                      <MenuItem value="community">Community</MenuItem>
                                    </Field>
                                    <ErrorMessage
                                      name="ChallengeCompetitors"
                                      component="div"
                                      className="error-message"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl fullWidth>
                                    <InputLabel>Select Community</InputLabel>
                                    <Field
                                      as={Select}
                                      name="Community"
                                      multiple
                                      label="Select Community"
                                      value={formData.Community ? formData.Community : []}
                                      onChange={(event) => {
                                        formikProps.setFieldValue("Community", event.target.value);
                                        handleInputChange(event);
                                      }}
                                    >
                                      {formikProps.values?.ChallengeCompetitors ==
                                        "subCommunity" ? (
                                        communitys.subCommunity.length ? (
                                          communitys.subCommunity.map((community) => {
                                            return (
                                              <MenuItem className="menuItemOption"
                                                key={community.value}
                                                value={community.value}
                                              >
                                                {community?.imageUrl && community?.imageUrl != ""
                                                  ? <img
                                                    src={community.imageUrl}
                                                    alt={community.name}
                                                    className="communityImg"
                                                  />
                                                  : <Icon fontSize={"large"} className="communityImg" sx={{ fontWeight: "bold" }}>account_circle</Icon>
                                                }
                                                &nbsp;&nbsp;
                                                {community.label.length > 20
                                                  ? community.label.substring(0, 20) + "..."
                                                  : community.label}
                                              </MenuItem>
                                            );
                                          })
                                        ) : (
                                          <MenuItem key={"NODATA"} value={"NODATA"}>
                                            No Sub-community Found
                                          </MenuItem>
                                        )
                                      ) : communitys.community.length ? (
                                        communitys.community.map((community) => {
                                          return (
                                            <MenuItem key={community.value} value={community.value}>
                                              {community?.imageUrl && community?.imageUrl != ""
                                                ? <img
                                                  src={community.imageUrl}
                                                  alt={community.name}
                                                  className="communityImg"
                                                />
                                                : <Icon fontSize={"large"} className="communityImg" sx={{ fontWeight: "bold" }}>account_circle</Icon>
                                              }
                                              &nbsp;&nbsp;
                                              {community.label.length > 20
                                                ? community.label.substring(0, 20) + "..."
                                                : community.label}
                                            </MenuItem>
                                          );
                                        })
                                      ) : (
                                        <MenuItem key={"NODATA"} value={"NODATA"}>
                                          No community Found
                                        </MenuItem>
                                      )}
                                    </Field>
                                    <ErrorMessage
                                      name="Community"
                                      component="div"
                                      className="error-message"
                                    />
                                  </FormControl>
                                </Grid>
                              </>
                            )}
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>Select Winning Criteria</InputLabel>
                              <Field
                                as={Select}
                                name="WinningCriteria"
                                onChange={(event) => {
                                  if (event.target.value == "CHALLENGETARGETCOMPLETED") {
                                    setDinamicValidationSchema({
                                      ...dinamicValidationSchema,
                                      TargetValue: Yup.number()
                                        .min(0)
                                        .required("Target Value  Unit is required"),
                                      TargetUnit: Yup.string().required("Target  Unit is required"),
                                    });
                                  } else {
                                    const { TargetValue, TargetUnit, ...rest } =
                                      dinamicValidationSchema;
                                    setDinamicValidationSchema(rest);
                                  }

                                  formikProps.setFieldValue("WinningCriteria", event.target.value);
                                  handleInputChange(event);
                                }}
                                // defaultValue={challengeOptionsDetails?.challengeOptionsList?.challengeTypeCategory[0]?.key}
                                label="Select Winning Criteria"
                              >
                                {challengeOptionsDetails?.challengeOptionsList
                                  ?.challengeTypeCategory &&
                                  challengeOptionsDetails.challengeOptionsList.challengeTypeCategory.map(
                                    (category) => {
                                      return (
                                        <MenuItem key={category.key} value={category.key}>
                                          {category.value}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                              </Field>
                              <ErrorMessage
                                name="WinningCriteria"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>Challenge Type </InputLabel>
                              <Field
                                as={Select}
                                name="ChallangeType"
                                label="Challenge Type"
                                onChange={(event) => {
                                  formikProps.setFieldValue("ChallangeType", event.target.value);
                                  handleInputChange(event);
                                }}
                              >
                                {challengeOptionsDetails?.challengeOptionsList?.challengeType &&
                                  challengeOptionsDetails.challengeOptionsList.challengeType.map(
                                    (type) => {
                                      return (
                                        <MenuItem key={type.key} value={type.key}>
                                          {type.value}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                              </Field>
                              <ErrorMessage
                                name="ChallangeType"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          {formikProps.values?.WinningCriteria == "CHALLENGETARGETCOMPLETED" && (
                            <>
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  {/* <InputLabel>Target Value</InputLabel> */}
                                  <Field
                                    as={MDInput}
                                    min="0"
                                    type="number"
                                    name="TargetValue"
                                    // defaultValue={}
                                    label="Target Value"
                                    onChange={(event) => {
                                      formikProps.setFieldValue("TargetValue", event.target.value);
                                      handleInputChange(event);
                                    }}
                                  />
                                  <ErrorMessage
                                    name="TargetValue"
                                    component="div"
                                    className="error-message"
                                  />
                                </FormControl>
                              </Grid>
                              {targetUnitValue && (
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>Target Unit</InputLabel>
                                    <Field
                                      as={Select}
                                      name="TargetUnit"
                                      label="Target Unit"
                                      defaultValue={targetUnit}
                                      value={targetUnit}
                                      onChange={(event) => {
                                        setTargetUnit(event.target.value);
                                        formikProps.setFieldValue("TargetUnit", event.target.value);
                                        handleInputChange(event);
                                      }}
                                    >
                                      {targetUnitValue}
                                    </Field>
                                    <ErrorMessage
                                      name="TargetUnit"
                                      component="div"
                                      className="error-message"
                                    />
                                  </FormControl>
                                </Grid>
                              )}
                            </>
                          )}
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>Duration </InputLabel>
                              <Field
                                as={Select}
                                name="Duration"
                                label="Duration"
                                onChange={(event) => {
                                  formikProps.setFieldValue("Duration", event.target.value);
                                  handleInputChange(event);
                                }}
                              >
                                {challengeOptionsDetails?.challengeOptionsList?.duration?.map(
                                  (duration) => {
                                    return (
                                      <MenuItem key={duration.key} value={duration.key}>
                                        {duration.value}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Field>
                              <ErrorMessage
                                name="Duration"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              {/* <InputLabel>Start Date</InputLabel> */}
                              <Field
                                as={DatePicker}
                                type="date"
                                min={startDate}
                                disablePast
                                value={startDate}
                                name="startDate"
                                defaultValue={startDate}
                                label="Start Date"
                                onChange={(event) => {
                                  formikProps.setFieldValue("startDate", event);
                                  setStartDate(event)
                                  handleDateChange(event);
                                }}
                              />
                              <ErrorMessage
                                name="startDate"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              {/* <InputLabel>End Date</InputLabel> */}
                              <Field
                                as={DatePicker}
                                type="date"
                                value={endDate}
                                minDate={endDate}
                                disablePast
                                disabled={formikProps?.values.Duration !== "Custom"}
                                name="endDate"
                                defaultValue={endDate}
                                label="End Date"
                                onChange={(event) => {
                                  setEndDate(event)
                                  setExpiryDate(event)
                                  formikProps.setFieldValue("endDate", event);
                                }}
                              />
                              <ErrorMessage
                                name="endDate"
                                component="div"
                                className="error-message"
                              />
                            </FormControl>
                          </Grid>

                          {formikProps.values?.Organizer &&
                            formikProps.values?.PrivacyType != "Global" &&
                            formikProps.values?.ChallengeCommunity == "No" && (
                              <>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>Who to Invite </InputLabel>
                                    <Field
                                      as={Select}
                                      name="Invite"
                                      label="Who to Invite"
                                      defaultValue="None"
                                      onChange={(event) => {
                                        handleInputChange(event)
                                        formikProps.values?.Organizer ?
                                          formikProps.setFieldValue("Invite", event.target.value) :
                                          formikProps.setFieldValue("Invite", "None");
                                        if (event.target.value == "Network") {
                                          setDinamicValidationSchema({
                                            ...dinamicValidationSchema,
                                            Choose: Yup.string().required("Choose is required"),
                                          });
                                        } else {
                                          const { Choose, ...rest } = dinamicValidationSchema;
                                          setDinamicValidationSchema(rest);
                                        }
                                      }}
                                    >
                                      {inviteList?.map((invite) => {
                                        return (
                                          <MenuItem key={invite.key} value={invite.key}>
                                            {invite.value}
                                          </MenuItem>
                                        );
                                      }
                                      )}
                                    </Field>
                                    <ErrorMessage
                                      name="Invite"
                                      component="div"
                                      className="error-message"
                                    />
                                  </FormControl>
                                </Grid>
                                {formData?.Invite == "Network" && (
                                  <>
                                    <Grid item xs={6}>
                                      <FormControl fullWidth>
                                        <InputLabel>Choose </InputLabel>
                                        <Field
                                          as={Select}
                                          name="Choose"
                                          label="Choose"
                                          defaultValue={
                                            challengeOptionsDetails?.challengeOptionsList
                                              ?.chooseFrom[0].key
                                          }
                                          onChange={(event) => {
                                            formikProps.setFieldValue("Choose", event.target.value);
                                            handleInputChange(event);
                                            if (event.target.value == "Specific") {
                                              setDinamicValidationSchema({
                                                ...dinamicValidationSchema,
                                                Particiapnts: Yup.array().required(
                                                  "Particiapnts is required"
                                                ),
                                              });
                                            } else {
                                              const { Particiapnts, ...rest } =
                                                dinamicValidationSchema;
                                              setDinamicValidationSchema(rest);
                                            }
                                          }}
                                        >
                                          {challengeOptionsDetails?.challengeOptionsList
                                            ?.chooseFrom &&
                                            challengeOptionsDetails.challengeOptionsList.chooseFrom.map(
                                              (choose) => {
                                                return (
                                                  <MenuItem key={choose.key} value={choose.key}>
                                                    {choose.value}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                        </Field>
                                        <ErrorMessage
                                          name="Choose"
                                          component="div"
                                          className="error-message"
                                        />
                                      </FormControl>
                                    </Grid>
                                    {formikProps.values?.Choose == "Specific" && (
                                      <Grid item xs={12}>
                                        <FormControl fullWidth>
                                          <InputLabel>Particiapnts </InputLabel>
                                          <Field
                                            as={Select}
                                            name="Particiapnts"
                                            multiple
                                            label="Particiapnts"
                                            value={
                                              formData.Particiapnts ? formData?.Particiapnts : []
                                            }
                                            defaultValue={
                                              challengeOptionsDetails?.challengeOptionsList
                                                ?.chooseFrom[0].key
                                            }
                                            onChange={(event) => {
                                              formikProps.setFieldValue(
                                                "Particiapnts",
                                                event.target.value
                                              );
                                              handleInputChange(event);
                                            }}
                                          >
                                            {participantsByOrganizerDetails?.participantsList &&
                                              participantsByOrganizerDetails.participantsList.map(
                                                (participant) => {
                                                  return (
                                                    <MenuItem className="menuItemOption"
                                                      key={participant.userId}
                                                      value={participant}
                                                    >
                                                      {participant?.profilePhotoUrl && participant?.profilePhotoUrl != ""
                                                        ? <img
                                                          src={participant.profilePhotoUrl}
                                                          alt={participant.name}
                                                          className="communityImg"
                                                        />
                                                        : <Icon fontSize={"large"} className="communityImg" sx={{ fontWeight: "bold" }}>account_circle</Icon>
                                                      }
                                                      &nbsp;&nbsp;
                                                      <span>{participant.userName}</span>
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                          </Field>
                                          <ErrorMessage
                                            name="Particiapnts"
                                            component="div"
                                            className="error-message"
                                          />
                                        </FormControl>
                                      </Grid>
                                    )}
                                  </>
                                )}
                              </>
                            )}

                          {isGlobalAdmin && (
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel>Add Reward </InputLabel>
                                <Field
                                  as={Select}
                                  name="AddReward"
                                  label="Add Reward"
                                  defaultValue={
                                    challengeOptionsDetails?.challengeOptionsList?.addReward &&
                                    challengeOptionsDetails?.challengeOptionsList?.addReward.length && challengeOptionsDetails?.challengeOptionsList?.addReward[1].key
                                  }
                                  onChange={(event) => {
                                    formikProps.setFieldValue("AddReward", event.target.value);
                                    handleInputChange(event);
                                    if (event.target.value == "Yes") {
                                      setDinamicValidationSchema({
                                        ...dinamicValidationSchema,
                                        RewardTitle: Yup.string().required(
                                          "Reward Title is required"
                                        ),
                                        RewardImages: Yup.string().required(
                                          "Reward Images is required"
                                        ),
                                        RewardDescription: Yup.string().required(
                                          "Reward Description is required"
                                        ),
                                        rewardUnlockPoint: Yup.string().required(
                                          "Reward Unlock Point is required"
                                        ),
                                        Unlimited: Yup.string().required("Unlimited is required"),
                                        GenerateRewardCode: Yup.string().required(
                                          "Generate Reward Code is required"
                                        ),
                                        ExpiryDate:
                                          Yup.string().required("Expiry Date is required"),
                                      });
                                    } else {
                                      const {
                                        RewardTitle,
                                        RewardDescription,
                                        rewardUnlockPoint,
                                        Unlimited,
                                        GenerateRewardCode,
                                        ExpiryDate,
                                        ...rest
                                      } = dinamicValidationSchema;
                                      setDinamicValidationSchema(rest);
                                    }
                                  }}
                                >
                                  {challengeOptionsDetails?.challengeOptionsList?.addReward &&
                                    challengeOptionsDetails.challengeOptionsList.addReward.map(
                                      (reward) => {
                                        return (
                                          <MenuItem key={reward.key} value={reward.key}>
                                            {reward.value}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                </Field>
                                <ErrorMessage
                                  name="AddReward"
                                  component="div"
                                  className="error-message"
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Card>
                    </Grid>
                    {formikProps.values?.AddReward == "Yes" && (
                      <>
                        <Grid item xs={12} md={3}>
                          <Card>
                            <MDBox pt={3} px={2}>
                              <MDTypography variant="h6" fontWeight="medium">
                                Reward Image
                              </MDTypography>
                            </MDBox>
                            {formikProps.values.RewardImages && (
                              <img alt="preview image" src={formikProps.values.RewardImages} />
                            )}
                            <ErrorMessage
                              name="RewardImages"
                              component="div"
                              className="error-message"
                            />
                            <Button variant="contained" component="label" color="white">
                              Upload File
                              <input
                                name="RewardImages"
                                // value={formData?.RewardImages}
                                type="file"
                                className="filetypeInput"
                                onChange={(event) => {
                                  setRewardImages(event.target.files[0]);
                                  formikProps.setFieldValue(
                                    "RewardImages",
                                    URL.createObjectURL(event.target.files[0])
                                  );
                                  handleInputChange(event);
                                }}
                                accept="image/*"
                                hidden
                              />
                            </Button>

                          </Card>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Card>
                            <MDBox pt={3} px={2}>
                              <MDTypography variant="h6" fontWeight="medium">
                                Reward
                              </MDTypography>
                            </MDBox>
                            <Grid
                              container
                              rowSpacing={3}
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                              py={3}
                              px={2}
                            >
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <Field
                                    as={MDInput}
                                    type="text"
                                    name="RewardTitle"
                                    label="Reward Title"
                                    onChange={(event) => {
                                      formikProps.setFieldValue("Title", event.target.value);
                                      handleInputChange(event);
                                    }}
                                  />
                                  <ErrorMessage
                                    name="RewardTitle"
                                    component="div"
                                    className="error-message"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl fullWidth>
                                  <Field
                                    as={MDInput}
                                    type="text"
                                    name="RewardDescription"
                                    label="Reward Description"
                                    onChange={(event) => {
                                      formikProps.setFieldValue("Description", event.target.value);
                                      handleInputChange(event);
                                    }}
                                  />
                                  <ErrorMessage
                                    name="RewardDescription"
                                    component="div"
                                    className="error-message"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <Field
                                    as={MDInput}
                                    type="number"
                                    name="rewardUnlockPoint"
                                    label="Unlock Point"
                                    defaultValue={0}
                                    onChange={(event) => {
                                      formikProps.setFieldValue(
                                        "rewardUnlockPoint",
                                        event.target.value
                                      );
                                      handleInputChange(event);
                                    }}
                                  />
                                  <ErrorMessage
                                    name="rewardUnlockPoint"
                                    component="div"
                                    className="error-message"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <Field
                                    as={MDInput}
                                    type="text"
                                    name="LinkToWebsite"
                                    label="Website"
                                    onChange={(event) => {
                                      formikProps.setFieldValue(
                                        "LinkToWebsite",
                                        event.target.value
                                      );
                                      handleInputChange(event);
                                    }}
                                  />
                                  <ErrorMessage
                                    name="LinkToWebsite"
                                    component="div"
                                    className="error-message"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <InputLabel>Unlimited </InputLabel>
                                  <Field
                                    as={Select}
                                    name="Unlimited"
                                    label="Unlimited"
                                    defaultValue={"No"}
                                    onChange={(event) => {
                                      formikProps.setFieldValue("Unlimited", event.target.value);
                                      handleInputChange(event);
                                    }}
                                  >
                                    {rewardOptionsDetails?.rewardOptionsList?.AddReward &&
                                      rewardOptionsDetails.rewardOptionsList.AddReward.map(
                                        (reward) => {
                                          return (
                                            <MenuItem key={reward.key} value={reward.key}>
                                              {reward.value}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                  </Field>
                                  <ErrorMessage
                                    name="Unlimited"
                                    component="div"
                                    className="error-message"
                                  />
                                </FormControl>
                              </Grid>
                              {formikProps.values?.Unlimited == "NO" && (
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Field
                                      as={MDInput}
                                      type="number"
                                      name="NumberOfAvailableRewards"
                                      label="Number Of Available Rewards"
                                      onChange={(event) => {
                                        formikProps.setFieldValue(
                                          "NumberOfAvailableRewards",
                                          event.target.value
                                        );
                                        handleInputChange(event);
                                      }}
                                    />
                                    <ErrorMessage
                                      name="NumberOfAvailableRewards"
                                      component="div"
                                      className="error-message"
                                    />
                                  </FormControl>
                                </Grid>
                              )}
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <InputLabel>Reward Codes </InputLabel>
                                  <Field
                                    as={Select}
                                    name="GenerateRewardCode"
                                    label="Reward Codes"
                                    defaultValue={
                                      challengeOptionsDetails?.challengeOptionsList?.chooseFrom[0]
                                        .key
                                    }
                                    onChange={(event) => {
                                      formikProps.setFieldValue(
                                        "GenerateRewardCode",
                                        event.target.value
                                      );
                                      handleInputChange(event);
                                      if (event.target.value == "GENERICCODE") {
                                        setDinamicValidationSchema({
                                          ...dinamicValidationSchema,
                                          CouponCode:
                                            Yup.string().required("Coupon Code is required"),
                                        });
                                      } else {
                                        const { CouponCode, ...rest } = dinamicValidationSchema;
                                        setDinamicValidationSchema(rest);
                                      }
                                    }}
                                  >
                                    {rewardOptionsDetails?.rewardOptionsList?.generateRewardCodes &&
                                      rewardOptionsDetails.rewardOptionsList.generateRewardCodes.map(
                                        (reward) => {
                                          if (reward.key != "MANUALGENERATEDCODE")
                                            return (
                                              <MenuItem value={reward.key} key={reward.key}>
                                                {reward.value}
                                              </MenuItem>
                                            );
                                        }
                                      )}
                                  </Field>
                                  <ErrorMessage
                                    name="GenerateRewardCode"
                                    component="div"
                                    className="error-message"
                                  />
                                </FormControl>
                              </Grid>
                              {formData?.GenerateRewardCode == "GENERICCODE" && (
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Field
                                      as={MDInput}
                                      type="text"
                                      name="CouponCode"
                                      label="Coupon Code"
                                      onChange={(event) => {
                                        formikProps.setFieldValue("CouponCode", event.target.value);
                                        handleInputChange(event);
                                      }}
                                    />
                                    <ErrorMessage
                                      name="CouponCode"
                                      component="div"
                                      className="error-message"
                                    />
                                  </FormControl>
                                </Grid>
                              )}
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <Field
                                    as={DatePicker}
                                    disablePast
                                    type="date"
                                    value={expiryDate}
                                    minDate={startDate}
                                    name="ExpiryDate"
                                    label="Expiry Date"
                                    onChange={(event) => {
                                      setExpiryDate(event);
                                    }}
                                  />
                                  <ErrorMessage
                                    name="ExpiryDate"
                                    component="div"
                                    className="error-message"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl fullWidth>
                                  {/* <InputLabel>Other/InputLabel> */}
                                  <Field
                                    as={MDInput}
                                    type="text"
                                    onChange={(event) => {
                                      formikProps.setFieldValue("Other", event.target.value);
                                      handleInputChange(event);
                                    }}
                                    name="Other"
                                    label="Other"
                                    multiline
                                    rows={3}
                                  />
                                  <ErrorMessage
                                    name="Other"
                                    component="div"
                                    className="error-message"
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <MDBox display="flex" justifyContent="end" alignItems="center" mt={3} >
                    {!createChalangeReducer?.data?.data?.challengeId &&
                      <MDButton type="submit" size="medium" color="success" >Save</MDButton>
                    }
                    &nbsp;
                    <Link to="/challenges">
                      <MDButton type="button" size="medium" color="error">Back</MDButton>
                    </Link>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </LocalizationProvider>

        </MDBox>
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.logedinUserReducer.userDetails,
    competitionCommunityDetails: state.competitionCommunityReducer,
    challengeOptionsDetails: state.challengeOptionsReducer,
    activityTypeDetails: state.activityTypeReducer,
    activityOptionsDetails: state.activityOptionsReducer,
    participantsByOrganizerDetails: state.participantsByOrganizerReducer,
    rewardOptionsDetails: state.rewardOptionsReducer,
    createChalangeReducer: state.createChalangeReducer,
  };
};

export default connect(mapStateToProps, {
  getChallengeOptions,
  getActivityTypes,
  getApplicationOptions,
  getcompetitionCommunity,
  getParticipantsByOrganizer,
  getRewardOptions,
  createChalange,
  clearChallengeResponce
})(Challenges);