import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import FormControl from "@mui/material/FormControl";
import { Icon, Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { confirmAlert } from "react-confirm-alert";

import { Formik, ErrorMessage, Field, Form, } from 'formik';
import * as Yup from 'yup';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import { Link, useNavigate } from "react-router-dom";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import { getActivitySearchUsers, transferUserFollowerAction } from "redux/actions/activityAction";


function TransferUserFollowers({ activitySearchUsersReducer, transferUserFollowersReducer,
  getActivitySearchUsers, transferUserFollowerAction }) {

  const navigate = useNavigate();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userFromList, setUserFromList] = useState(null);
  const [userToList, setUserToList] = useState(null);

  const formInitialValues = {
    "oldUserId": null,
    "newUserId": null,
  };

  const formValidationSchema = {
    oldUserId: Yup.object().required("Please choose the old user."),
    newUserId: Yup.object().required("Please choose the new user."),
  };

  useEffect(() => {
    getActivitySearchUsers()
  }, []);

  useEffect(() => {
    if (activitySearchUsersReducer.userList) {
      setUserFromList(activitySearchUsersReducer.userList)
      setUserToList(activitySearchUsersReducer.userList)
    }
  }, [activitySearchUsersReducer])

  useEffect(() => {
    if (transferUserFollowersReducer?.sucess) {
      setIsLoading(false);
      // setTimeout(() => {
      //   navigate("/support_tool/transfer-user-activity")
      // }, 2000);
    }
  }, [transferUserFollowersReducer])

  const removeFromOtherList = (selectedUser, isItFrom) => {
    if (selectedUser) {
      if (isItFrom) {
        setUserToList(activitySearchUsersReducer.userList.filter(user => user.userId != selectedUser.userId))
      }
      else {
        setUserFromList(activitySearchUsersReducer.userList.filter(user => user.userId != selectedUser.userId))
      }
    }
  }


  const onFormSubmit = (values, actions) => {
    confirmAlert({
      title: "",
      message: "Are you sure to transfer followers?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setIsLoading(true)
            transferUserFollowerAction({
              oldUserId: values.oldUserId.userId,
              newUserId: values.newUserId.userId
            })
          }
        },
        {
          label: "No"
          // onClick: () => alert("Click No")
        }
      ]
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mx={0} mb={2} mt={3} py={3} px={2} variant="gradient" bgColor="success" borderRadius="lg" coloredShadow="info">
          <MDTypography variant="h6" color="white">
            Transfer User Followers
          </MDTypography>
        </MDBox>
        {isError && (
          <MDSnackbar
            color="error" icon="warning" title={isError} content={isError} open={isError} bgWhite
            onClose={() => { setIsError(false) }}
            close={() => { setIsError(false) }}
          />
        )}
        <MDBox mb={3}>
          {(activitySearchUsersReducer?.loading || isLoading) &&
            <CustomSpinner />}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Formik
              enableReinitialize={true}
              initialValues={formInitialValues}
              validationSchema={Yup.object().shape(formValidationSchema)}
              onSubmit={onFormSubmit}
            >
              {formikProps => (
                <Form>
                  <Card>
                    {/* <MDBox pt={3} px={2}>
                            <MDTypography variant="h6" fontWeight="medium">Transfer User Activity </MDTypography>
                          </MDBox> */}
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} py={3} px={2}>


                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Field
                            as={Autocomplete}
                            name="oldUserId"
                            options={userFromList}
                            defaultValue={formikProps.values?.oldUserId}
                            value={formikProps.values?.oldUserId}
                            onChange={(event, value) => {
                              formikProps.setFieldValue("oldUserId", value);
                              removeFromOtherList(value, true)
                            }}
                            getOptionLabel={(option) => option.userName}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.userId}>

                                {option?.profilePhotoUrl && option?.profilePhotoUrl != ""
                                  ? <img
                                    srcSet={option.profilePhotoUrl}
                                    src={option.profilePhotoUrl}
                                    alt={option.userName}
                                    className="communityImg"
                                  />
                                  : <Icon fontSize={"large"} className="communityImg communityImgIcon" >account_circle</Icon>
                                }
                                &nbsp;&nbsp;
                                <span>{option.userName}</span>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Activity Old User"
                                inputProps={{
                                  ...params.inputProps,

                                }}
                              />
                            )}
                          />

                          <ErrorMessage name="oldUserId" component="div" className="error-message" />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          {/* <InputLabel>Activity New User</InputLabel> */}

                          <Field
                            as={Autocomplete}

                            defaultValue={formikProps.values?.newUserId}
                            value={formikProps.values?.newUserId}
                            name="newUserId"
                            options={userToList}
                            onChange={(event, value) => {
                              formikProps.setFieldValue("newUserId", value);
                              removeFromOtherList(value, false)
                            }}
                            getOptionLabel={(option) => option.userName}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.userId}>

                                {option?.profilePhotoUrl && option?.profilePhotoUrl != ""
                                  ? <img
                                    srcSet={option.profilePhotoUrl}
                                    src={option.profilePhotoUrl}
                                    alt={option.userName}
                                    className="communityImg"
                                  />
                                  : <Icon fontSize={"large"} className="communityImg communityImgIcon"  >account_circle</Icon>
                                }
                                &nbsp;&nbsp;
                                <span>{option.userName}</span>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Activity New User"
                                inputProps={{
                                  ...params.inputProps,

                                }}
                              />
                            )}
                          />



                          <ErrorMessage name="newUserId" component="div" className="error-message" />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  <MDBox display="flex" justifyContent="end" alignItems="center" mt={3} >
                    <MDButton type="submit" size="medium" color="success">Transfer Followers </MDButton>
                    &nbsp;
                    <Link to="/dashboard">
                      <MDButton type="button" size="medium" color="error">Back</MDButton>
                    </Link>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </LocalizationProvider>

        </MDBox>
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}

const mapStateToProps = (state) => {
  return {
    activitySearchUsersReducer: state.getActivitySearchUsersReducer,
    transferUserFollowersReducer: state.transferUserFollowersReducer,
  };
};

export default connect(mapStateToProps, { getActivitySearchUsers, transferUserFollowerAction })(TransferUserFollowers);