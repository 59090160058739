import { ActionTypes, CHALLENGE_URL, LOOKUP_URL, REWARD_URL } from "../contants";
import { customeGetService, } from "../services/service";

// Get Activity Types List
const getActivityTypes = (params = null) => {
    return (Dispatch) => {
        Dispatch({ type: ActionTypes.GET_ACTIVE_TYPE_REQUEST, })
        customeGetService(LOOKUP_URL + 'Activity/ActivityTypesContentPrefrences').then(
            (response) => {
                if (response?.data) {
                    return Dispatch({ type: ActionTypes.GET_ACTIVE_TYPE_SUCESS, data: response.data })
                }
                else { return Dispatch({ type: ActionTypes.GET_ACTIVE_TYPE_FAIL, data: response.response }) }
            },
            (error) => { return Dispatch({ type: ActionTypes.GET_ACTIVE_TYPE_FAIL, data: error }) }
        )
    }
};

// Get Application Options List
const getApplicationOptions = (params = null) => {
    return (Dispatch) => {
        Dispatch({ type: ActionTypes.GET_ACTIVE_OPTIONS_REQUEST, })
        customeGetService(LOOKUP_URL + 'Application/Options').then(
            (response) => {
                if (response?.data) {
                    return Dispatch({ type: ActionTypes.GET_ACTIVE_OPTIONS_SUCESS, data: response.data })
                }
                else { return Dispatch({ type: ActionTypes.GET_ACTIVE_OPTIONS_FAIL, data: response.response }) }
            },
            (error) => { return Dispatch({ type: ActionTypes.GET_ACTIVE_OPTIONS_FAIL, data: error }) }
        )
    }
};

export {
    getActivityTypes,
    getApplicationOptions,
}