import { ConstructionOutlined } from "@mui/icons-material";
import { ActionTypes } from "../contants";

const intialState = {
  eventList: null,
  eventOptionsList: null,
};
const userDetails = JSON.parse(localStorage.getItem("UserDetails"));

const eventListReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_ACTIVE_EVENT_LIST_REQUEST:
      return {
        loading: true,
        eventList: null,
      };
    case ActionTypes.GET_ALL_ACTIVE_EVENT_LIST_SUCESS:
      return {
        eventList: action.data,
        loading: false,
      };
    case ActionTypes.GET_ALL_ACTIVE_EVENT_LIST_FAIL:
      return {
        eventList: null,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

const eventOptionsReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_REQUEST:
      return {
        loading: true,
        eventOptionsList: null,
      };
    case ActionTypes.GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_SUCESS:
      return {
        eventOptionsList: action.data,
        loading: false,
      };
    case ActionTypes.GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_FAIL:
      return {
        eventOptionsList: null,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

const eventParticipantsByOrganizerReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_EVENT_PARTICIPANTS_BY_ORGANIZER_REQUEST:
      return {
        loading: true,
        participantsList: null,
      };
    case ActionTypes.GET_EVENT_PARTICIPANTS_BY_ORGANIZER_SUCESS:
      let newData = action.data.filter(item => item.userId != userDetails.userId)
      return {
        participantsList: newData,
        loading: false,
      };
    case ActionTypes.GET_EVENT_PARTICIPANTS_BY_ORGANIZER_FAIL:
      return {
        participantsList: null,
        loading: false,
      };
    default:
      return state;
  }
};

const ceateEventReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_EVENT_REQUEST:
      return {
        loading: true,
        sucess: false,
      };
    case ActionTypes.CREATE_EVENT_SUCESS:
      return {
        data: action.data,
        sucess: true,
        loading: false,
      };
    case ActionTypes.CREATE_EVENT_FAIL:
      return {
        error: action.data,
        sucess: false,
        loading: false,
      };
    case ActionTypes.CLEAR_EVENT_RESPONCE:
      return {
        data: null,
        error: null,
        sucess: false,
        loading: false,
      };
    default:
      return state;
  }
};

const eventDatailsByIdReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_EVENT_DETAILS_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case ActionTypes.GET_EVENT_DETAILS_BY_ID_SUCESS:
      if (action.data.status == 404) {
        return {
          data: { message: "Event not found." },
          sucess: false,
          loading: false,
        };
      } else {
        return {
          data: action.data.data,
          sucess: true,
          loading: false,
        };
      }
    case ActionTypes.GET_EVENT_DETAILS_BY_ID_FAIL:
      return {
        sucesss: false,
        loading: false,
      };
    case ActionTypes.RESET_EVENT_DETAILS_BY_ID_FAIL:
      return {
        sucesss: false,
        loading: false,
      };
    default:
      return state;
  }
};

const updateEventDatailsByIdReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_EVENT_REQUEST:
      return {
        loading: true,
      };
    case ActionTypes.UPDATE_EVENT_SUCESS:
      return {
        data: action.data,
        sucess: true,
        loading: false,
      };
    case ActionTypes.UPDATE_EVENT_FAIL:
      return {
        sucesss: false,
        loading: false,
      };
    case ActionTypes.CLEAR_EVENT_RESPONCE:
      return {
      };
    default:
      return state;
  }
};

const removeEventReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.REMOVE_EVENT_REQUEST:
      return {
        loading: true,
      };
    case ActionTypes.REMOVE_EVENT_SUCESS:
      return {
        data: action.data,
        sucess: true,
        loading: false,
      };
    case ActionTypes.REMOVE_EVENT_FAIL:
      return {
        sucesss: false,
        loading: false,
      };
    default:
      return state;
  }
};


export {
  eventListReducer,
  eventOptionsReducer,
  eventParticipantsByOrganizerReducer,
  ceateEventReducer,
  eventDatailsByIdReducer,
  updateEventDatailsByIdReducer,
  removeEventReducer,
}








