import { ActionTypes, LOOKUP_URL, EVENT_URL } from "../contants";
import { customeGetService, customePostService, customeDeleteService } from "../services/service";


const geteventsList = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_EVENT_LIST_REQUEST, })
    let userDetails = JSON.parse(localStorage.getItem('UserDetails'))
    customeGetService(EVENT_URL + 'Event/Own/' + userDetails.userId + '?type=ACTIVE').then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_EVENT_LIST_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_EVENT_LIST_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_EVENT_LIST_FAIL, data: error }) }
    )
  }
};

// Get Event Options List
const getEventOptionsList = () => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_REQUEST, })
    customeGetService(LOOKUP_URL + 'Event/Options').then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_FAIL, data: error }) }
    )
  }
};

// Get Event Participants By  Organizer 
const getParticipantsByOrganizer = (params = null) => {
  return (Dispatch) => {

    Dispatch({ type: ActionTypes.GET_EVENT_PARTICIPANTS_BY_ORGANIZER_REQUEST, })
    customePostService(EVENT_URL + 'Event/GetParticipantsByOrganizer', params).then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_EVENT_PARTICIPANTS_BY_ORGANIZER_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_EVENT_PARTICIPANTS_BY_ORGANIZER_FAIL, data: response.response }) }
      },
      (error) => {
        return Dispatch({ type: ActionTypes.GET_EVENT_PARTICIPANTS_BY_ORGANIZER_FAIL, data: error })
      }
    )
  }
};

// Create New Event
const createEvent = (params = {}) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.CREATE_EVENT_REQUEST, })
    customePostService(EVENT_URL + 'Event/Create', params).then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.CREATE_EVENT_SUCESS, data: response })
        }
        else { return Dispatch({ type: ActionTypes.CREATE_EVENT_FAIL, data: response.response }) }
      },
      (error) => {
        return Dispatch({ type: ActionTypes.CREATE_EVENT_FAIL, data: error })
      }
    )
  }
};

// ClearC Callenge Success Responce
const clearEventResponce = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.CLEAR_EVENT_RESPONCE, })
  }
}

// Get Event Datails
const getEventsDatailsById = (eventeId = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.GET_EVENT_DETAILS_BY_ID_REQUEST, })
    customeGetService(EVENT_URL + 'Event/Details/' + eventeId).then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_EVENT_DETAILS_BY_ID_SUCESS, data: response })
        }
        else { return Dispatch({ type: ActionTypes.GET_EVENT_DETAILS_BY_ID_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.GET_EVENT_DETAILS_BY_ID_FAIL, data: error }) }
    )
  }
};

const resetEventsDatailsById = () => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.RESET_EVENT_DETAILS_BY_ID_FAIL, })
  }
}

// Get Update Event
const updateEventeById = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.UPDATE_EVENT_REQUEST, })
    customePostService(EVENT_URL + 'Event/Update', params).then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.UPDATE_EVENT_SUCESS, data: response })
        }
        else { return Dispatch({ type: ActionTypes.UPDATE_EVENT_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.UPDATE_EVENT_FAIL, data: error }) }
    )
  }
};

// Get Remove Event
const removeEvent = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.REMOVE_EVENT_REQUEST, })
    customeDeleteService(EVENT_URL + 'Event/Delete/' + params).then(
      (response) => {
        if (response?.status === 200) {
          return Dispatch({ type: ActionTypes.REMOVE_EVENT_SUCESS, data: response })
        }
        else { return Dispatch({ type: ActionTypes.REMOVE_EVENT_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.REMOVE_EVENT_FAIL, data: error }) }
    )
  }
};

export {
  geteventsList,
  getEventOptionsList,
  getParticipantsByOrganizer,
  createEvent,
  clearEventResponce,
  getEventsDatailsById,
  resetEventsDatailsById,
  updateEventeById,
  removeEvent,
}