import { combineReducers } from "redux";
// Errors Reducer
import { errorReducer, successReducer } from "./errorReducer";
// Login Reducer
import { msalInstanceReducer, logedinUserReducer } from "./loginReducer";
// Challenges Reducer
import { challengeReducer, challengeOptionsReducer, competitionCommunityReducer, participantsByOrganizerReducer, rewardOptionsReducer, createChalangeReducer, challengesDatailsByIdReducer, updateChallengesDatailsByIdReducer, removeChallengeReducer, GetChallengeRelatedActivitiesReducer, recalculateLeaderboardReducer, } from "./challengeReducer";
// Events Reducer
import { ceateEventReducer, eventDatailsByIdReducer, eventListReducer, eventOptionsReducer, eventParticipantsByOrganizerReducer, removeEventReducer, updateEventDatailsByIdReducer } from "./eventReducer";
// Common Reducer
import { activityOptionsReducer, activityTypeReducer } from "./commonReducer";
import { ceateGoalReducer, goalListReducer, goalOptionsReducer } from "./goalReducer";
import { activateRewardReducer, rewardChallengeParticipantsReducer, rewardChallengesReducer } from "./activateRewardReducer";
import { GetActivityExcelReducer, getActivitySearchUsersReducer, transferUserActivityReducer, transferUserFollowersReducer } from "./activityReducer";
import { removeUserReducer, userMarketingDataReducer } from "./userReducer";


const reducers = combineReducers({

    // Errors Reducer
    errorReducer: errorReducer,
    successReducer: successReducer,

    // Login Reducer
    msalInstanceReducer: msalInstanceReducer,
    logedinUserReducer: logedinUserReducer,

    // Challenges Reducer
    challengeReducer: challengeReducer,
    challengeOptionsReducer: challengeOptionsReducer,
    competitionCommunityReducer: competitionCommunityReducer,
    participantsByOrganizerReducer: participantsByOrganizerReducer,
    rewardOptionsReducer: rewardOptionsReducer,
    createChalangeReducer: createChalangeReducer,
    challengesDatailsByIdReducer: challengesDatailsByIdReducer,
    updateChallengesDatailsByIdReducer: updateChallengesDatailsByIdReducer,
    removeChallengeReducer: removeChallengeReducer,
    recalculateLeaderboardReducer: recalculateLeaderboardReducer,

    // Events Reducer
    eventListReducer: eventListReducer,
    eventParticipantsByOrganizerReducer: eventParticipantsByOrganizerReducer,
    eventOptionsReducer: eventOptionsReducer,
    ceateEventReducer: ceateEventReducer,
    eventDatailsByIdReducer: eventDatailsByIdReducer,
    updateEventDatailsByIdReducer: updateEventDatailsByIdReducer,
    removeEventReducer: removeEventReducer,

    // Goals Reducer 
    goalListReducer: goalListReducer,
    goalOptionsReducer: goalOptionsReducer,
    ceateGoalReducer: ceateGoalReducer,

    // Common Reducer
    activityOptionsReducer: activityOptionsReducer,
    activityTypeReducer: activityTypeReducer,

    activateRewardReducer: activateRewardReducer,
    rewardChallengesReducer: rewardChallengesReducer,

    GetChallengeRelatedActivitiesReducer: GetChallengeRelatedActivitiesReducer,
    rewardChallengeParticipantsReducer: rewardChallengeParticipantsReducer,

    getActivitySearchUsersReducer: getActivitySearchUsersReducer,
    GetActivityExcelReducer: GetActivityExcelReducer,

    userMarketingDataReducer: userMarketingDataReducer,
    removeUserReducer:removeUserReducer,
    
    transferUserActivityReducer: transferUserActivityReducer,
    transferUserFollowersReducer: transferUserFollowersReducer

});

export default reducers;