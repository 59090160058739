import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MDInput from "components/MDInput";
import { Select, Icon, Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { confirmAlert } from "react-confirm-alert";

import { Formik, ErrorMessage, Field, Form, } from 'formik';
import * as Yup from 'yup';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import { Link, useNavigate } from "react-router-dom";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import { getActivitySearchUsers, } from "redux/actions/activityAction";
import { removeUser, } from "redux/actions/userAction";


function TransferUserActivity({ activitySearchUsersReducer, removeUserReducer,
  getActivitySearchUsers, removeUser }) {

  const navigate = useNavigate();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userFromList, setUserFromList] = useState(null);

  const initialValues = {
    "UserId": null,
  };
  const [formInitialValues, setFormInitialValues] = useState(initialValues);

  const formValidationSchema = {
    UserId: Yup.object().required("Please choose the user."),
  };

  useEffect(() => {
    getActivitySearchUsers()
  }, []);

  useEffect(() => {
    if (activitySearchUsersReducer.userList) {
      setUserFromList(activitySearchUsersReducer.userList)
    }
  }, [activitySearchUsersReducer])

  useEffect(() => {
    if (removeUserReducer.success) {
      setIsLoading(false);
      setFormInitialValues({
        "UserId": null,
      })
      // setTimeout(() => {
      //   navigate("/support_tool/transfer-user-activity")
      // }, 2000);
    }
  }, [removeUserReducer])


  const onFormSubmit = (values, actions) => {
    confirmAlert({
      title: "Remove user",
      message: "Are you sure to remove user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setIsLoading(true)
            removeUser(values.UserId.userId)
          }
        },
        {
          label: "No"
          // onClick: () => alert("Click No")
        }
      ]
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mx={0} mb={2} mt={3} py={3} px={2} variant="gradient" bgColor="success" borderRadius="lg" coloredShadow="info">
          <MDTypography variant="h6" color="white">
            Remove User
          </MDTypography>
        </MDBox>
        {isError && (
          <MDSnackbar
            color="error" icon="warning" title={isError} content={isError} open={isError} bgWhite
            onClose={() => { setIsError(false) }}
            close={() => { setIsError(false) }}
          />
        )}
        <MDBox mb={3}>
          {(activitySearchUsersReducer?.loading || isLoading) &&
            <CustomSpinner />
          }

          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            validationSchema={Yup.object().shape(formValidationSchema)}
            onSubmit={onFormSubmit}
          >
            {formikProps =>
            (<Form>
              <Card>
                {/* <MDBox pt={3} px={2}>
                            <MDTypography variant="h6" fontWeight="medium">Transfer User Activity </MDTypography>
                          </MDBox> */}
                <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} py={3} px={2}>


                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Field
                        as={Autocomplete}
                        name="UserId"
                        options={userFromList}
                        defaultValue={formInitialValues.userId}
                        // value={formInitialValues.userId}
                        onChange={(event, value) => {
                          setFormInitialValues({ "UserId": value })
                        }}
                        // onChange={(event, value) => {
                        //   formikProps.setFieldValue("UserId", value);
                        // }}
                        getOptionLabel={(option) => option ? option.userName : ""}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.userId}>

                            {option?.profilePhotoUrl && option?.profilePhotoUrl != ""
                              ? <img
                                srcSet={option.profilePhotoUrl}
                                src={option.profilePhotoUrl}
                                alt={option.userName}
                                className="communityImg"
                              />
                              : <Icon fontSize={"large"} className="communityImg communityImgIcon" >account_circle</Icon>
                            }
                            &nbsp;&nbsp;
                            <span>{option.userName}</span>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select User"
                            inputProps={{
                              ...params.inputProps,

                            }}
                          />
                        )}
                      />

                      <ErrorMessage name="UserId" component="div" className="error-message" />
                    </FormControl>
                  </Grid>
                </Grid>
              </Card>
              <MDBox display="flex" justifyContent="end" alignItems="center" mt={3} >
                <MDButton type="submit" size="medium" color="success">Remove User </MDButton>
                &nbsp;
                <Link to="/dashboard">
                  <MDButton type="button" size="medium" color="error">Back</MDButton>
                </Link>
              </MDBox>
            </Form>
            )}
          </Formik>

        </MDBox>
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}

const mapStateToProps = (state) => {
  return {
    activitySearchUsersReducer: state.getActivitySearchUsersReducer,
    removeUserReducer: state.removeUserReducer,
  };
};

export default connect(mapStateToProps, { getActivitySearchUsers, removeUser })(TransferUserActivity);