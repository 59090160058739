import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import { logOutActopn } from "../../redux/actions/loginActions";
import bgImage from "assets/images/frami/FramiBg.jpg";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";

import logo from "assets/images/frami/frami.png"

import Divider from "@mui/material/Divider";

const UnauthorizedComponent = () => {
    const navigate = useNavigate();
    const dispatchAction = useDispatch()

    const logOut = () => {
        dispatchAction(logOutActopn())
        navigate('/')
    }
    return <>
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox variant="gradient"
                    bgColor="success"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center">
                    <Grid >
                        <MDBox component="img" src={logo} alt="Brand" width="8rem" />
                        <Divider
                            light={"white"} />
                        <MDTypography variant="h4">
                            You don`t have permission to access this portal.
                        </MDTypography>
                    </Grid>
                    <br />
                    <MDButton
                        component="button"
                        rel="noreferrer"
                        variant="gradient"
                        color='light'
                        fullWidth
                        onClick={() => { logOut() }}
                    >
                        <LogoutIcon /> Log Out
                    </MDButton>
                </MDBox>
            </Card>
        </BasicLayout>
    </>

}

export default UnauthorizedComponent;