
import { ActionTypes } from "../contants";

const intialState = {
    loading: false,
    userList: null,
};

const userMarketingDataReducer = (state = intialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_USER_MARKETING_DATA_REQUEST:
            return {
                loading: true,
                userList: null,
            };
        case ActionTypes.GET_USER_MARKETING_DATA_SUCESS:
            return {
                userList: action.data,
                loading: false,
            };
        case ActionTypes.GET_USER_MARKETING_DATA_FAIL:
            return {
                userList: null,
                loading: false,
            };
        default:
            return state;
    }
};

const removeUserReducer = (state = intialState, action) => {
    switch (action.type) {
        case ActionTypes.REMOVE_USER_REQUEST:
            return {
                loading: true,
                userList: null,
            };
        case ActionTypes.REMOVE_USER_SUCESS:
            return {
                userList: action.data,
                loading: false,
                success: true
            };
        case ActionTypes.REMOVE_USER_FAIL:
            return {
                userList: null,
                loading: false,
                success: false
            };
        case ActionTypes.CLEAR_USER_RESPONCE:
            return {
                userList: null,
                loading: false,
            };
        default:
            return state;
    }
};




export {
    userMarketingDataReducer,
    removeUserReducer
}