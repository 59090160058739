
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "../DataTable";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import Icon from "@mui/material/Icon";
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import MDButton from "components/MDButton";
import Notification from "layouts/Notification";

import { geteventsList, clearEventResponce } from "redux/actions/eventActions";


function Events({ eventListReducer, successReducer, geteventsList, clearEventResponce }) {
  const [goalsList, setGolessList] = useState([])

  // useEffect(() => {
  //   geteventsList()
  // }, []);

  // useEffect(() => {
  //   if (eventListReducer?.eventList) {
  //     setEventsList(eventListReducer?.eventList.map(item => {
  //       return { ...item, startDate: new Date(item.startDate).toLocaleDateString(), endDate: new Date(item.endDate).toLocaleDateString() }
  //     }))
  //   }
  // }, [eventListReducer]);

  // useEffect(() => {
  //   if (successReducer?.message) {
  //     setTimeout(() => {
  //       clearEventResponce();
  //     }, 1000);
  //   }
  // }, [successReducer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info">

                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Goals
                  </MDTypography>
                  <Link to='/goal'><MDButton
                    // fullWidth
                    variant="outlined"
                    size="small"
                    color="white">
                    Add Goal
                  </MDButton>
                  </Link>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {eventListReducer?.loading
                  ?
                  <CustomSpinner />
                  :
                  <DataTable
                    table={{
                      columns: [
                        { Header: "Event ", accessor: "eventName", width: "16%" },
                        { Header: "Objective", accessor: "objective", width: "17%" },
                        { Header: "Start Date", accessor: "startDate", width: "16%" },
                        { Header: "End Date", accessor: "endDate", width: "16%" },
                        // { Header: "Type", accessor: "challangeType", width: "16%" },
                        // {
                        //   Header: "Action",
                        //   accessor: "action",
                        //   Cell: props => {
                        //     return (
                        //       <MDButton
                        //         component="a"
                        //         href={'/challenge/' + props.row.original.challengeId}
                        //         rel="noreferrer"
                        //         variant="text"
                        //         color="dark">
                        //         <VisibilitySharpIcon fontSize="small">table_view</VisibilitySharpIcon>
                        //       </MDButton>
                        //     )
                        //   },
                        //   width: "10%"
                        // },
                      ],
                      rows: goalsList
                    }}
                    entriesPerPage={true}
                    pagination={true}
                    isSorted={true}
                    noEndBorder={true}
                    canSearch={true}
                    showTotalEntries={true}
                  />
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {successReducer?.message &&
        <Notification
          color="success"
          icon="check"
          title={successReducer.message}
          content={successReducer.Description}
          dateTime=""
        />}
      <Footer />
    </DashboardLayout >
  );
}

const mapStateToProps = (state) => {
  return {
    eventListReducer: state.eventListReducer,
    successReducer: state.successReducer
  };
};

export default connect(mapStateToProps, { geteventsList, clearEventResponce })(Events);
