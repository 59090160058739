import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "../DataTable";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Notification from "layouts/Notification";
import { geteventsList, removeEvent, clearEventResponce } from "redux/actions/eventActions";
function Events({ userDetails, eventListReducer, removeEventReducer, successReducer, geteventsList, clearEventResponce, removeEvent }) {
  const [eventsList, setEventsList] = useState([])

  useEffect(() => {
    geteventsList()
  }, []);

  useEffect(() => {
    if (eventListReducer?.eventList) {
      setEventsList(eventListReducer?.eventList.map(item => {
        return { ...item, startDate: new Date(item.startDate).toLocaleDateString('nb').replaceAll('.', '/'), endDate: new Date(item.endDate).toLocaleDateString('nb').replaceAll('.', '/') }
      }))
    }
  }, [eventListReducer]);

  useEffect(() => {
    if (successReducer?.message) {
      setTimeout(() => {
        clearEventResponce();
      }, 1000);
    }
  }, [successReducer]);

  useEffect(() => {
    if (removeEventReducer?.sucess) {
      geteventsList()
    }
  }, [removeEventReducer])

  const onRemoveEvent = (eventId) => {
    if (confirm("Confirm remove the event.") == true) {
      removeEvent(eventId)
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info">

                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Events
                  </MDTypography>
                  <Link to='/event'><MDButton
                    // fullWidth
                    variant="outlined"
                    size="small"
                    color="white">
                    Add Event
                  </MDButton>
                  </Link>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {eventListReducer?.loading
                  ?
                  <CustomSpinner />
                  :
                  <DataTable
                    table={{
                      columns: [
                        { Header: "Event ", accessor: "eventName", width: "16%" },
                        { Header: "Objective", accessor: "objective", width: "17%" },
                        { Header: "Start Date", accessor: "startDate", width: "16%" },
                        { Header: "End Date", accessor: "endDate", width: "16%" },
                        {
                          Header: "Action",
                          accessor: "action",
                          Cell: props => {
                            return (userDetails.userId === props.row.original.userId ?
                              <MDBox display="flex" justifyContent="end" alignItems="center" >
                                <Link to={'/event/' + props.row.original.eventId} >
                                  <Tooltip title="Edit" placement="top">
                                    <MDTypography variant="body2" color="secondary">
                                      <Icon>edit</Icon>
                                    </MDTypography>
                                  </Tooltip>
                                </Link>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                {/* <Tooltip title="Delete" placement="top" onClick={() => onRemoveEvent(props.row.original.eventId)}>
                                  <MDTypography variant="body2" color="secondary">
                                    <Icon>delete</Icon>
                                  </MDTypography>
                                </Tooltip> */}
                              </MDBox>
                              : <></>
                            )
                          },
                          width: "10%"
                        },
                      ],
                      rows: eventsList
                    }}
                    entriesPerPage={true}
                    pagination={true}
                    isSorted={true}
                    noEndBorder={true}
                    canSearch={true}
                    showTotalEntries={true}
                  />
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {
        successReducer?.message &&
        <Notification
          color="success"
          icon="check"
          title={successReducer.message}
          content={successReducer.Description}
          dateTime=""
        />
      }
      <Footer />
    </DashboardLayout >
  );
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.logedinUserReducer.userDetails,
    eventListReducer: state.eventListReducer,
    successReducer: state.successReducer,
    removeEventReducer: state.removeEventReducer,
  };
};

export default connect(mapStateToProps, { geteventsList, removeEvent, clearEventResponce })(Events);
