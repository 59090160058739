
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "../DataTable";

import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import Icon from "@mui/material/Icon";
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import MDButton from "components/MDButton";
import Notification from "layouts/Notification";


import { GetUserMarketingData } from "redux/actions/userAction";

function Events({ userMarketingDataReducer, successReducer, GetUserMarketingData }) {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const GetUserMarketingDataAction = () => {
    setIsLoading(true)
    GetUserMarketingData()
  }


  useEffect(() => {
    if (userMarketingDataReducer?.userList) {
      setIsLoading(false);

      setTimeout(() => {
        navigate("/support_tool/export-user-marketing-details")
      }, 2000);
    }
  }, [userMarketingDataReducer])

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mx={0} mb={2} mt={3} py={3} px={2} variant="gradient" bgColor="success" borderRadius="lg" coloredShadow="info">
          <MDTypography variant="h6" color="white">
            Export User Marketing details
          </MDTypography>
        </MDBox>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
            {(userMarketingDataReducer?.loading || isLoading) && <CustomSpinner />
          }
              <MDBox m={3}>
                <Grid item xs={4}>
                  <MDButton
                    variant="gradient"
                    size="small"
                    color="success"
                    onClick={GetUserMarketingDataAction}
                  >
                    <Icon fontSize="small">assignment</Icon>
                    <MDTypography variant="button" fontWeight="regular" color="light">
                      &nbsp;&nbsp;Export User Marketing details
                    </MDTypography>
                  </MDButton>
                </Grid>

              </MDBox>

            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {
        successReducer?.message &&
        <Notification
          color="success"
          icon="check"
          title={successReducer.message}
          content={successReducer.Description}
          dateTime=""
        />
      }
      <Footer />
    </DashboardLayout >
  );
}

const mapStateToProps = (state) => {
  return {
    userMarketingDataReducer: state.userMarketingDataReducer,
    successReducer: state.successReducer
  };
};

export default connect(mapStateToProps, { GetUserMarketingData, })(Events);
