import { ActionTypes, USER_URL } from "../contants";
import { customeGetService } from "../services/service";

export const setLoginAuthority = (params = null) => {
  return (dispatch) => {
    return dispatch({ type: ActionTypes.SET_LOGIN_AUTHORITY, data: params })
  };
};

export const getUserDetails = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.USER_LOGIN_REQUEST, })
    customeGetService(USER_URL + 'User/ValidateUser').then(
      (response) => {
        if (response?.data) {
          localStorage.setItem('UserDetails', JSON.stringify(response.data));
          return Dispatch({ type: ActionTypes.USER_LOGIN_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.USER_LOGIN_FAILED, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.USER_LOGIN_FAILED, data: error }) }
    )
  }
};
export const isAllradyLoginAction = (params = null) => {
  return (Dispatch) => {
    return Dispatch({ type: ActionTypes.USER_ALLRADY_LOGIN, data: params })
  }
}

export const logOutActopn = (params = null) => {
  localStorage.clear();
  window.location = '/'
  return (dispatch) => {
    return dispatch({ type: ActionTypes.LOG_OUT, data: null })
  };
};