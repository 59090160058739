import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import InputLabel from "@mui/material/InputLabel";
import { Select, Icon, Autocomplete } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MDInput from "components/MDInput";

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';


import { Formik, ErrorMessage, Field, Form, } from 'formik';
import * as Yup from 'yup';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Notification from "layouts/Notification";

import { getActivitySearchUsers, GetActivityExcelAction } from "redux/actions/activityAction";

function Event({ getActivitySearchUsersReducer, GetActivityExcelReducer, successReducer,
  getActivitySearchUsers, GetActivityExcelAction }) {

  const navigate = useNavigate();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const initialValues = {
    "userId": null,
    "startDate": startDate,
    "endDate": endDate,
  }

  const [formInitialValues, setFormInitialValues] = useState(initialValues);

  useEffect(() => {
    getActivitySearchUsers()
  }, [])

  const formValidationSchema = {
    userId: Yup.object().required("Please choose a user."),
    startDate: Yup.string().required('Please choose start date.'),
    endDate: Yup.string().required('Please choose end date.'),
  };

  useEffect(() => {
    if (GetActivityExcelReducer?.sucess) {
      setFormInitialValues({
        "userId": null,
        "startDate": dayjs(new Date()),
        "endDate": dayjs(new Date()),
      })
      setIsLoading(false);
      // setTimeout(() => {
      //   navigate("/support_tool/export-user-activity")
      // }, 2000);
    }
  }, [GetActivityExcelReducer])

  const resetFormData = (values) => {
    setFormInitialValues(
      {
        "userId": values,
        "startDate": dayjs(values?.startDate ? values?.startDate : startDate),
        "endDate": dayjs(values?.endDate ? values?.endDate : endDate),
      }
    )
    setStartDate(dayjs(values?.startDate ? values?.startDate : startDate))
    setEndDate(dayjs(values?.endDate ? values?.endDate : endDate))
  }

  const resetDate = (date) => {
    if (Date.parse(date.$d) > Date.parse(endDate.$d)) {
      setEndDate(date)
    }
    setStartDate(date)
  }

  const onFormSubmit = (values, actions) => {
    setIsLoading(true);
    GetActivityExcelAction({
      userId: values.userId.userId,
      startDate: new Date(values.startDate).toLocaleDateString("en-CA"),
      endDate: new Date(values.endDate).toLocaleDateString("en-CA")
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mx={0} mb={2} mt={3} py={3} px={2} variant="gradient" bgColor="success" borderRadius="lg" coloredShadow="info">
          <MDTypography variant="h6" color="white">
            Export User Activity
          </MDTypography>
        </MDBox>
        {isError && (
          <MDSnackbar
            color="error" icon="warning" title={isError} content={isError} open={isError} bgWhite
            onClose={() => { setIsError(false) }}
            close={() => { setIsError(false) }}
          />
        )}
        <MDBox mb={3}>
          {getActivitySearchUsersReducer?.loading || isLoading &&
            <CustomSpinner />
          }
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Formik
              enableReinitialize={true}
              initialValues={formInitialValues}
              validationSchema={Yup.object().shape(formValidationSchema)}
              onSubmit={onFormSubmit}
            >
              {formikProps => (
                <Form>
                  <Card>

                    {/* <MDBox pt={3} px={2}>
                          <MDTypography variant="h6" fontWeight="medium">Export User Activity </MDTypography>
                        </MDBox> */}
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} py={3} px={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Field
                            as={Autocomplete}
                            name="userId"
                            options={getActivitySearchUsersReducer?.userList ? getActivitySearchUsersReducer?.userList : []}
                            defaultValue={formInitialValues.userId}
                            value={formInitialValues.userId}
                            onChange={(event, value) => {
                              resetFormData(value)
                            }}
                            getOptionLabel={(option) => option.userName}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.userId}>

                                {option?.profilePhotoUrl && option?.profilePhotoUrl != ""
                                  ? <img
                                    srcSet={option.profilePhotoUrl}
                                    src={option.profilePhotoUrl}
                                    alt={option.userName}
                                    className="communityImg"
                                  />
                                  : <Icon fontSize={"large"} className="communityImg communityImgIcon" >account_circle</Icon>
                                }
                                &nbsp;&nbsp;
                                <span>{option.userName}</span>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="User"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: ''

                                }}
                              />
                            )}
                          />
                          <ErrorMessage name="userId" component="div" className="error-message" />
                        </FormControl>
                      </Grid>



                      <Grid item xs={6}></Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          {/* <InputLabel>Start Date</InputLabel> */}
                          <Field
                            as={DatePicker}
                            type="date"
                            min={startDate}
                            value={startDate}
                            name="startDate"
                            defaultValue={startDate}
                            label="Start Date"
                            onChange={(event) => {
                              formikProps.setFieldValue("startDate", event);
                              resetDate(event)
                            }}
                          />
                          <ErrorMessage
                            name="startDate"
                            component="div"
                            className="error-message"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          {/* <InputLabel>End Date</InputLabel> */}
                          <Field
                            as={DatePicker}
                            type="date"
                            value={endDate}
                            minDate={startDate}
                            name="endDate"
                            defaultValue={endDate}
                            label="End Date"
                            onChange={(event) => {
                              formikProps.setFieldValue("endDate", event);
                              setEndDate(event)
                            }}
                          />
                          <ErrorMessage
                            name="endDate"
                            component="div"
                            className="error-message"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  <MDBox display="flex" justifyContent="end" alignItems="center" mt={3} >
                    <MDButton type="submit" size="medium" color="success">Export User Activity</MDButton>
                    &nbsp;
                    <Link to="/dashboard">
                      <MDButton type="button" size="medium" color="error">Back</MDButton>
                    </Link>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </LocalizationProvider>

        </MDBox>
      </MDBox >
      {
        successReducer?.message &&
        <Notification
          color="success"
          icon="check"
          title={successReducer.message}
          content={successReducer.Description}
          dateTime=""
        />
      }
      <Footer />
    </DashboardLayout >
  );
}

const mapStateToProps = (state) => {
  return {
    getActivitySearchUsersReducer: state.getActivitySearchUsersReducer,
    GetActivityExcelReducer: state.GetActivityExcelReducer,
    successReducer: state.successReducer
  };
};

export default connect(mapStateToProps, { getActivitySearchUsers, GetActivityExcelAction })(Event);