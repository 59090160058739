import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "../DataTable";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Notification from "layouts/Notification";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getActiveChallengesList, clearChallengeResponce, removeChallenge, recalculateLeaderboardAction } from '../../redux/actions/challengeActions'

function Challenges({ userDetails, challengeDetail, successReducer, getActiveChallengesList, clearChallengeResponce, removeChallenge, removeChallengeReducer, recalculateLeaderboardAction, recalculateLeaderboardReducer }) {
  const [challengesList, setChallengesList] = useState([])

  useEffect(() => { getActiveChallengesList() }, []);

  useEffect(() => {
    if (challengeDetail?.challengesList) {
      setChallengesList(challengeDetail?.challengesList.map(item => {
        return { ...item, startDate: new Date(item.startDate).toLocaleDateString('nb').replaceAll('.', '/'), endDate: new Date(item.endDate).toLocaleDateString('nb').replaceAll('.', '/') }
      }))
    }
  }, [challengeDetail]);

  useEffect(() => {
    if (successReducer?.message) {
      setTimeout(() => {
        clearChallengeResponce();
      }, 1000);
    }
  }, [successReducer]);

  useEffect(() => {
    if (removeChallengeReducer?.sucess) {
      getActiveChallengesList()
    }
  }, [removeChallengeReducer])

  const onRemoveChallenge = (ChallengeiD) => {
    if (confirm("Confirm remove the challenge.") == true) {
      removeChallenge(ChallengeiD)
    }
  }

  useEffect(() => {
    if (recalculateLeaderboardReducer.sucess) {
      setTimeout(() => {
        getActiveChallengesList()
      }, 2000);
    }
  }, [recalculateLeaderboardReducer])

  const recalculateLeaderboard = (challenge) => {

    confirmAlert({
      title: "",
      message: "Are you sure to recalculate leaderboard for " + challenge.challengeName + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => recalculateLeaderboardAction(challenge.challengeId)
        },
        {
          label: "No"
          // onClick: () => alert("Click No")
        }
      ]
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info">
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Active Challenges
                  </MDTypography>
                  <MDBox>
                    <Link to='/challenge'>
                      <MDButton
                        // fullWidth
                        variant="outlined"
                        size="small"
                        color="white">
                        Add Challenge
                      </MDButton>
                    </Link>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {challengeDetail?.loading
                  ?
                  <CustomSpinner />
                  :
                  <DataTable
                    table={{
                      columns: [
                        { Header: "challenge ", accessor: "challengeName", },
                        { Header: "Organizer", accessor: "organizer.name", },
                        { Header: "Start Date", accessor: "startDate", },
                        { Header: "End Date", accessor: "endDate", },
                        { Header: "Type", accessor: "challangeType", },
                        {
                          Header: "Leaderboard", accessor: "recalculateLeaderboard",
                          Cell: props => {
                            return ((props.row.original.challengeCommunity === "No" && userDetails.userId === props.row.original.userId )?
                              <MDBox display="flex" justifyContent="end" alignItems="center" >
                                <MDButton
                                  onClick={() => recalculateLeaderboard(props.row.original)}
                                  // fullWidth
                                  variant="outlined"
                                  size="small"
                                  color="secondary">
                                  Recalculate
                                </MDButton>
                              </MDBox>
                              : <></>
                            )
                          },
                        },
                        {
                          Header: "Action",
                          accessor: "action",
                          Cell: props => {
                            return (userDetails.userId === props.row.original.userId ?
                              <MDBox display="flex" justifyContent="end" alignItems="center" >
                                <Link to={'/challenge/' + props.row.original.challengeId} >
                                  <Tooltip title="Edit" placement="top">
                                    <MDTypography variant="body2" color="secondary">
                                      <Icon>edit</Icon>
                                    </MDTypography>
                                  </Tooltip>
                                </Link>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                {/* <Tooltip title="Delete" placement="top" onClick={() => onRemoveChallenge(props.row.original.challengeId)}>
                                  <MDTypography variant="body2" color="secondary">
                                    <Icon>delete</Icon>
                                  </MDTypography>
                                </Tooltip> */}
                              </MDBox>
                              : <></>
                            )
                          },
                        },

                      ],
                      rows: challengesList
                    }}
                    entriesPerPage={true}
                    pagination={true}
                    isSorted={true}
                    noEndBorder={true}
                    canSearch={true}
                    showTotalEntries={true}
                  />
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {
        successReducer?.message &&
        <Notification
          color="success"
          icon="check"
          title={successReducer.message}
          content={successReducer.Description}
          dateTime=""
        />
      }
      <Footer />
    </DashboardLayout >
  );
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.logedinUserReducer.userDetails,
    msalInstanceData: state.msalInstanceReducer.msalInstance,
    challengeDetail: state.challengeReducer,
    successReducer: state.successReducer,
    removeChallengeReducer: state.removeChallengeReducer,
    recalculateLeaderboardReducer: state.recalculateLeaderboardReducer,
  };
};

export default connect(mapStateToProps, { getActiveChallengesList, clearChallengeResponce, removeChallenge, recalculateLeaderboardAction })(Challenges);
