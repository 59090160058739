
import { useEffect, useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import { logOutActopn } from "../../redux/actions/loginActions";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import MDAvatar from "components/MDAvatar";

function Sidenav({ color, brand, brandName, routes, userDetails, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const [activeSubMenu, setActiveSubMenu] = useState(null); // State to manage active submenu

  let isGlobalAdmin = userDetails && userDetails.userRoles.filter((item) => item.roleType == "GlobalCommunityAdmin").length ? true : false;

  const navigate = useNavigate()
  const dispatchAction = useDispatch()
  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }


    window.addEventListener("resize", handleMiniSidenav);

    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const iconsStyle = ({ palette: { white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = white.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route, canAccess, children }, index) => {
    let returnValue;

    if (isGlobalAdmin && canAccess.includes('GlobalCommunityAdmin')) {

      if (type === "collapse") {
        if (children && children.length > 0) {
          returnValue = (
            <>
              {type === "collapse" && (
                <div key={key} onClick={() => activeSubMenu == key ? setActiveSubMenu(null) : setActiveSubMenu(key)}>
                  <SidenavCollapse name={name} icon={icon} active={key.includes(collapseName)} />
                </div>
              )}

              {activeSubMenu === key && (
                <List sx={{ pl: 4 }}>
                  {children.map((childRoute, index) => {
                    return <NavLink key={childRoute.key} to={childRoute.route}>
                      <SidenavCollapse name={childRoute.name} icon={icon} active={childRoute.key == collapseName} />
                    </NavLink>
                  })}
                </List>
              )}
            </>
          );
        }
        else {
          returnValue = <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key.includes(collapseName)} />
          </NavLink>
        }

        // returnValue = href ? (
        //   <Link
        //     href={route}
        //     key={key}
        //     // target="_blank"
        //     rel="noreferrer"
        //     sx={{ textDecoration: "none" }}
        //   >
        //     <SidenavCollapse
        //       name={name}
        //       icon={icon}
        //       active={key.includes(collapseName)}
        //       noCollapse={noCollapse}
        //     />
        //   </Link>
        // ) : (

        // );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }
    }
    else if (canAccess.includes('All')) {
      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={route}
            key={key}
            // target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key.includes(collapseName)}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key.includes(collapseName)} />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }
    }

    return <div key={index}>  {returnValue}</div>;
  });

  const logOut = () => {
    dispatchAction(logOutActopn())
    navigate('/')
  }

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/dashboard" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="8rem" />}
          {/* <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox> */}
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>

      {/* <MDBox p={2} mt="auto">
        <MDBox ml={0} display="flex" spacing={3} alignItems="center">

          {userDetails?.profilePhotoUrl != '' || userDetails?.profilePhotoUrl != null ?
            <MDAvatar src={userDetails?.profilePhotoUrl} alt="profile-image" size="md" shadow="sm" />
            :
            <Icon fontSize={"large"} sx={{ fontWeight: "bold" }}>account_circle</Icon>
          }


          <MDBox height="100%" ml={1} lineHeight={1}>
            <MDTypography variant="h6" fontWeight="light" color="light">
              {userDetails?.userName.length > 12 ?
                userDetails?.userName.substring(0, 12) :
                userDetails?.userName}
            </MDTypography>
            <MDTypography variant="button" color="light" fontWeight="light">

              {userDetails?.emailAddress.length > 30 ?
                userDetails?.emailAddress.substring(0, 30) :
                userDetails?.emailAddress}
            </MDTypography>
          </MDBox>
        </MDBox>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />

        <MDButton
          component="button"
          rel="noreferrer"
          variant="gradient"
          color='secondary'
          fullWidth
          onClick={() => { logOut() }}
        >
          Log Out
        </MDButton>
      </MDBox> */}
    </SidenavRoot >
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
