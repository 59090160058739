import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select } from "@mui/material";

import { Formik, ErrorMessage, Field, Form, } from 'formik';
import * as Yup from 'yup';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import MDInput from "components/MDInput";
import { Button, Icon } from "@mui/material";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import dayjs from "dayjs";

import { getEventOptionsList, getParticipantsByOrganizer, createEvent } from "redux/actions/eventActions";

import { getActivityTypes, getApplicationOptions } from "redux/actions/commonAction";
import ImageDropdownComponent from "layouts/commonComponents/ImageDropdownComponent";
import ActivityDropdownComponent from "layouts/commonComponents/ActivityDropdownComponent";

import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import { setDefaults, geocode, RequestType } from "react-geocode";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MDAlert from "components/MDAlert";

function Event({ userDetails, eventOptionsReducer, activityTypeDetails, activityOptionsDetails, eventParticipantsByOrganizerReducer, ceateEventReducer,
  getEventOptionsList, getActivityTypes, getApplicationOptions, getParticipantsByOrganizer, createEvent
}) {

  const navigate = useNavigate();
  let isGlobalAdmin = userDetails.userRoles.filter((item) => item.roleType == "GlobalCommunityAdmin").length ? true : false;


  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, 'hour'));
  const [recurringEndDate, setRecurringEndDate] = useState(dayjs().add(1, 'hour'));

  const [lat, setLat] = useState(59.918431204280836);
  const [lng, setLng] = useState(10.787848583031288);

  const [address, setAddress] = useState('');

  const formInitialValues = {
    "all_activity": true,
    "eventImage": "",
    "eventName": "",
    "eventObjective": "",
    "eventDescription": "",
    "Organizer": null,
    "eventPrivacy": "Global",
    "isEventEvent": "Event",
    "eventType": "Event",
    "activities": [],
    "limitTheNumber": "No",
    "numberOfParticipant": 0,
    "linkToWebsite": null,
    "address": "",
    "lat": "",
    "lng": "",
    "startDate": startDate,
    "endDate": endDate,
    "isRecurringEvent": "Event",
    "eventRepeat": "EVERYWEEK",
    "eventStopRepeat": "NEVER",
    "recurringEventEndDate": recurringEndDate,
    "eventSendInvite": "THREEDAY",
    "eventInviteForm": "MyNetwork",
    "inviteChooseFrom": "All",
    "selectedParticipant": [],
  };

  const formValidationSchema = {
    eventImage: Yup.string().required("Image required"),
    eventName: Yup.string().required("Event Name required"),
    eventObjective: Yup.string().required("Objective required"),
    eventDescription: Yup.string().required("Description required"),
    Organizer: Yup.object().required("Organizer required"),
    // linkToWebsite: Yup.string()
    //   .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, 'Enter correct url (https://)!')
    //   .required('Please enter website'),
    // "activities": Yup.array().required("Activity required"),
    // "address": Yup.string().required("Address required"),
    // "isRecurringEvent": Yup.string().required("Recurring event required"),
    // "eventPrivacy": Yup.string().required("Privacy required"),
    // "eventType": Yup.string().required("Type required"),
    // "limitTheNumber": Yup.number().required("limit Number required"),
    // "numberOfParticipant": Yup.number().required("Number Of Participant required"),
    // "lat": Yup.string().required("Lat required"),
    // "lng": Yup.string().required("Lng required"),
    // "startDate": Yup.string().required("Start date required"),
    // "endDate": Yup.string().required("End date required"),
    // "eventRepeat": Yup.string().required("Event repeat required"),
    // "eventStopRepeat": Yup.string().required("Stop repeat required"),
    // "recurringEventEndDate": Yup.string().required("Recurring end date required"),
    // "eventSendInvite": Yup.string().required("Send invite required"),
    // "eventInviteForm": Yup.string().required("invite form required"),
    // "inviteChooseFrom": Yup.string().required("Choose From required"),
    // "selectedParticipant": Yup.string().required("Participant required"),
  };

  const [formValues, setFormValues] = useState(formInitialValues);
  const [dinamicValidationSchema, setDinamicValidationSchema] = useState(formValidationSchema);
  const [inviteList, setInviteList] = useState(null);
  const [eventImage, setEventImage] = useState(null);

  setDefaults({
    key: "AIzaSyBOPHYklulQKQEFlOZaUk0CTLY6IEMsZMI", // Your API key here.
    language: "en", // Default language for responses.
    region: "es", // Default region for responses.
  });

  const [locationAllowed, setLocationAllowed] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    getEventOptionsList();
    getActivityTypes();
    getApplicationOptions();

    const checkLocationPermission = () => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (!JSON.parse(localStorage.getItem('alloweolocation'))) {
              setLng(position.coords.longitude)
              setLat(position.coords.latitude)
              localStorage.setItem('alloweolocation', true);
              setLocationAllowed(true);
              window.location.reload();

            }
            else if (position.coords) {
              setLng(position.coords.longitude)
              setLat(position.coords.latitude)
              setLocationAllowed(true);

            }
            else {
              localStorage.setItem('alloweolocation', false);
              setLocationAllowed(false);
            }
          },
          (error) => {
            if (error.code === 1) {
              localStorage.setItem('alloweolocation', false)
              setLocationAllowed(false)
            }
            // Handle other error cases if needed
          }
        );
      } else {
        // Geolocation not supported by the browser
        localStorage.setItem('alloweolocation', false)
        setLocationAllowed(false)
      }
    };

    checkLocationPermission();


  }, []);

  useEffect(() => {
    geocode(RequestType.LATLNG, String(lat + "," + lng))
      .then(({ results }) => {

        const address = results[0].formatted_address;
        setAddress(address);
      })
      .catch(console.error);

  }, [lat, lng])

  useEffect(() => {
    if (eventOptionsReducer?.eventOptionsList) {

      let tempFormValues = {};

      if (isGlobalAdmin) {
        setInviteList(eventOptionsReducer.eventOptionsList.privacy);
        tempFormValues = { eventPrivacy: "Global" }
      } else {
        let temp = eventOptionsReducer.eventOptionsList.privacy.filter(function (item) { return item.key !== "Global"; });
        tempFormValues = { eventPrivacy: temp[0].key }
        setInviteList(temp)
      }

      tempFormValues = {
        ...tempFormValues,
        eventType: eventOptionsReducer.eventOptionsList.eventType[0].key,
        eventRepeat: eventOptionsReducer.eventOptionsList.chooseFrequentRecurring[0].key,
        eventStopRepeat: eventOptionsReducer.eventOptionsList.chooseStopRecurring[0].key,
        eventSendInvite: eventOptionsReducer.eventOptionsList.sendInvite[0].key,
      }

      setFormValues({ ...formValues, ...tempFormValues })
      setIsLoading(false)
    }
  }, [eventOptionsReducer]);

  useEffect(() => {
    if (ceateEventReducer.sucess) {
      setIsLoading(false);

      setTimeout(() => {
        navigate("/events")
      }, 2000);

    } else if (ceateEventReducer?.sucesss === false) {
      setIsError("Somthig went to wrong, try again!!");
      setIsLoading(false);
    }

  }, [ceateEventReducer])


  const getParticipantsList = (organizerData) => {
    getParticipantsByOrganizer(organizerData)
  }

  const onFormSubmit = (values, actions) => {
    setIsLoading(true)
    const formData = new FormData();
    formData.append("UserId", userDetails.userId);
    formData.append("UserName", userDetails.userName);
    formData.append("ProfilePhotoUrl", userDetails.profilePhotoUrl);

    formData.append("EventImages", eventImage);
    formData.append("EventName", values.eventName);
    formData.append("Objective", values.eventObjective);
    formData.append("Description", values.eventDescription);

    formData.append("Organizer.Id", values.Organizer.id);
    formData.append("Organizer.Name", values.Organizer.name);
    formData.append("Organizer.ImageUrl", values.Organizer.imageUrl);
    formData.append("Organizer.OrganizerPrivacy", values.Organizer.organizerPrivacy);
    formData.append("Organizer.OrganizerType", values.Organizer.organizerType);

    formData.append("PrivacyType", values.eventPrivacy);
    formData.append("Eventtype", values.eventType);

    if (values.all_activity) {
      formData.append("ActivityTypes[0].key", activityOptionsDetails.activityOptionList.allActivityType.key);
      formData.append("ActivityTypes[0].name", activityOptionsDetails.activityOptionList.allActivityType.name);
      formData.append("ActivityTypes[0].icon", activityOptionsDetails.activityOptionList.allActivityType.icon);
      formData.append("ActivityTypes[0].combinationNo", activityOptionsDetails.activityOptionList.allActivityType.combinationNo);
    } else {
      if (values.activities.length) {
        let count = 0
        values.activities.forEach((item, index) => {
          item.zones.forEach((activity) => {
            formData.append("ActivityTypes[" + count + "].key", activity.code)
            formData.append("ActivityTypes[" + count + "].name", activity.name)
            formData.append("ActivityTypes[" + count + "].icon", activity.icon)
            formData.append("ActivityTypes[" + count + "].combinationNo", activity.combinationNo)
            count++
          })
        })
      }
      else {
        setIsError("Select activitys first!!");
        setIsLoading(false)
        return true;
      }
    }
    formData.append("IsLimitedNumberOfParticipants", values.limitTheNumber === "Yes" ? true : false);
    if (values.limitTheNumber === "Yes") {
      formData.append("NumberOfParticipants", values.numberOfParticipant);
    }

    formData.append("LinkToPurchaseTickets", values.linkToWebsite);
    formData.append("Venue.Name", address);
    formData.append("Venue.Latitude", lat);
    formData.append("Venue.Longitude", lng);


    let start = startDate.toISOString()
    start = start.substring(0, start.lastIndexOf('.'))

    let end = endDate.toISOString()
    end = end.substring(0, end.lastIndexOf('.'))

    if (new Date(start).getTime() >= new Date(end).getTime()) {
      setIsError("Invalid end date!!")
      return false;
    } else {
      formData.append("StartDate", start);
      formData.append("EndDate", end);
    }

    if (values.isRecurringEvent === "RecurringEvent") {
      formData.append("IsRecurringEvent", true);
      formData.append("ChooseFrequentRecurring", values.eventRepeat);
      formData.append("ChooseStopRecurring", values.eventStopRepeat);
      if (values.eventStopRepeat !== "NEVER") {
        formData.append("RecurringEventEndDate", new Date(recurringEndDate).toLocaleDateString("en-CA"));
      }
      formData.append("SendInvite", values.eventSendInvite);
    }

    formData.append("Invite", values.eventInviteForm);
    formData.append("Select", values.inviteChooseFrom);

    if (values.inviteChooseFrom === "All") {
      eventParticipantsByOrganizerReducer.participantsList.forEach((particiapnt, index) => {
        formData.append("Participants[" + index + "].userId", particiapnt.userId);
        formData.append("Participants[" + index + "].userName", particiapnt.userName);
        formData.append("Participants[" + index + "].profilePhotoUrl", particiapnt.profilePhotoUrl);
        formData.append("Participants[" + index + "].participantStatus", "NoResponse");
      });
    } else if (values.inviteChooseFrom == "Specific") {
      values.selectedParticipant.forEach((particiapnt, index) => {
        formData.append("Participants[" + index + "].userId", particiapnt.userId);
        formData.append("Participants[" + index + "].userName", particiapnt.userName);
        formData.append("Participants[" + index + "].profilePhotoUrl", particiapnt.profilePhotoUrl);
        formData.append("Participants[" + index + "].participantStatus", "NoResponse");
      });
    }

    createEvent(formData)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mx={0} mb={2} mt={3} py={3} px={2} variant="gradient" bgColor="success" borderRadius="lg" coloredShadow="info">
          <MDTypography variant="h6" color="white">
            Event Details
          </MDTypography>
        </MDBox>
        {isError && (
          <MDSnackbar
            color="error"
            icon="warning"
            title={isError}
            content={isError}
            open={isError}
            onClose={() => { setIsError(false) }}
            close={() => { setIsError(false) }}
            bgWhite
          />
        )}

        <MDBox mb={3}>
          {eventOptionsReducer.loading ||
            isLoading ? (
            <CustomSpinner />
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {!locationAllowed &&
                <MDAlert color="info" >
                  <MDTypography variant="body2" color="white">
                    It appears that you have blocked location sharing on this website. To accurately display your current location on the map, we need access to your location information. Currently, the map is displaying a default location, which may not accurately represent where you are.
                  </MDTypography>
                </MDAlert>
              }
              <Formik
                enableReinitialize={true}
                initialValues={formValues}
                validationSchema={Yup.object().shape(dinamicValidationSchema)}
                onSubmit={onFormSubmit}
              >
                {formikProps => (
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={3}>
                        <Card>
                          <MDBox pt={3} px={2}>
                            <MDTypography variant="h6" fontWeight="medium"> Event Image </MDTypography>
                          </MDBox>
                          {formikProps.values?.eventImage && (
                            <img alt="preview image" src={formikProps.values.eventImage} />
                          )}
                          <FormControl fullWidth>
                            <ErrorMessage name="eventImage" component="div" className="error-message" />
                            <Button variant="contained" component="label" color="white">
                              Upload File
                              <input
                                type="file"
                                name="eventImage"
                                accept="image/*"
                                onChange={(event) => {
                                  setEventImage(event.target.files[0])
                                  formikProps.setFieldValue("eventImage", URL.createObjectURL(event.target.files[0]));
                                }}
                                hidden
                                className="filetypeInput"
                              />
                            </Button>
                          </FormControl>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Card>
                          <MDBox pt={3} px={2}>
                            <MDTypography variant="h6" fontWeight="medium">Details</MDTypography>
                          </MDBox>
                          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} py={3} px={2}>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <InputLabel>Select which type of event </InputLabel>
                                <Field
                                  as={Select}
                                  label="Select which type of event "
                                  defaultValue={"Event"}
                                  name="isRecurringEvent"
                                  onChange={(event) => {
                                    formikProps.setFieldValue("isRecurringEvent", event.target.value);
                                    if (event.target.value == "RecurringEvent") {
                                      setDinamicValidationSchema({
                                        ...dinamicValidationSchema,
                                        eventRepeat: Yup.string().required("Repeat required."),
                                        eventStopRepeat: Yup.string().required("Stop Repeat required."),
                                        eventSendInvite: Yup.string().required("Send Invite required."),
                                      });
                                    } else {
                                      const { eventRepeat, eventStopRepeat, eventSendInvite, ...rest } = dinamicValidationSchema;
                                      setDinamicValidationSchema(rest);
                                    }
                                  }}
                                >
                                  <MenuItem value={"Event"}>Event</MenuItem>
                                  <MenuItem value={"RecurringEvent"}>Recurring Event</MenuItem>
                                </Field>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <InputLabel>Event Privacy </InputLabel>
                                <Field
                                  as={Select}
                                  label="Event Privacy "
                                  defaultValue={inviteList && inviteList[0].key}
                                  name="eventPrivacy"
                                >
                                  {inviteList?.map((invite) => {
                                    return <MenuItem key={invite.key} value={invite.key}>{invite.value}</MenuItem>;
                                  })}
                                </Field>
                              </FormControl>
                            </Grid>
                            {eventOptionsReducer?.eventOptionsList?.organizers &&
                              <Grid item xs={12}>
                                <ImageDropdownComponent titel={"Organizer"} fieldName={"Organizer"} onChangeEvent={
                                  (inputData) => {
                                    formikProps.setFieldValue("Organizer", inputData.target.value);
                                    getParticipantsList(inputData.target.value)
                                  }} options={eventOptionsReducer.eventOptionsList.organizers} selectedData={formikProps.values.Organizer} />
                              </Grid>
                            }
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                {/* <InputLabel>Event Name</InputLabel> */}
                                <Field
                                  as={MDInput}
                                  type="text"
                                  name="eventName"
                                  label="Event Name"
                                />
                                <ErrorMessage name="eventName" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                {/* <InputLabel>Event Objective</InputLabel> */}
                                <Field
                                  as={MDInput}
                                  type="text"
                                  name="eventObjective"
                                  label="Event Objective"
                                />
                                <ErrorMessage name="eventObjective" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                {/* <InputLabel>Event Description</InputLabel> */}
                                <Field
                                  as={MDInput}
                                  type="text"
                                  name="eventDescription"
                                  label="Event Description"
                                  multiline
                                  rows={3}
                                />
                                <ErrorMessage name="eventDescription" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            {activityTypeDetails?.activityTypeList &&
                              <Grid item xs={12}>
                                <ActivityDropdownComponent data={activityTypeDetails.activityTypeList} onActivitySelct={(selectedData) => {
                                  if (selectedData) {
                                    formikProps.setFieldValue("all_activity", false);
                                    formikProps.setFieldValue("activities", selectedData)
                                  } else {
                                    formikProps.setFieldValue("all_activity", true);
                                    formikProps.setFieldValue("activities", [])
                                  }
                                }} />
                              </Grid>
                            }
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel>Event Type</InputLabel>
                                <Field
                                  as={Select}
                                  name="eventType"
                                  // value={}
                                  // defaultValue={formData.PrivacyType}
                                  label="Event Type"
                                >
                                  {eventOptionsReducer?.eventOptionsList?.eventType &&
                                    eventOptionsReducer.eventOptionsList.eventType.map((type) => {
                                      return (
                                        <MenuItem key={type.key} value={type.key}>
                                          {type.value}
                                        </MenuItem>
                                      );
                                    })}
                                </Field>
                                <ErrorMessage
                                  name="eventType"
                                  component="div"
                                  className="error-message"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <Field
                                  as={MDInput}
                                  type="text"
                                  name="linkToWebsite"
                                  label="Website"
                                />
                                <ErrorMessage name="linkToWebsite" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel>Limit the number of participants </InputLabel>
                                <Field
                                  as={Select}
                                  label="Limit the number of participants "
                                  defaultValue={"No"}
                                  onChange={(event) => {
                                    formikProps.setFieldValue("limitTheNumber", event.target.value);
                                    if (event.target.value === "Yes") {
                                      setDinamicValidationSchema({
                                        ...dinamicValidationSchema, numberOfParticipant: Yup.number()
                                          .min(1, "Must be more than 0")
                                          .required("This field is requried")
                                      });
                                    } else {
                                      const { numberOfParticipant, ...rest } = dinamicValidationSchema;
                                      setDinamicValidationSchema(rest);
                                    }
                                  }}
                                  name="limitTheNumber"
                                >
                                  <MenuItem value={"Yes"}>Yes</MenuItem>
                                  <MenuItem value={"No"}>No</MenuItem>
                                </Field>
                              </FormControl>
                            </Grid>
                            {formikProps.values.limitTheNumber === "Yes" &&
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  {/* <InputLabel>Number of Participants </InputLabel> */}
                                  <Field
                                    as={MDInput}
                                    type="number"
                                    name="numberOfParticipant"
                                    label="Number of Participants"
                                    defaultValue={0}
                                  />
                                  <ErrorMessage name="numberOfParticipant" component="div" className="error-message" />
                                </FormControl>
                              </Grid>
                            }
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                {/* <InputLabel>Start Date</InputLabel> */}
                                <Field
                                  as={DateTimePicker}
                                  type="date"
                                  minDate={startDate}
                                  onChange={(event) => {
                                    setStartDate(event);
                                    setEndDate(dayjs(event).add(1, 'hour'));
                                  }}
                                  value={startDate}
                                  name="startDate"
                                  defaultValue={startDate}
                                  label="Start Date"
                                  disablePast
                                />
                                <ErrorMessage name="startDate" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                {/* <InputLabel>End Date</InputLabel> */}
                                <Field
                                  as={DateTimePicker}
                                  type="date"
                                  value={endDate}
                                  minDate={startDate}
                                  defaultValue={endDate}
                                  disablePast
                                  onChange={(event) => {
                                    setEndDate(event);
                                    setRecurringEndDate(event);

                                  }}
                                  name="endDate"
                                  label="End Date"
                                />
                                <ErrorMessage name="endDate" component="div" className="error-message" />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                              <div className="mb-3" style={{ height: '60vh', width: '100%' }}>
                                <GoogleMapReact
                                  bootstrapURLKeys={{ key: "AIzaSyBOPHYklulQKQEFlOZaUk0CTLY6IEMsZMI" }}
                                  defaultCenter={{
                                    "lng": lng,
                                    "lat": lat,
                                  }}
                                  defaultZoom={11}
                                  onClick={(e) => {
                                    setLat(e.lat);
                                    setLng(e.lng);
                                  }}
                                >
                                  <Marker
                                    key={""}
                                    text={address}
                                    lat={lat}
                                    lng={lng}
                                  />
                                </GoogleMapReact>
                              </div>

                            </Grid>

                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                {/* <InputLabel>Location Address</InputLabel> */}
                                <Field
                                  as={MDInput}
                                  type="text"
                                  disabled
                                  name="location"
                                  label="Location Address"
                                  multiline
                                  value={address}
                                  rows={1}
                                />
                                <ErrorMessage name="location" component="div" className="error-message" />
                              </FormControl>
                            </Grid>


                            {formikProps.values.isRecurringEvent === "RecurringEvent" &&
                              <>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>Repeat</InputLabel>
                                    <Field
                                      as={Select}
                                      name="eventRepeat"
                                      // value={}
                                      defaultValue={eventOptionsReducer?.eventOptionsList?.chooseFrequentRecurring[0].key}
                                      label="Repeat"
                                    >
                                      {eventOptionsReducer?.eventOptionsList?.chooseFrequentRecurring &&
                                        eventOptionsReducer.eventOptionsList.chooseFrequentRecurring.map((recurring) => {
                                          return (
                                            <MenuItem key={recurring.key} value={recurring.key}>
                                              {recurring.value}
                                            </MenuItem>
                                          );
                                        })}
                                    </Field>
                                    <ErrorMessage name="eventRepeat" component="div" className="error-message" />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>Stop repeating</InputLabel>
                                    <Field
                                      as={Select}
                                      name="eventStopRepeat"
                                      defaultValue={eventOptionsReducer?.eventOptionsList?.chooseStopRecurring[0].key}
                                      label="Stop repeating"
                                      onChange={(event) => {
                                        formikProps.setFieldValue("eventStopRepeat", event.target.value);
                                        if (event.target.value == "SPECIFICDATE") {
                                          setDinamicValidationSchema({
                                            ...dinamicValidationSchema, numberOfParticipant: Yup.number().required("Number Of Participant required"),
                                          });
                                        } else {
                                          const { numberOfParticipant, ...rest } = dinamicValidationSchema;
                                          setDinamicValidationSchema(rest);
                                        }

                                      }}
                                    >
                                      {eventOptionsReducer?.eventOptionsList?.chooseStopRecurring &&
                                        eventOptionsReducer.eventOptionsList.chooseStopRecurring.map((recurring) => {
                                          return (
                                            <MenuItem key={recurring.key} value={recurring.key}>
                                              {recurring.value}
                                            </MenuItem>
                                          );
                                        })}
                                    </Field>
                                    <ErrorMessage name="eventStopRepeat" component="div" className="error-message" />
                                  </FormControl>
                                </Grid>
                                {formikProps.values.eventStopRepeat === "SPECIFICDATE" &&
                                  <Grid item xs={6}>
                                    <FormControl fullWidth>
                                      {/* <InputLabel>Repeating End Date</InputLabel> */}
                                      <Field
                                        as={DatePicker}
                                        type="date"
                                        min={endDate}
                                        disablePast
                                        value={recurringEndDate}
                                        name="recurringEventEndDate"
                                        defaultValue={endDate}
                                        label="Repeating End Date"
                                        onChange={(event) => {
                                          formikProps.setFieldValue("recurringEventEndDate", event);
                                          setRecurringEndDate(event);
                                        }}
                                      />
                                      <ErrorMessage name="recurringEventEndDate" component="div" className="error-message" />
                                    </FormControl>
                                  </Grid>
                                }
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>Send Invite</InputLabel>
                                    <Field
                                      as={Select}
                                      name="eventSendInvite"
                                      // value={}
                                      defaultValue={eventOptionsReducer?.eventOptionsList?.sendInvite[0].key}
                                      label="Send Invite"
                                    >
                                      {eventOptionsReducer?.eventOptionsList?.sendInvite &&
                                        eventOptionsReducer.eventOptionsList.sendInvite.map((recurring) => {
                                          return (
                                            <MenuItem key={recurring.key} value={recurring.key}>
                                              {recurring.value}
                                            </MenuItem>
                                          );
                                        })}
                                    </Field>
                                    <ErrorMessage name="eventSendInvite" component="div" className="error-message" />
                                  </FormControl>
                                </Grid>
                              </>
                            }

                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel>Who to Invite </InputLabel>
                                <Field
                                  as={Select}
                                  name="eventInviteForm"
                                  label="Who to Invite"
                                  defaultValue="MyNetwork"
                                >
                                  {eventOptionsReducer?.eventOptionsList?.inviteFrom &&
                                    eventOptionsReducer.eventOptionsList.inviteFrom.map((invite) => {
                                      return (
                                        <MenuItem key={invite.key} value={invite.key}>
                                          {invite.value}
                                        </MenuItem>
                                      );
                                    })}
                                </Field>
                                <ErrorMessage name="eventInviteForm" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel>Choose </InputLabel>
                                <Field
                                  as={Select}
                                  name="inviteChooseFrom"
                                  label="Choose"
                                  onChange={(event) => {
                                    if (formikProps.values.Organizer) {
                                      formikProps.setFieldValue("inviteChooseFrom", event.target.value);
                                    }
                                    else {
                                      setIsError("Select organizer first!!");
                                    }
                                  }}
                                  defaultValue="All"
                                >
                                  {eventOptionsReducer?.eventOptionsList?.chooseFrom &&
                                    eventOptionsReducer.eventOptionsList.chooseFrom.map((choose) => {
                                      return (
                                        <MenuItem key={choose.key} value={choose.key}>
                                          {choose.value}
                                        </MenuItem>
                                      );
                                    })}
                                </Field>
                                <ErrorMessage name="inviteChooseFrom" component="div" className="error-message" />
                              </FormControl>
                            </Grid>
                            {formikProps.values?.inviteChooseFrom == "Specific" &&
                              eventParticipantsByOrganizerReducer?.participantsList &&
                              <Grid item xs={12}>
                                <ImageDropdownComponent titel={"Particiapnts"} fieldName={"selectedParticipant"} onChangeEvent={
                                  (inputData) => {
                                    formikProps.setFieldValue("selectedParticipant", inputData.target.value);
                                  }} options={eventParticipantsByOrganizerReducer.participantsList} isMultiple={true} selectedData={formikProps.values.selectedParticipant} />
                              </Grid>
                            }
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                    <MDBox display="flex" justifyContent="end" alignItems="center" mt={3} >
                      <MDButton type="submit" size="medium" color="success">Save</MDButton>
                      &nbsp;
                      <Link to="/challenges">
                        <MDButton type="button" size="medium" color="error">Back</MDButton>
                      </Link>
                    </MDBox>
                  </Form>
                )}
              </Formik>
            </LocalizationProvider>
          )}
        </MDBox>
      </MDBox >
      <Footer />

    </DashboardLayout >
  );
}


const mapStateToProps = (state) => {
  return {
    userDetails: state.logedinUserReducer.userDetails,
    eventOptionsReducer: state.eventOptionsReducer,
    activityTypeDetails: state.activityTypeReducer,
    eventParticipantsByOrganizerReducer: state.eventParticipantsByOrganizerReducer,
    activityOptionsDetails: state.activityOptionsReducer,
    ceateEventReducer: state.ceateEventReducer,
  };
};

export default connect(mapStateToProps, {
  getEventOptionsList, getActivityTypes, getApplicationOptions,
  getParticipantsByOrganizer, createEvent
})(Event);