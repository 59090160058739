import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select } from "@mui/material";
import { Icon } from "@mui/material";

import { ErrorMessage, Field } from 'formik';
import { NestedSelect } from 'multi-nested-select'


const ActivityDropdownComponent = ({ data, onActivitySelct }) => {

    const [allActivity, setAllActivity] = useState(false);
    const [selectData, setSelectData] = useState();

    useEffect(() => {
        if (data) {
            setSelectData(data.map((item) => {
                const { value, title, key, ...rest } = item;
                return {
                    ...rest,
                    name: title,
                    code: key,
                    provinceKey: key,
                    zones: value.map((zone) => {
                        const { name, key, ...zoneRest } = zone;
                        return {
                            ...zoneRest, name,
                            code: zone.key
                        }
                    }),
                }
            }))
        }
    }, [data])

    return (
        <>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>All Activity</InputLabel>
                    <Field
                        as={Select}
                        onChange={() => {
                            setAllActivity(!allActivity)
                            onActivitySelct(null)
                        }}
                        name="all_activity"
                        label="All Activity"
                    >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </Field>
                </FormControl>
                <ErrorMessage
                    name="all_activity"
                    component="div"
                    className="error-message"
                />
            </Grid>
            {allActivity && (
                <Grid item xs={12} mt={3}>

                    <NestedSelect
                        enableButton={false}
                        state={true}
                        width={"100%"}
                        height={350}
                        leading={true}
                        chip={false}
                        chipCount={10}
                        placeholder="Select Activity"
                        trailing={true}
                        trailingIcon={true}
                        inputClass="myCustom_text"
                        continent={false}
                        omitSelected={false}
                        expandChip={true}
                        selectedValue={[]}
                        // error={erTg}
                        // helperText="error occured"
                        // chip={true}
                        // limit={5}
                        // selectAllOption={true}
                        placeholderCtx={true}
                        selectAllOption={false}
                        dropDownClass="multi_nested_select "
                        showCustomList={selectData}
                        selectAllTitle={"select All Option"}
                        onViewmore={(event) => onActivitySelct(event)}
                        onChipDelete={(event) => onActivitySelct(event)}
                        onChange={(event) => onActivitySelct(event)}
                        callback={(event) => onActivitySelct(event)}
                    />


                    <ErrorMessage
                        name="activities"
                        component="div"
                        className="error-message"
                    />
                </Grid>
            )}
        </>
    );
}

export default ActivityDropdownComponent;