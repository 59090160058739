import { ConstructionOutlined } from "@mui/icons-material";
import { ActionTypes } from "../contants";

const intialState = {
  goalList: null,
  goalOptionsList: null,
};

const goalListReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_GOLES_LIST_REQUEST:
      return {
        loading: true,
        goalList: null,
      };
    case ActionTypes.GET_ALL_GOLES_LIST_SUCESS:
      return {
        goalList: action.data,
        loading: false,
      };
    case ActionTypes.GET_ALL_GOLES_LIST_FAIL:
      return {
        goalList: null,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

const goalOptionsReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_REQUEST:
      return {
        loading: true,
        goalOptionsList: null,
      };
    case ActionTypes.GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_SUCESS:
      return {
        goalOptionsList: action.data,
        loading: false,
      };
    case ActionTypes.GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_FAIL:
      return {
        goalOptionsList: null,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

const ceateGoalReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_GOAL_REQUEST:
      return {
        loading: true,
        sucess: false,
      };
    case ActionTypes.CREATE_GOAL_SUCESS:
      return {
        data: action.data,
        sucess: true,
        loading: false,
      };
    case ActionTypes.CREATE_GOAL_FAIL:
      return {
        error: action.data,
        sucess: false,
        loading: false,
      };
    case ActionTypes.CLEAR_GOAL_RESPONCE:
      return {
        data: null,
        error: null,
        sucess: false,
        loading: false,
      };
    default:
      return state;
  }
};


export {
  goalListReducer,
  goalOptionsReducer,
  ceateGoalReducer,
}








